const SET_GEO_LOCATION = "SET_GEO_LOCATION";
const SET_USER_CURRENT_LOCATION = "SET_USER_CURRENT_LOCATION";
const SET_BOOKING_LOCATION = "SET_BOOKING_LOCATION";
const SET_LANGUAGE = "SET_LANGUAGE";
const UPDATE_SETTINGS = "UPDATE_SETTINGS";
const SET_TOKEN = 'SET_TOKEN';
const SET_CURRENT_USER = 'SET_CURRENT_USER';
const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';

// Actions
const initialState = {
  accessToken: null,
  currentUser: null,
  isSignIn: false,
  geoLocation: {},
  userCurrentLocation: null,
  bookingLocation: null,
  preferredLanguage: null
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        isSignIn: true,
        accessToken: action.token
      };

    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.user
      };

    case AUTH_SIGN_OUT:
      return {
        ...state,
        accessToken: null,
        currentUser: null,
        isSignIn: false
      };
    case SET_GEO_LOCATION:
      return {
        ...state,
        geoLocation: { ...state.geoLocation, ...action.location },
      };

    case SET_USER_CURRENT_LOCATION:
      return {
        ...state,
        userCurrentLocation: {
          ...state.userCurrentLocation,
          ...action.location,
        },
      };

    case SET_BOOKING_LOCATION:
      return {
        ...state,
        bookingLocation: action.location,
      };

    case SET_LANGUAGE:
      return {
        ...state,
        preferredLanguage: action.language,
      };
    case UPDATE_SETTINGS:
      return {
        ...state,
        ...action.options.geoLocation && { geoLocation: action.options.geoLocation },
        ...Object.keys(action.options).includes('userCurrentLocation') &&
          { userCurrentLocation: action.options.userCurrentLocation },
        ...Object.keys(action.options).includes('bookingLocation') &&
          { bookingLocation: action.options.bookingLocation },
        ...Object.keys(action.options).includes('preferredLanguage') &&
          { preferredLanguage: action.options.preferredLanguage },
        ...Object.keys(action.options).includes('accessToken') &&
          { accessToken: action.options.accessToken },
        ...Object.keys(action.options).includes('currentUser') &&
          { currentUser: action.options.currentUser },
        ...Object.keys(action.options).includes('isSignIn') &&
          { isSignIn: action.options.isSignIn },
      }

    default:
      return state;
  }
}

// Action Creators
export function setCurrentGeoLocation(location) {
  return {
    type: SET_GEO_LOCATION,
    location: {
      countryName: location.countryName,
      countryCode: location.countryCode
        ? location.countryCode.toLowerCase()
        : null,
      locality: location.locality,
    },
  };
}

export function setUserCurrentLocation(location) {
  return {
    type: SET_USER_CURRENT_LOCATION,
    location: {
      countryName: location.countryName,
      countryCode: location.countryCode
        ? location.countryCode.toLowerCase()
        : null,
      locality: location.locality,
      address: location.address,
      placeId: location.placeId,
      lat: location.lat,
      lng: location.lng,
    },
  };
}

export function setBookingLocation(location) {
  return { type: SET_BOOKING_LOCATION, location };
}

export function setLanguage(language) {
  return { type: SET_LANGUAGE, language };
}

export function updateSettings(options={}) {
  return { type: UPDATE_SETTINGS, options };
}

export function setAuthToken(token) {
  return { type: SET_TOKEN, token };
}

export function setCurrentUser(user) {
  return { type: SET_CURRENT_USER, user };
}

export function signOut() {
  return { type: AUTH_SIGN_OUT };
}
