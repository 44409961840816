import React, { useState, useContext } from 'react';
import { Button, useMediaQuery } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { reset } from 'redux-form';
import styles from './styles.module.scss';
import { connect, useDispatch } from 'react-redux';
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { formValueSelector } from 'redux-form';
import { showErrorMessage } from 'lib/notifier';
import { setCurrentOrderId } from 'redux/payment';
import { handleOpeningAuthDialog } from 'redux/auth';
import { updateSettings } from 'redux/user';
import { toggleUpdateContactDialog } from 'redux/service';
import { LocationConfirmation } from 'components/booking_top_bar/components';
import { AppContext } from 'routes';
import clsx from 'clsx';

const SUBMIT_ORDER = gql`
  mutation($orderSubmission: OrderSubmission!) {
    submitOrder(orderSubmission: $orderSubmission) {
      id
      discountData
      totalPrice {
        amountCents
        currencyCode
      }
      totalDiscountedPrice {
        amountCents
        currencyCode
      }
      totalDuration
      salesItems {
        name
        price {
          amountCents
          currencyCode
        }
        duration
        heroEarnings {
          amountCents
          currencyCode
        }
      }
    }
  }
`;

const SUBMIT_WEEKLY_ORDER = gql`
  mutation($orderSubmission: WeeklyBooking!) {
    weeklySubmitOrder(orderSubmission: $orderSubmission) {
      id
      discountData
      totalPrice {
        amountCents
        currencyCode
      }
      totalDiscountedPrice {
        amountCents
        currencyCode
      }
      totalDuration
      salesItems {
        name
        price {
          amountCents
          currencyCode
        }
        duration
        heroEarnings {
          amountCents
          currencyCode
        }
      }
      directService {
        id
      }
      recurringService {
        id
      }
    }
  }
`;

const SUBMIT_YEARLY_ORDER = gql`
  mutation($orderSubmission: YearlyBooking!) {
    yearlySubmitOrder(orderSubmission: $orderSubmission) {
      id
      discountData
      totalPrice {
        amountCents
        currencyCode
      }
      totalDiscountedPrice {
        amountCents
        currencyCode
      }
      totalDuration
      recurringService {
        id
        order {
          id
        }
      }
      directService {
        id
      }
      salesItems {
        name
        price {
          amountCents
          currencyCode
        }
        duration
        heroEarnings {
          amountCents
          currencyCode
        }
      }
    }
  }
`;

const REQUIRE_FIELD = ['name', 'phone_number', 'email'];
const PLATFORM = 'web';

const CheckoutButton = ({
  isSignIn,
  address,
  directServiceId,
  selectedOptionalItems,
  selectedSalesItem,
  timeslots,
  answers,
  dateType,
  frequency,
  recurringServicePlanId,
  countryCode,
  language,
  history,
  setCurrentOrderIdDispatch,
  resetForm,
  translate,
  currentUser,
  bookingLocation
}) => {
  const [submitOrder, { loading: submitOrderLoading }] = useMutation(SUBMIT_ORDER);
  const [submitWeeklyOrder, { loading: submitWeeklyOrderLoading }] = useMutation(
    SUBMIT_WEEKLY_ORDER
  );
  const [submitYearlyOrder, { loading: submitYearlyOrderLoading }] = useMutation(
    SUBMIT_YEARLY_ORDER
  );
  const dispatch = useDispatch();
  const isOnMobile = useMediaQuery('(max-width:992px)', { noSsr: true });
  const contextProps = useContext(AppContext);
  const [locationConfirmationOpen, setLocationConfirmationOpen] = useState(false);
  const mapTypeToFunction = {
    once_off: () => handleSubmit(),
    weekly: () => handleSubmitRecurring(),
    yearly: () => handleSubmitRecurring()
  };

  let searchParams = ''
  if (contextProps.searchParams && contextProps.searchParams.urlSearchParams) {
    searchParams = contextProps.searchParams.urlSearchParams;
  }

  const mapTypeToSubmit = {
    weekly: {
      function: ({ variables }) => submitWeeklyOrder({ variables }),
      name: 'weeklySubmitOrder'
    },
    yearly: {
      function: ({ variables }) => submitYearlyOrder({ variables }),
      name: 'yearlySubmitOrder'
    }
  };

  function checkRequireField(userInfo) {
    const missingField = REQUIRE_FIELD.filter((field) => !userInfo[field]);
    return missingField.length ? true : false;
  }

  function checkLocationInformation(location) {
    return !(location && location.unitNumber)
  };

  const handleCheckout = () => {
    // check sign in
    if (!isSignIn) return dispatch(handleOpeningAuthDialog(true));

    // check unit number
    const isOpenConfirmLocation = checkLocationInformation(bookingLocation);
    if (isOpenConfirmLocation) return setLocationConfirmationOpen(true);
    
    // check required information
    const isOpenModal = checkRequireField(currentUser);
    if (isOpenModal) return dispatch(toggleUpdateContactDialog(true));
    const func = mapTypeToFunction[dateType];
    if (!func) return null;
    func();
  };

  const handleSubmitRecurring = async () => {
    const payload = {
      orderSubmission: {
        inputAddress: address,
        serviceId: directServiceId,
        selectedOptionalSalesItems: selectedOptionalItems,
        selectedSalesItem,
        frequency,
        answers,
        recurringServicePlanId,
        recurringType: dateType,
        platform: PLATFORM
      }
    };
    const { e, data } = await mapTypeToSubmit[dateType]
      .function({ variables: payload })
      .catch((e) => ({
        e
      }));
    if (e) {
      const errorMessage = e.graphQLErrors
        ? e.graphQLErrors.map((err) => err && err.message).join('\n')
        : 'Oops, your order cant be submit ';
      return showErrorMessage(errorMessage);
    }
    setCurrentOrderIdDispatch(
      data && data[mapTypeToSubmit[dateType].name] && data[mapTypeToSubmit[dateType].name].id,
      dateType
    );
    history.push({
      pathname: `/${countryCode}/${language}/summary`,
      search : searchParams || ''
    });
    resetForm();
  };

  const handleSubmit = async () => {
    const payload = {
      orderSubmission: {
        inputAddress: address,
        serviceId: directServiceId,
        selectedOptionalSalesItems: selectedOptionalItems,
        selectedSalesItem,
        timeslots,
        answers,
        platform: PLATFORM
      }
    };
    const { e, data } = await submitOrder({ variables: payload }).catch((e) => ({ e }));
    if (e) {
      const errorMessage = e.graphQLErrors
        ? e.graphQLErrors.map((err) => err && err.message).join('\n')
        : 'Oops, your order cant be submit ';
      return showErrorMessage(errorMessage);
    }
    setCurrentOrderIdDispatch(data && data.submitOrder && data.submitOrder.id, dateType);
    history.push({
      pathname: `/${countryCode}/${language}/summary`,
      search : searchParams || ''
    });
    resetForm();
  };

  const confirmLocationCancel = () => setLocationConfirmationOpen(false);

  const confirmLocation = async (location) => {
    await dispatch(updateSettings({ bookingLocation: location }));
    setLocationConfirmationOpen(false);
  };

  return (
    <>
      {locationConfirmationOpen && (
        <LocationConfirmation
          open={locationConfirmationOpen}
          location={bookingLocation}
          translate={translate}
          confirmLocationCancel={confirmLocationCancel}
          onSubmit={confirmLocation}
        />
      )}
      <Button
        disabled={submitOrderLoading || submitWeeklyOrderLoading || submitYearlyOrderLoading}
        onClick={handleCheckout}
        className={clsx(styles.checkoutBtn, 'primary_yellow_btn')}
        fullWidth
      >
        {translate(isOnMobile ? 'checkoutText' : 'checkout')}
      </Button>
    </>
  );
};
const bookingFormSelector = formValueSelector('BookingForm');

const mapStateToProps = (state) => {
  const selectedService = state.service && state.service.selectedService;
  const bookingLocation = state.user && state.user.bookingLocation;
  const currentUser = state.user && state.user.currentUser;
  const {
    selectedOptionalSalesItems,
    selectedSalesItem,
    dateType,
    timeslots,
    frequency,
    recurringServicePlanId,
    answers
  } = bookingFormSelector(
    state,
    'selectedOptionalSalesItems',
    'selectedSalesItem',
    'dateType',
    'timeslots',
    'frequency',
    'recurringServicePlanId',
    'answers'
  );
  return {
    directServiceId: (selectedService && selectedService.id) || null,
    selectedOptionalItems: selectedOptionalSalesItems || [],
    selectedSalesItem: selectedSalesItem || null,
    dateType: dateType || '',
    timeslots: timeslots || '',
    frequency: frequency || '',
    recurringServicePlanId: recurringServicePlanId || '',
    answers: answers || [],
    isSignIn: (state.user && state.user.isSignIn) || false,
    address: {
      address: bookingLocation && bookingLocation.address,
      placeId: bookingLocation && bookingLocation.placeId,
      lng: bookingLocation && bookingLocation.lng,
      lat: bookingLocation && bookingLocation.lat,
      remarks: bookingLocation && bookingLocation.remarks,
      locality: bookingLocation && bookingLocation.locality,
      unitNumber: bookingLocation && bookingLocation.unitNumber
    },
    countryCode:
      (state.user && state.user.geoLocation && state.user.geoLocation.countryCode) || 'my',
    language: (state.user && state.user.preferredLanguage) || 'en',
    currentUser: currentUser || {},
    bookingLocation
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentOrderIdDispatch: (id, dateType) => dispatch(setCurrentOrderId(id, dateType)),
  resetForm: () => dispatch(reset('BookingForm'))
});
export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(CheckoutButton);
