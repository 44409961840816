import React from 'react';
import styles from './card.module.scss';
import { Dialog, Typography, IconButton, Grid, Button, TextField } from '@material-ui/core';
import TextInput from './components/defaultInput';
import { Field, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';
import InputMask from 'react-input-mask';

const MaskedInput = (props) => {
  const { maskFormat, ...other } = props;
  return (
    <InputMask mask={maskFormat} value={props.input.value} onChange={props.input.onChange}>
      {(inputProps) => {
        return <TextInput {...inputProps} {...other} fullWidth />;
      }}
    </InputMask>
  );
};

let CardForm = ({ handleSubmit, translate, price, pristine, invalid, submitting }) => {
  return (
    <Grid item className="mt_10 mb_10">
      <form onSubmit={handleSubmit}>
        <Grid container item>
          <Field
            component={TextInput}
            variant="filled"
            name="cardNumber"
            label={translate('cardNumber')}
            placeholder="1234 5678 9012 3456"
            required
          />
          <Grid className="mt_15" item container wrap="nowrap">
            <Field
              variant="filled"
              name="expDate"
              placeholder="MM/YY"
              maskFormat="99/9999"
              className="mr_10"
              label={translate('expDate')}
              required
              component={MaskedInput}
            />
            <Field
              component={MaskedInput}
              variant="filled"
              name="cvc"
              placeholder="123"
              maskFormat="999"
              label={translate('cvc')}
              required
            />
          </Grid>
          <Field
            component={TextInput}
            variant="filled"
            name="nameOnCard"
            className="mt_15"
            placeholder="John Doe"
            label={translate('nameOnCard')}
            required
          />
        </Grid>

        <Button
          disabled={pristine || submitting || invalid}
          fullWidth
          type="submit"
          className="primary_yellow_btn mt_10"
          variant="contained"
        >
          <Trans i18nKey="Payment:pay" values={{ price: price }}></Trans>
        </Button>
      </form>
    </Grid>
  );
};

const validate = (values, props) => {
  const { translate, requirements } = props;
  const validPrefixCardNumbers = requirements && requirements.validPrefixCardNumbers;
  const errors = {};
  if (!values.cardNumber) {
    errors.cardNumber = translate('Payment:required');
  } else if (values.cardNumber.length < 6) {
    errors.cardNumber = translate('invalidCardNumber');
  } else if (
    validPrefixCardNumbers &&
    !validPrefixCardNumbers.includes(values.cardNumber.substring(0, 6))
  ) {
    errors.cardNumber = translate('notSupportCardNumber');
  }
  return errors;
};

CardForm = reduxForm({ form: 'CardForm', validate })(CardForm);

export default CardForm;
