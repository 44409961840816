import insuranceIcon from 'assets/icons/InsuranceIcon.svg';

import AirConditioningCategoryIcon from 'assets/icons/AirConditioningCategory.svg';
import CleaningCategoryIcon from 'assets/icons/CleaningCategory.svg';
import HandymanCategoryIcon from 'assets/icons/HandymanCategory.svg';
import PlumbingCategoryIcon from 'assets/icons/PlumbingCategory.svg';
import ProfessionalDisinfectionCategoryIcon from 'assets/icons/ProfessionalDisinfectionCategory.svg';
import ElectricalCategoryIcon from 'assets/icons/ElectricalCategory.svg';
import RefreshIcon from 'assets/icons/Refresh.svg';
import CalendarIcon from 'assets/icons/Calendar.svg';
import OtherIcon from 'assets/icons/others.svg';

import MYFlagIcon from 'assets/logos/my-flag.svg';
import SGFlagIcon from 'assets/logos/sg-flag.svg';
import THFlagIcon from 'assets/logos/th-flag.svg';
import UKFlagIcon from 'assets/logos/uk-flag.svg';

const iconSwitch = {
  Insurance: insuranceIcon,

  // Category Icons
  'Air Conditioning': AirConditioningCategoryIcon,
  Cleaning: CleaningCategoryIcon,
  'Cleaning Supplies': CleaningCategoryIcon,
  Handyman: HandymanCategoryIcon,
  Plumbing: PlumbingCategoryIcon,
  'Professional Disinfection': ProfessionalDisinfectionCategoryIcon,
  Electrical: ElectricalCategoryIcon,
  once_off: CalendarIcon,
  yearly: RefreshIcon,
  weekly: RefreshIcon,
  // Country Flag Icons
  MYFlagIcon,
  SGFlagIcon,
  THFlagIcon,
  UKFlagIcon
};

export default function (iconIdentifier, isServiceCard) {
  const src = iconSwitch[iconIdentifier];
  if (isServiceCard && !src) return OtherIcon;
  return src;
}
