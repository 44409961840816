import React, { useEffect } from 'react';
import styles from '../../style.module.scss';
import { connect } from 'react-redux';
import { Grid, Card, Typography, Divider, Box, useMediaQuery } from '@material-ui/core';
import { formValueSelector } from 'redux-form';
import setIconSource from 'lib/setIconSource';
import { formatPrice } from 'lib/currencyFormatter';
import dateIcon from 'assets/icons/DateTime.svg';
import locationIcon from 'assets/icons/location.svg';
import moment from 'moment';
import 'moment/locale/th';
import { CheckoutButton } from '../button';
import { useTranslation } from 'react-i18next';
import infoSrc from 'assets/icons/Info.svg';
import { useDispatch } from 'react-redux';
import { updateProcess } from 'redux/modal';

const SummaryComponent = ({
  selectedSalesItem,
  timeslots,
  alternative,
  totalAmount = {},
  name,
  address,
  endDate,
  pricePerSession
}) => {
  const { t: translate } = useTranslation('Booking');
  const isOnMobile = useMediaQuery('(max-width:992px)', { noSsr: true });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateProcess(5));
  }, []);
  if (isOnMobile) {
    return (
      <Card className={styles.summaryCard}>
        <Grid container wrap="nowrap" justify="space-between" className="height_100">
          <Grid item container className={styles.cardContainer}>
            {pricePerSession ? (
              <>
                <Typography variant="h5">{translate('youWillPay')}</Typography>
                <Grid item container wrap="nowrap">
                  <Typography variant="h2" className={styles.total}>
                    {formatPrice(pricePerSession.amountCents, pricePerSession.currencyCode)}
                  </Typography>
                  <Typography variant="body2" className={`${styles.textGray} ml_5`}>
                    /{translate('session')}
                  </Typography>
                </Grid>
              </>
            ) : (
              <Grid item container alignItems="center">
                <Typography className={styles.textDark} variant="body2">
                  {translate('totalAmount')}
                </Typography>
                {/* <Box ml={1}>
                  <img src={infoSrc} alt="infoSrc" />
                </Box> */}
                <Grid item container>
                  <Typography className={styles.total}>
                    {formatPrice(totalAmount.amountCents, totalAmount.currencyCode)}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item container alignItems="center">
            <CheckoutButton translate={translate} />
          </Grid>
        </Grid>
      </Card>
    );
  }
  return (
    <Card className={styles.summaryCard}>
      <Grid container className={styles.cardContainer} wrap="nowrap">
        <Box mr={2}>
          <Grid item>
            <img src={setIconSource('Cleaning')} alt="image_source" />
          </Grid>
        </Box>
        <Grid item>
          <Typography className={styles.summaryTitle} variant="h3">
            {name}
          </Typography>
          <Typography className={styles.textDark}>
            {selectedSalesItem && selectedSalesItem.name}
          </Typography>
        </Grid>
      </Grid>
      <Divider />

      <Grid container className={styles.cardContainer}>
        <Grid item container wrap="nowrap">
          <Box mr={2}>
            <img src={dateIcon} alt="icon_date" />
          </Box>
          <Grid item container>
            <Typography className={styles.address}>
              {endDate
                ? `${moment(timeslots).format(translate('Common:dateFormat2'))} -
                ${moment(endDate).format(translate('Common:dateFormat2'))}`
                : timeslots && moment(timeslots).format(translate('Common:datetimeFormat'))}
            </Typography>
            {alternative && (
              <Typography className={`${styles.address} mb_15 w_100`} variant="body2">{`${translate(
                'alternative'
              )}: ${moment(alternative).format(translate('Common:datetimeFormat'))}`}</Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container wrap="nowrap">
          <Box mr={2}>
            <img src={locationIcon} alt="icon_location" />
          </Box>
          <Typography className={styles.address}>{address}</Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid container className={styles.cardContainer}>
        <Grid item container justify="center">
          {pricePerSession ? (
            <>
              <Typography variant="h3">{translate('youWillPay')}</Typography>
              <Grid className="mt_15" item container alignItems="center" direction="column">
                <Typography variant="h2" className={styles.total}>
                  {formatPrice(pricePerSession.amountCents, pricePerSession.currencyCode)}
                </Typography>
              </Grid>
              <Typography variant="subtitle2" className={styles.textGray}>
                /{translate('session')}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h1">{translate('total')}</Typography>
              <Grid item container justify="center">
                <Typography
                  className={styles.textDark}
                  style={{ textAlign: 'center' }}
                  variant="body2"
                >
                  {translate('includedTaxes')}
                </Typography>
              </Grid>
              <Grid className="mt_10" item container alignItems="center" direction="column">
                <Typography variant="h2" className={styles.total}>
                  {formatPrice(totalAmount.amountCents, totalAmount.currencyCode)}
                </Typography>
              </Grid>
            </>
          )}
          <CheckoutButton translate={translate} />
        </Grid>
      </Grid>
    </Card>
  );
};
const bookingFormSelector = formValueSelector('BookingForm');

const mapStateToProps = (state) => {
  const selectedService = state.service && state.service.selectedService;
  const locationSetting =
    state.user && state.user.bookingLocation && state.user.bookingLocation;
  const { selectedSalesItem, frequency } = bookingFormSelector(
    state,
    'selectedSalesItem',
    'frequency'
  );
  return {
    name: (selectedService && selectedService.name) || '',
    selectedSalesItem: selectedSalesItem || null,
    frequency: frequency || '',
    address: locationSetting && locationSetting.address
  };
};

export default connect(mapStateToProps, null)(SummaryComponent);
