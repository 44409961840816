import React from 'react';
import styles from './styles.module.scss';
import { Grid, Typography, Box, Link, useMediaQuery } from '@material-ui/core';
import mobileSrc from 'assets/icons/mobile.svg';
import appStoreSrc from 'assets/image/appStore.svg';
import googleStoreSrc from 'assets/image/googlePlay.svg';
import qrSrc from 'assets/image/qrCode.svg';
import { useTranslation } from 'react-i18next';

const SHApplicationConnection = () => {
  const { t: translate } = useTranslation('Common');
  const isOnMobile = useMediaQuery('(max-width:767px)', { noSsr: true });
  return (
    <>
      <Grid className={styles.sh_app_connection} container item>
        <Grid item xs={isOnMobile ? 12 : 10}>
          <Grid container>
            <img src={mobileSrc} alt="mobile_img" />
            <Typography variant="h5" className={styles.sh_app_title}>
              {translate('shMobileApp')}
            </Typography>
          </Grid>
          <Box mt={1} mb={3}>
            <Typography className={styles.sh_app_content}>{translate('downloadAppNow')}</Typography>
          </Box>
          <Grid container alignItems="center">
            <Link
              className={isOnMobile ? 'mr_10' : ''}
              href="https://itunes.apple.com/my/app/servishero-on-demand-services/id992334828?mt=8"
              target="_blank"
            >
              <img src={appStoreSrc} alt="app_store" />
            </Link>

            <Link
              href="https://play.google.com/store/apps/details?id=com.servishero.consumer&amp;hl=en"
              target="_blank"
            >
              <img
                className={!isOnMobile ? styles.google_img : ''}
                src={googleStoreSrc}
                alt="google_store"
              />
            </Link>
            { !isOnMobile &&
              <Typography className={styles.sh_app_content}>{translate('scanToDownload')}</Typography>
            }
          </Grid>
        </Grid>
        { !isOnMobile &&
          <Grid item container xs={2} justify="center" alignItems="center">
            <Grid item className={styles.qr_code}>
              <img src={qrSrc} alt="qr_code" />
            </Grid>
          </Grid>
        }
      </Grid>
    </>
  );
};

export default SHApplicationConnection;
