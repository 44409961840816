import React from 'react';
import styles from './style.module.scss';
import { Grid, Typography, CardContent, Card } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/th';

const FrequencyComponent = ({ sessions, translate, setRef }) => {
  const renderFrequency = () => {
    return (
      <ul>
        {sessions.map((session, index) => {
          return (
            <li key={index} className={index % 2 !== 0 ? styles.highLight : ''}>
              <Typography>{index + 1}</Typography>
              <Typography variant="body2">
                {moment(session).format(translate('Common:datetimeFormatWithDayName'))}
              </Typography>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Card
      ref={(newRef) => setRef(newRef)}
      style={{ minHeight: '400px' }}
      className={styles.card_frequency}
    >
      <CardContent className={styles.cardContent}>
        <Typography variant="h1" className={`${styles.title} mb_25`}>
          {translate('frequencySchedule')}
        </Typography>
        <Grid className={styles.frequency} container>
          {sessions && renderFrequency()}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default FrequencyComponent;
