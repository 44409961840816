import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles.module.scss';

const ProofPaymentDetails = (props) => {
  const {openProofPaymentDetails, handleClosingProofPaymentDetails, proofPaymentFile} = props;

  return (<Dialog open={openProofPaymentDetails} classes={{paper: styles.dialogProofPayment}} >
    <DialogActions className={styles.dialogActionsProofPayment}>
      <Button
        variant="contained"
        className={styles.buttonCloseProofPayment}
        size="medium"
        startIcon={<CloseIcon />}
        onClick={handleClosingProofPaymentDetails}
      >
        Close
      </Button>
    </DialogActions>
    <DialogContent className={styles.dialogContentProofPayment}>
      <img src={URL.createObjectURL(proofPaymentFile)} alt="proof payment" />
    </DialogContent>
  </Dialog>)
}

export default ProofPaymentDetails;
