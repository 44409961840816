// export { default as TransferViaPayNowSG } from './transfer_via_pay_now_for_sg';
import React from 'react';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';

import BankInformation from './bank_information';
import TransferViaPayNowSG from './transfer_via_pay_now_for_sg';

const optionMapping = {
  duit_now: BankInformation,
  pay_now: TransferViaPayNowSG
};

const GET_BANK_DETAILS = gql`
  query ($country: String!) {
    bankDetails(country: $country) {
      accountName
      accountNumber
      bankName
      swiftCode
    }
  }
`;

const BankTransfer = ({
  translate,
  proofPaymentFile,
  setProofPaymentFile,
  order,
  paymentMethodClassName,
  countryCode,
  isSelectedPaymentMethod,
  handleChangePaymentMethod,
  isDisablePaymentMethod,
  bankTransferMethods = []
}) => {
  if (!Array.isArray(bankTransferMethods) || !bankTransferMethods.length) return null;

  const {
    loading: loadingBankDetails,
    error: errorBankDetails,
    data: bankDetailsData
  } = useQuery(GET_BANK_DETAILS, { variables: { country: countryCode } });

  return (
    <>
      {bankTransferMethods.map((bankItem, indexPaymentMethod) => {
        const Comp = optionMapping[bankItem.option] || BankInformation;
        return (
          <Comp
            key={indexPaymentMethod}
            className={paymentMethodClassName(bankItem.value)}
            expanded={isSelectedPaymentMethod(bankItem.value)}
            handleChangePaymentMethod={handleChangePaymentMethod}
            countryCode={countryCode}
            order={order}
            setProofPaymentFile={setProofPaymentFile}
            proofPaymentFile={proofPaymentFile}
            translate={translate}
            label={countryCode == 'my' ? translate('duitNowQrPayment') : bankItem.name}
            isDisablePaymentMethod={isDisablePaymentMethod(bankItem.value)}
            loadingBankDetails={loadingBankDetails}
            errorBankDetails={errorBankDetails}
            bankDetailsData={bankDetailsData}
            item={bankItem}
          />
        );
      })}
    </>
  );
};

export default BankTransfer;
