import React, { useState } from 'react';
import { Grid, Radio, Typography, FormControlLabel, Button, Box } from '@material-ui/core';
import styles from './style.module.scss';
import setIconSource from 'lib/setIconSource';
import clsx from 'clsx';
import EditIcon from 'assets/icons/Edit.svg';
import SaveIcon from 'assets/icons/Union.svg';
import { Trans } from 'react-i18next';

const SelectInput = ({
  title,
  subtitle,
  price,
  value,
  name,
  onClick,
  selectStyle,
  handleClick,
  scheduleType,
  translate,
  titleIcon,
  dateTime,
  savePrice
}) => {
  let isShowEditIcon = false;
  if (scheduleType === 'once_off' && dateTime) {
    isShowEditIcon = true;
  } else if (scheduleType !== 'once_off' && scheduleType === value) {
    isShowEditIcon = true;
  }
  const imgSrc = setIconSource(name);
  const EditComponent = () => {
    return (
      <Grid container className={styles.btnEdit} onClick={handleClick}>
        <Typography>{scheduleType === 'once_off' ? dateTime : translate(value)}</Typography>
        <Box ml={1}>
          <img src={EditIcon} alt="edit_icon" />
        </Box>
      </Grid>
    );
  };
  return (
    <FormControlLabel
      value={value}
      onChange={onClick}
      className={styles.answerContainer}
      control={
        <Grid
          container
          className={clsx(selectStyle, styles.increaseButton)}
          alignItems="center"
        >
          <Grid container item xs={10} wrap="nowrap" justify="space-between">
            <Grid item container wrap="nowrap">
              {imgSrc && (
                <Grid item className={styles.imageContainer}>
                  <img className={styles.imageIcon} src={imgSrc} alt="image_icon" />
                </Grid>
              )}
              <Grid item>
                {title && (
                  <Grid className={styles.dateContainer}>
                    <Typography className={styles.increaseItem} variant="subtitle1">
                      {title}
                    </Typography>
                    {titleIcon && (
                      <Box ml={1}>
                        <img className={styles.imageIcon} src={titleIcon} alt="title_icon" />
                      </Box>
                    )}
                  </Grid>
                )}
                <Grid className={styles.dateContainer}>
                  {isShowEditIcon ? <EditComponent /> : <Typography>{subtitle}</Typography>}
                </Grid>
                {price && <Typography className={styles.price}>{price}</Typography>}
              </Grid>
            </Grid>

            {savePrice && (
              <Grid
                item
                className={styles.saveContainer}
              >
                <img src={SaveIcon} alt="save_icon" />
                <Typography align="center" noWrap className={styles.savePrice} variant="body2">
                  <Trans
                    className={styles.savePrice}
                    i18nKey="Booking:saveAmount"
                    values={{ price: savePrice }}
                  ></Trans>
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container item xs={2} justify="flex-end">
            <Radio classes={{ checked: styles.checked }} value={value} />
          </Grid>
        </Grid>
      }
    />
  );
};
export default SelectInput;
