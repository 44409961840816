export default {
  changePassword: 'เปลี่ยนรหัสผ่าน',
  gender: 'เพศ',
  dateOfBirth: 'วันเกิด',
  personalDetails: 'ข้อมูลส่วนตัว',
  editProfile: 'แก้ไขข้อมูล',
  name: 'ชื่อ',
  male: 'ชาย',
  female: 'หญิง',
  undefine: 'ไม่ระบุ',
  changedYourProfileSuccessfully: 'อัปเดตข้อมูลเรียบร้อย',
  savePlaces: 'สถานที่ที่บันทึกไว้',
}
