import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import BuildingIcon from '@material-ui/icons/HomeWork';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import styles from './Notifier.module.scss';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  location: BuildingIcon
};

const defaultTimeToHideNotifier = 4000;

function MySnackbarContentWrapper(props) {
  const { className, message, closeSnack, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (

    <SnackbarContent
      className={styles[variant]}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={styles.message}>
          <Icon className={`${styles.icon, styles.iconVariant}`} />
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={closeSnack}
        >
          <CloseIcon className={styles.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'location']).isRequired
};

let openSnackbarFn;

class Notifier extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      variant: props.variant || 'info',
      vertical: props.vertical || 'top',
      horizontal: props.vertical || 'center',
      message: props.message,
      autoHideDuration: defaultTimeToHideNotifier,
      closedCallback: null
    };

    this.handleSnackbarRequestClose = this.handleSnackbarRequestClose.bind(this);
    this.openSnackbar = this.openSnackbar.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }


  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  handleSnackbarRequestClose = () => {
    this.setState({
      open: false,
      message: '',
    });
  };

  openSnackbar = ({ message, variant, autoHideDuration, closedCallback }) => {
    this.setState({
      message,
      open: true,
      variant: variant || 'info',
      autoHideDuration: autoHideDuration || defaultTimeToHideNotifier,
      closedCallback
    });
  };


  handleClose(_event, reason) {
    if (reason === 'clickaway') return;

    this.setState({ open: false, message: null });
    if (this.state.closedCallback) this.state.closedCallback();
  }

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: this.state.vertical || 'top',
          horizontal: this.state.horizontal || 'center'
        }}
        open={this.state.open}
        autoHideDuration={this.state.autoHideDuration}
        onClose={this.handleClose}
      >
        <MySnackbarContentWrapper
          closeSnack={this.handleClose}
          variant={this.state.variant}
          message={this.state.message}
        />
      </Snackbar>
    );
  }
}

export function openSnackbar(options) {
  openSnackbarFn(options);
}

export default Notifier;
