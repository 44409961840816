import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Link,
  SvgIcon,
  Divider,
  Typography,
  Menu
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { AppContext } from 'routes';
import { ReactComponent as ServisHeroLogo } from 'assets/logos/sh-full-logo.svg';
import { ReactComponent as AddBookingIcon } from 'assets/icons/plus.svg';
import { ReactComponent as SelectedAddBookingIcon } from 'assets/icons/selected_plus.svg';
import { ReactComponent as JobsIcon } from 'assets/icons/jobs.svg';
import { ReactComponent as SelectedJobsIcon } from 'assets/icons/selected_jobs.svg';

import { ReactComponent as NotiIcon } from 'assets/icons/noti.svg';
import { ReactComponent as UserIcon } from 'assets/icons/circleUserIcon.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menuIcon.svg';
import styles from './styles.module.scss';
import setIconSource from 'lib/setIconSource';
import { useTranslation } from 'react-i18next';
import { supportedCountries } from 'lib/locationAndLanguage';
import { useDispatch, useSelector } from 'react-redux';
import { handleOpeningAuthDialog } from 'redux/auth';
import { toggleContactSupportDialog, toggleGetAppDialog } from 'redux/modal';
import { rootPath, buildFullPath } from 'lib/helper';
import helpIcon from 'assets/icons/Help.svg';
import mobileIcon from 'assets/icons/mobile.svg';
import infoIcon from 'assets/icons/Info.svg';


import ecoworldLogo from 'assets/logos/partner_logo/sh_x_ecoworld.svg';
import livingOsLogo from 'assets/logos/partner_logo/sh_x_livingos.svg';
import aigLogo from 'assets/logos/partner_logo/ShxAIxEa.svg';
import senhengLogo from 'assets/logos/partner_logo/senheng_d.svg'

const partners = {
  'a1351ce8-0227-4dbf-87d4-6b089d384202': ecoworldLogo,
  'ae478e20-5361-416d-90a9-7d35b5f639d4': livingOsLogo,
  '1eaa6113-4e32-4190-bc33-603001611db6': aigLogo,
  '79cd1ea3-c21c-475b-b752-53bf9df1b155': senhengLogo
};

const MenuDropdown = (props) => {
  const {
    translate,
    handleOpenMenuDropdown,
    anchorElForMenuDropdown,
    handleCloseMenuDropdown,
    handleShowingContactSupport,
    handleShowingGetApp
  } = props;
  return (
    <>
      <IconButton aria-label="menu" onClick={handleOpenMenuDropdown}>
        <SvgIcon component={MenuIcon} />
      </IconButton>
      <Menu
        anchorEl={anchorElForMenuDropdown}
        keepMounted
        open={Boolean(anchorElForMenuDropdown)}
        onClose={handleCloseMenuDropdown}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        style={{ padding: 0 }}
      >
        <Grid container direction="column" className={styles.userMenuPopover}>
          <Grid
            item
            container
            className={styles.rowStyle}
            onClick={(e) => handleShowingContactSupport(e)}
          >
            <Grid item>
              <img src={helpIcon} alt="mobile" />
            </Grid>
            <Grid item className="pl_15">
              <Typography variant="subtitle1">{translate('Common:contactForSupport')}</Typography>
            </Grid>
          </Grid>

          <Link
            href={buildFullPath('terms', 'https://servishero.com')}
            target="_blank"
            className={styles.linkText}
          >
            <Grid item container className={styles.rowStyle}>
              <Grid item>
                <img src={infoIcon} alt="mobile" />
              </Grid>
              <Grid item className="pl_15">
                <Typography variant="body1">{translate('Common:termsOfService')}</Typography>
              </Grid>
            </Grid>
          </Link>

          <Divider />
          <Grid item container className={styles.rowStyle} onClick={(e) => handleShowingGetApp(e)}>
            <Grid item>
              <img src={mobileIcon} alt="mobile" />
            </Grid>
            <Grid item className="pl_15">
              <Typography variant="subtitle1">{translate('Common:getApp')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};

const MenuLanguage = (props) => {
  const {
    translate,
    handleOpenCountriesMenu,
    anchorElForCountriesMenu,
    handleCloseCountriesMenu,
    // countryCode,
    preferredLanguage
  } = props;
  const countries = supportedCountries();

  const countryCode = window.location.pathname.split("/")?.[1] || "th"
  
  return (
    <>
      <IconButton onClick={handleOpenCountriesMenu}>
        {countryCode && (
          <img src={setIconSource(countryCode.toUpperCase() + 'FlagIcon')} alt={countryCode} />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorElForCountriesMenu}
        keepMounted
        open={Boolean(anchorElForCountriesMenu)}
        onClose={handleCloseCountriesMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Grid container direction="column" className={styles.languagePopover}>
          <Typography style={{ fontWeight: 700 }}>
            {translate('language.language')}{' '}
          </Typography>

          <Grid style={{ marginTop: 24 }}>
            {countries.filter((item) => item.country.code === "th").map((item, cindex) => {
              const itemCountryCode = item.country.code;
              return (
                <Grid container key={cindex} direction="column" style={{ width: 250 }}>
                  <Grid
                    container
                    key={cindex}
                    justify="space-between"
                    alignItems="center"
                    style={{ width: '100%' }}
                  >
                    {/* <Grid container item justify="flex-start" alignItems="flex-start" xs={6}>
                      <Grid item style={{ width: 24, height: 24, marginRight: 8 }}>
                        <img
                          src={setIconSource(itemCountryCode.toUpperCase() + 'FlagIcon')}
                          alt={countryCode}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{ fontWeight: countryCode === itemCountryCode ? 700 : 500 }}
                        >
                          {item.country.name}
                        </Typography>
                      </Grid>
                    </Grid> */}

                    <Grid item xs={6}>
                      {item.languages.map((language, lindex) => {
                        const languageCode = language.code;
                        const isCurrentPreferredLanguage =
                        countryCode === itemCountryCode && languageCode === preferredLanguage;

                        return (
                          <Button
                            key={lindex}
                            className={`${styles.countryButton}
                        ${isCurrentPreferredLanguage ? styles.selectedCountryButton : null}`}
                            href={isCurrentPreferredLanguage ? null : language.redirectUrl}
                          >
                            {language.name}
                          </Button>
                        );
                      })}
                    </Grid>
                  </Grid>
                  {cindex < countries.length - 1 && (
                    <Divider style={{ margin: '12px 0' }}></Divider>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};

const UserMenuDropdown = (props) => {
  const {
    currentUser,
    handleOpenUserDropdown,
    anchorElForUserDropdown,
    translate,
    handleCloseUserDropdown
  } = props;
  return (
    <>
      <IconButton
        aria-label="menu"
        className={`${styles.profileButton} ml_10`}
        onClick={handleOpenUserDropdown}
      >
        <SvgIcon component={UserIcon} />
      </IconButton>
      <Menu
        anchorEl={anchorElForUserDropdown}
        keepMounted
        open={Boolean(anchorElForUserDropdown)}
        onClose={handleCloseUserDropdown}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        style={{ padding: 0 }}
      >
        <Grid container direction="column" className={styles.userMenuPopover}>
          <Grid container justify="center" alignItems="center" style={{ width: '100%' }}>
            <IconButton aria-label="menu" className={styles.userBtn}>
              <SvgIcon component={UserIcon} className={styles.userIcon} />
            </IconButton>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ width: '100%' }}
            className={styles.userName}
          >
            {currentUser.name}
          </Grid>
          <Grid container justify="center" alignItems="center" style={{ width: '100%' }}>
            <Button
              variant="outlined"
              href={buildFullPath('profile')}
              className={styles.circleOutlineBtn}
            >
              {translate('Auth:viewProfile')}
            </Button>
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};

const AppHeader = ({ history }) => {
  const { t: translate } = useTranslation('Header');
  const dispatch = useDispatch();
  const countryCode = useSelector((state) => state.user.geoLocation.countryCode);
  const preferredLanguage = useSelector((state) => state.user.preferredLanguage);
  const currentUser = useSelector((state) => state.user.currentUser);
  const selectedPage = useSelector((state) => state.modal.currentPage);

  const contextProps = useContext(AppContext);
  const currentPartner = contextProps.searchParams.partnerId;
  
  const [anchorElForCountriesMenu, setAnchorElForCountriesMenu] = useState(null);
  const [anchorElForUserDropdown, setAnchorElForUserDropdown] = useState(null);
  const [anchorElForMenuDropdown, setAnchorElForMenuDropdown] = useState(null);

  const topBarRef = React.createRef();

  const handleOpenCountriesMenu = (e) => {
    setAnchorElForCountriesMenu(topBarRef.current);
  };

  const handleCloseCountriesMenu = () => {
    setAnchorElForCountriesMenu(null);
  };

  const handleOpenUserDropdown = (e) => {
    setAnchorElForUserDropdown(topBarRef.current);
  };

  const handleCloseUserDropdown = () => {
    setAnchorElForUserDropdown(null);
  };

  const handleOpenMenuDropdown = (e) => {
    setAnchorElForMenuDropdown(topBarRef.current);
  };

  const handleCloseMenuDropdown = () => {
    setAnchorElForMenuDropdown(null);
  };

  const handleOpenLoginForm = () => {
    dispatch(handleOpeningAuthDialog(true));
  };

  const handleShowingContactSupport = (e) => {
    e.preventDefault();
    dispatch(toggleContactSupportDialog(true));
  };

  const handleShowingGetApp = (e) => {
    e.preventDefault();
    dispatch(toggleGetAppDialog(true));
  };

  const pushToPage = (name) => {
    history.push({
      pathname: `/${countryCode}/${preferredLanguage}/${name}`
    });
  };

  const pushToRootWithParams = () => {
    let searchParams = ''
    if (contextProps.searchParams && contextProps.searchParams.urlSearchParams) {
      searchParams = contextProps.searchParams.urlSearchParams;
    };
    history.push({
      pathname: `/${countryCode}/${preferredLanguage}`,
      search : searchParams || ''
    });
  }

  return (
    <>
      <AppBar position="static" color="inherit" ref={topBarRef} className={styles.appBar}>
        <Toolbar className="height_100">
          {
            !!currentPartner && !!partners[currentPartner] ?
              <IconButton onClick={pushToRootWithParams} edge="start" aria-label="menu" className={`${styles.partnerLogoButton}`}>
                <img src={partners[currentPartner]} alt="partner_logo" className={styles.partnerLogo}/>
              </IconButton> :
              <IconButton href={rootPath()} edge="start" aria-label="menu">
                <SvgIcon viewBox="0 0 31 31" color="primary" component={ServisHeroLogo} />
              </IconButton>
          }
          <div style={{ flexGrow: 1 }}>
            <Button
              className={`${
                selectedPage === 'booking' ? styles.selectedSection : null
              } mr_10 ${styles.quickSelectBtn}`}
              onClick={() => pushToPage('')}
              startIcon={
                <SvgIcon
                  component={selectedPage === 'booking' ? SelectedAddBookingIcon : AddBookingIcon}
                />
              }
            >
              {translate('bookingService')}
            </Button>
            {/* <Button
              className={`${selectedPage === 'jobs' ? styles.selectedSection : null} ${
                styles.quickSelectBtn
              }`}
              onClick={() => pushToPage('jobs')}
              startIcon={
                <SvgIcon component={selectedPage === 'jobs' ? SelectedJobsIcon : JobsIcon} />
              }
            >
              {translate('jobsHistory')}
            </Button> */}
          </div>
          <div>
            <Grid container alignItems="center" wrap='nowrap'>
              {/* language menu */}
              <MenuLanguage
                translate={translate}
                handleOpenCountriesMenu={handleOpenCountriesMenu}
                anchorElForCountriesMenu={anchorElForCountriesMenu}
                handleCloseCountriesMenu={handleCloseCountriesMenu}
                countryCode={countryCode}
                preferredLanguage={preferredLanguage}
              />
              {/* End language menu */}

              {/* Menu dropdown */}
              <MenuDropdown
                translate={translate}
                handleOpenMenuDropdown={handleOpenMenuDropdown}
                anchorElForMenuDropdown={anchorElForMenuDropdown}
                handleCloseMenuDropdown={handleCloseMenuDropdown}
                handleShowingContactSupport={handleShowingContactSupport}
                handleShowingGetApp={handleShowingGetApp}
              />
              {/* End Menu dropdown */}

              {/* User dropdown */}
              {currentUser && (
                <>
                  {/* Have not supported notification yet. */}
                  {/* <IconButton aria-label="menu">
                    <SvgIcon component={NotiIcon} />
                  </IconButton> */}
                  <Button
                    className={`shade_light_btn ${styles.myProfileNav} ml_10`}
                    href={buildFullPath('profile')}
                  >
                    <SvgIcon
                      viewBox="0 0 32 32"
                      component={UserIcon}
                      className={`mr_10 ${styles.userIcon}`}
                    />
                    {translate('Auth:myProfile')}
                  </Button>
                </>
              )}

              {/* End User dropdown */}

              {!currentUser && (
                <Button
                  onClick={handleOpenLoginForm}
                  className={`shade_light_btn ${styles.signUpBtn} ml_10`}
                >
                  {translate('Auth:login')}/{translate('Auth:signUp')}
                </Button>
              )}
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default withRouter(AppHeader);
