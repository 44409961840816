import React, { useState } from 'react';
import {
  Button,
  Grid,
  Link,
  Typography,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';

import { TextInput } from 'components';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Trans } from 'react-i18next';
import { buildFullPath } from 'lib/helper';

let SignUpForm = ({ handleSubmit, translate }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleClickShowConfirmPassword() {
    setShowConfirmPassword(!showConfirmPassword);
  }
  return (
    <Grid item>
      <form onSubmit={handleSubmit}>
        <Grid container item>
          <Field
            component={TextInput}
            variant="filled"
            name="fullName"
            label={translate('fullName')}
            required
          />
        </Grid>
        <Grid className="mt_10" container item>
          <Field
            component={TextInput}
            variant="filled"
            name="email"
            label={translate('email')}
            required
          />
        </Grid>
        <Grid className="mt_10" container item>
          <Field
            component={TextInput}
            variant="filled"
            name="phoneNumber"
            label={translate('phoneNumber')}
            type="text"
            required
          />
        </Grid>
        <Grid className="mt_10" container item>
          <Field
            component={TextInput}
            variant="filled"
            name="password"
            label={translate('password')}
            type={showPassword ? 'text' : 'password'}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                    tabIndex={-1}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            required
          />
        </Grid>
        <Grid className="mt_10" container item>
          <Field
            component={TextInput}
            variant="filled"
            name="confirmPassword"
            label={translate('confirmPassword')}
            type={showConfirmPassword ? 'text' : 'password'}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    tabIndex={-1}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            required
          />
        </Grid>
        <Grid className="mt_10 mb_20" container item>
          <Typography>
            <Trans i18nKey="Auth:signUpAgreeing">
              <Link href={buildFullPath('terms', 'https://servishero.com')} target="_blank" className='link_style' />
            </Trans>
          </Typography>
        </Grid>
        <Button type="submit" className='primary_yellow_btn' variant="contained">
          {translate('signUp')}
        </Button>
      </form>
    </Grid>
  );
};

const validate = (values, props) => {
  const { translate } = props;
  const errors = {};
  if (!values.email) {
    errors.email = translate('Auth:required');
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = translate('invalidEmail');
  }
  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = translate('confirmPasswordNotMatch');
  }
  return errors;
};

SignUpForm = reduxForm({ form: 'signUpForm', validate })(SignUpForm);

export default SignUpForm;
