import React, { useEffect } from 'react';
import styles from '../payment.module.scss';
import { RadioGroup, Typography, Box, CircularProgress } from '@material-ui/core';
import { SelectInput } from 'components';
import 'moment/locale/th';
import { useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/client';
import { formatPrice } from 'lib/currencyFormatter';
import { useSelector } from 'react-redux';
import { toggleErrorPage } from 'redux/modal';

const PaymentTypeQuery = gql`
  query($serviceId: ID!) {
    service(serviceId: $serviceId) {
      paymentTypes {
        id
        active
        name
        value
        discount {
          absoluteAmount {
            amountCents
            currencyCode
          }
          percentage
        }
      }
    }
  }
`;

const PaymentType = ({ translate, setSelectedPaymentType, selectedPaymentType, setFetchApiError }) => {
  const directServiceId = useSelector(state => ((state.service || {}).selectedService || {}).id);

  const { loading, error, data } = useQuery(PaymentTypeQuery, {
    variables: {
      serviceId: directServiceId
    }
  });

  if (loading) {
    return (
      <div className="loading-content">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    setFetchApiError(true);
    return null;
  }

  const handleClickType = (event) => {
    setSelectedPaymentType(event.target.value);
  };

  const { service } = data;
  if (!selectedPaymentType && service && service.paymentTypes) {
    setSelectedPaymentType && setSelectedPaymentType(service.paymentTypes[0].value);
  }

  return (
    <>
      <Typography variant="h3" className={styles.title}>
        {translate('paymentType')}
      </Typography>
      <Box mt={1} mb={2}>
        <Typography variant="subtitle1" className={styles.textDark}>
          {translate('paymentTypeDescription')}
        </Typography>
      </Box>
      {service.paymentTypes.length > 0 && (
        <RadioGroup
          value={selectedPaymentType}
          onChange={handleClickType}
          className={styles.radioGroup}
        >
          {service.paymentTypes.map((type, index) => {
            let savePrice = '';
            if (type.discount.percentage > 0) {
              savePrice = `${type.discount.percentage}%`;
            } else if (type.discount.absoluteAmount.amountCents > 0) {
              savePrice = formatPrice(
                type.discount.absoluteAmount.amountCents,
                type.discount.absoluteAmount.currencyCode
              );
            }
            return (
              <SelectInput
                savePrice={savePrice}
                key={index}
                value={type.value}
                subtitle={type.name}
                selectStyle={styles.paymentType}
              />
            );
          })}
        </RadioGroup>
      )}
    </>
  );
};

export default PaymentType;
