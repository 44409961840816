import React from 'react';
import {
  Button,
  Grid,
  Container,
  Link,
} from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import styles from './../../styles.module.scss';
import AuthBySocialNetwork from './authBySocialNetworkHandler';

const SignUpNavigatorHandler = (props) => {
  const {
    handleShowingLoginForm,
    handleShowingSignUpForm,
    translate
  } = props;

  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      alignItems="flex-start"
      className={styles.signUpNavWrapper}
    >
      <Grid container className="mb_30">
        <AuthBySocialNetwork translate={translate} />

        <Grid className="mt_10" container item>
          <Button
             onClick={() => handleShowingSignUpForm()}
            variant="outlined"
            className='shade_light_btn full_width'
            startIcon={<MailOutlineIcon />}
          >
            { translate('signUpWithEmail')}
          </Button>
        </Grid>
      </Grid>

      <Grid item className="ml_10 mb_20">
        <span className="mr_10"> {translate('alrdHaveAccount')}</span>
        <Link onClick={() => handleShowingLoginForm()} className='link_style'>
          {translate('login')}
        </Link>
      </Grid>
    </Grid>
  );
};

export default SignUpNavigatorHandler;
