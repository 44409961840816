import React, { useContext } from 'react';
import { Card, CardMedia, CardActionArea, Typography, CircularProgress } from '@material-ui/core';
import styles from './styles.module.scss';
import { withRouter } from 'react-router-dom';
import DefaultServiceImage from 'assets/image/defaultServiceImage.svg';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { setSelectedService } from 'redux/service';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from 'routes';

const GET_POPULAR_SERVICES = gql`
  query {
    popularServices {
      id
      name
      description
      image
      disabled
      disabledImage
      disabledMessage
      recurring
      recurringType
      directCategory {
        name
      }
    }
  }
`;

const PopularServices = ({ history }) => {
  const dispatch = useDispatch();
  const { loading, data } = useQuery(GET_POPULAR_SERVICES);
  const userState = useSelector((state) => state.user);
  const contextProps =  useContext(AppContext);
  
  if (loading) return <div className='loading-content'><CircularProgress /></div>;

  const handleClick = async (service) => {
    await dispatch(setSelectedService(service));
    const url = `/${userState.geoLocation.countryCode}/${userState.preferredLanguage}/booking`;
    let searchParams = ''
    if (contextProps.searchParams && contextProps.searchParams.urlSearchParams) {
      searchParams = contextProps.searchParams.urlSearchParams;
    }

    history.push({
      pathname: url,
      search : searchParams || ''
    });
  };

  return (
    <>
      {data && data.popularServices.length > 0 && (
        <div className={styles.listServices}>
          {data.popularServices.map((service, index) => (
            <Card onClick={() => handleClick(service)} className={styles.serviceItem} key={index}>
              <CardActionArea classes={{root: styles.cardActionArea}}>
                <CardMedia
                  component="img"
                  alt={service.name}
                  image={service.image || DefaultServiceImage}
                  title={service.name}
                  className={styles.cardImage}
                />
                <div className={styles.serviceName}>
                  <Typography gutterBottom component="h4">
                    {service.name}
                  </Typography>
                </div>
              </CardActionArea>
            </Card>
          ))}
        </div>
      )}
    </>
  );
};

export default withRouter(PopularServices);
