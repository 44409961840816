import React, { useContext } from 'react';
import {
  Button,
  Grid,
} from '@material-ui/core';
import { signInWithFacebook, signInWithAppleId } from 'redux/auth';
import { showSuccessMessage, showErrorMessage } from 'lib/notifier';
import { AppContext } from 'routes';

import styles from './socialNetworkStyles.module.scss';
import fbIcon from 'assets/icons/fb.svg';
import appleIcon from 'assets/icons/Apple.svg';
import { FacebookButton } from 'components';
import AppleSignin from 'react-apple-signin-auth';
import CONSTANTS from 'constants/config';
import { handleOpeningAuthDialog } from 'redux/auth';
import { useDispatch, useSelector } from 'react-redux';

const AuthBySocialNetworkHandler = (props) => {
  const { translate } = props;
  const dispatch = useDispatch();
  const countryCode = useSelector(state => state.user.geoLocation.countryCode);
  const contextProps = useContext(AppContext);

  const handleSubmitFacebook = async (token) => {
    await dispatch(signInWithFacebook(token, countryCode, contextProps.searchParams));
    showSuccessMessage(translate('loginSuccess'));
    dispatch(handleOpeningAuthDialog(false));
  };

  const handleSubmitApple = async ({ authorization }) => {
    if (!authorization) return showErrorMessage(translate('authFailed'));
    const data = {
      identityToken: authorization.id_token,
      countryCode: countryCode
    }
    await dispatch(signInWithAppleId(data, contextProps.searchParams));
    showSuccessMessage(translate('loginSuccess'));
    dispatch(handleOpeningAuthDialog(false));
  };

  return (
    <>
      <Grid container item>
        <FacebookButton handleSubmitFacebook={handleSubmitFacebook}>
          <Button
            variant="outlined"
            className={styles.fb_btn}
            startIcon={<img src={fbIcon} alt="fb_icon" />}
          >
            {translate('continueWithFB')}
          </Button>
        </FacebookButton>
      </Grid>
      <Grid className="mt_10" container item>
        <AppleSignin
          authOptions={{
            clientId: CONSTANTS.APPLE_CLIENT_ID,
            scope: 'email name',
            redirectURI: CONSTANTS.REDIRECT_URI,
            state: 'state',
            nonce: 'nonce',
            usePopup: true
          }}
          uiType="dark"
          onSuccess={(response) => handleSubmitApple(response)}
          onError={(error) => console.error(error)}
          skipScript={false}
          render={(props) => (
            <Button
              variant="outlined"
              className={styles.apple_btn}
              startIcon={<img src={appleIcon} alt="fb_icon" />}
              {...props}
            >
              {translate('continueWithApple')}
            </Button>
          )}
        />
      </Grid>
    </>
  );
};

export default AuthBySocialNetworkHandler;
