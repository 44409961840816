export default {
  changePassword: 'Change Password',
  gender: 'Gender',
  dateOfBirth: 'Date of Birth',
  personalDetails: 'Personal Details',
  editProfile: 'Edit Profile',
  name: 'Name',
  male: 'Male',
  female: 'Female',
  undefine: 'Undefine',
  changedYourProfileSuccessfully: 'Your profile was updated successfully',
  savePlaces: 'Saved Places',
}
