import React from 'react';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { SummaryComponent } from '../summary';
import { CircularProgress } from '@material-ui/core';

const GET_YEARLY_PREVIEW = gql`
  query(
    $directServiceId: ID!
    $frequency: YearlyFrequency!
    $selectedSalesItem: SelectedSalesItem!
    $selectedOptionalSalesItems: [ID!]!
  ) {
    yearlyRecurringServicePlanPreview(
      directServiceId: $directServiceId
      frequency: $frequency
      selectedSalesItem: $selectedSalesItem
      selectedOptionalSalesItems: $selectedOptionalSalesItems
    ) {
      id
      duration
      durationUnit
      sessions
      totalSession
      startDate
      endDate
      serviceName
      totalAmount {
        amountCents
        currencyCode
      }
      subtotalAmount {
        amountCents
        currencyCode
      }
      pricePerSession {
        amountCents
        currencyCode
      }
      discountDetails {
        name
        percentage
        priceAbsoluteAmount {
          amountCents
          currencyCode
        }
      }
    }
  }
`;

const YearlyComponent = ({
  selectedSalesItem,
  selectedOptionalItems,
  directServiceId,
  frequency,
  setFetchApiError
}) => {
  const { loading, error, data } = useQuery(GET_YEARLY_PREVIEW, {
    variables: {
      directServiceId,
      selectedOptionalSalesItems: selectedOptionalItems,
      selectedSalesItem,
      frequency
    }
  });

  if (loading)
    return (
      <div className="loading-content">
        <CircularProgress />
      </div>
    );

  if (error || !data) {
    setFetchApiError(true);
    return null;
  }

  const { yearlyRecurringServicePlanPreview } = data;
  const selectedPreviewPlan =
    (yearlyRecurringServicePlanPreview && yearlyRecurringServicePlanPreview[0]) || '';
  return (
    <SummaryComponent
      selectedSalesItem={selectedSalesItem}
      totalAmount={selectedPreviewPlan.totalAmount}
      timeslots={selectedPreviewPlan.startDate}
      endDate={selectedPreviewPlan.endDate}
      pricePerSession={selectedPreviewPlan.pricePerSession}
    />
  );
};

export default YearlyComponent;
