import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Select,
  MenuItem,
  Chip,
  Divider,
  List,
  ListItem,
  IconButton,
  Box
} from '@material-ui/core';
import styles from './styles.module.scss';
import lightBubSrc from 'assets/icons/lightBub.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TimeSelector, DateSelector } from 'components';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import 'moment/locale/th';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { toggleDateTimeModal } from 'redux/modal';

const GET_SESSION_PER_YEAR_OPTIONS_FOR_YEARLY_RECURRING_API = gql`
  query($directServiceId: ID!, $baseVariationId: ID!) {
    sessionPerYearOptionsForYearlyRecurring(
      directServiceId: $directServiceId
      baseVariationId: $baseVariationId
    ) {
      sessionPerYearOptions {
        absoluteAmount {
          amountCents
          currencyCode
        }
        percentage
        sessionNumber
      }
    }
  }
`;

const GET_YEARLY_RECURRING_PLAN_PREVIEW_API = gql`
  query(
    $directServiceId: ID!
    $frequency: YearlyFrequency!
    $selectedSalesItem: SelectedSalesItem!
    $selectedOptionalSalesItems: [ID!]!
  ) {
    yearlyRecurringServicePlanPreview(
      directServiceId: $directServiceId
      frequency: $frequency
      selectedSalesItem: $selectedSalesItem
      selectedOptionalSalesItems: $selectedOptionalSalesItems
    ) {
      id
      duration
      durationUnit
      sessions
      totalSession
      startDate
      endDate
      serviceName
      totalAmount {
        amountCents
        currencyCode
      }
      subtotalAmount {
        amountCents
        currencyCode
      }
      discountDetails {
        name
        percentage
        priceAbsoluteAmount {
          amountCents
          currencyCode
        }
      }
    }
  }
`;

const YearlyModal = ({
  changeField,
  translate,
  selectedSalesItem,
  selectedOptionalItems,
  directServiceId,
  selectedFrequency,
  isOpenModal,
  toggleDateTimeModalDispatch
}) => {
  const { loading: occurenceLoading, error: occurenceError, data: occurenceData } = useQuery(
    GET_SESSION_PER_YEAR_OPTIONS_FOR_YEARLY_RECURRING_API,
    {
      variables: {
        directServiceId,
        baseVariationId: selectedSalesItem && selectedSalesItem.baseVariationId
      }
    }
  );

  const { refetch: refetchPlanPreview } = useQuery(GET_YEARLY_RECURRING_PLAN_PREVIEW_API, {
    skip: true
  });

  const [selectedOccurrenceOption, setSelectedOccurrenceOption] = useState('');

  const handleChangeOccurrenceSelect = (event) => {
    setSelectedOccurrenceOption(event.target.value);
  };
  const [selectedDate, handleDateChange] = useState('');
  const [selectedTime, handleTimeChange] = useState('');
  const [showFrequency, setShowFrequency] = useState(false);
  const [frequencies, setFrequencies] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const isOnMobile = useMediaQuery('(max-width:992px)', { noSsr: true });

  const handleClickConfirm = () => {
    const frequency = {
      numberSessionPerYear: selectedOccurrenceOption.sessionNumber,
      startDate: moment(selectedDate).format(translate('Common:dateFormat2')),
      startTime: moment(selectedTime, translate('Common:timeFormat')).diff(
        moment().startOf('day'),
        'seconds'
      )
    };
    changeField('frequency', frequency);
    changeField('recurringServicePlanId', selectedPlan.id);
    changeField('sessions', selectedPlan.sessions);
    toggleDateTimeModalDispatch(false);
  };
  useEffect(async () => {
    if (!selectedDate || !selectedTime || !selectedOccurrenceOption) {
      // do nothing
      return;
    }

    const planPreviewResponse = await refetchPlanPreview({
      directServiceId,
      selectedSalesItem,
      selectedOptionalSalesItems: selectedOptionalItems,
      frequency: {
        startDate: selectedDate.format(translate('Common:dateFormat2')),
        startTime: parseTimeStringToSecond(selectedTime),
        numberSessionPerYear: selectedOccurrenceOption.sessionNumber
      }
    });

    if (
      planPreviewResponse &&
      planPreviewResponse.data &&
      planPreviewResponse.data.yearlyRecurringServicePlanPreview &&
      planPreviewResponse.data.yearlyRecurringServicePlanPreview[0] &&
      planPreviewResponse.data.yearlyRecurringServicePlanPreview[0].sessions
    ) {
      setFrequencies(planPreviewResponse.data.yearlyRecurringServicePlanPreview[0].sessions);
      setSelectedPlan(planPreviewResponse.data.yearlyRecurringServicePlanPreview[0]);
      setShowFrequency(true);
    }
  }, [selectedDate, selectedTime, selectedOccurrenceOption]);

  const parseTimeStringToSecond = (s) => {
    // 11:00 AM
    const [time, period] = s.split(' ');
    const [hour, minute] = time.split(':');
    if (!period) return parseInt(hour) * 3600 + parseInt(minute) * 60;
    return (
      parseInt(hour) * 3600 +
      parseInt(minute) * 60 +
      (hour === '12' || period.toLowerCase() === 'am' ? 0 : 43200)
    );
  };

  return (
    <Dialog classes={{ paper: styles.yearlyDialog }} open={isOpenModal} maxWidth="md">
      <Grid className={styles.modalTitle}>
        {isOnMobile && (
          <Grid container item justify="flex-end">
            <IconButton
              className={styles.closeBtn}
              onClick={() => {
                if (!selectedFrequency) changeField('dateType', '');
                toggleDateTimeModalDispatch(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
        <Grid className="height_100" container justify="space-between" alignItems="center">
          <Typography variant="h3" className={styles.header}>
            {translate('custom')}
          </Typography>
          {!isOnMobile && (
            <IconButton
              className={styles.closeBtn}
              onClick={() => {
                if (!selectedFrequency) changeField('dateType', '');
                toggleDateTimeModalDispatch(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <DialogContent className={styles.dialogContent}>
        {!occurenceLoading && (
          <>
            <Box p={3} className={styles.selectContent}>
              <Grid container item alignItems="center">
                <Typography className={styles.textDark}>{translate('need')}</Typography>
                <Select
                  variant="outlined"
                  IconComponent={ExpandMoreIcon}
                  renderValue={(val) =>
                    val ? translate('times', { time: val.sessionNumber }) : translate('selectOcc')
                  }
                  value={selectedOccurrenceOption}
                  onChange={handleChangeOccurrenceSelect}
                  className={styles.occurrenceSelectWrapper}
                  classes={{
                    select: styles.occurrenceSelect
                  }}
                  displayEmpty
                >
                  {occurenceData &&
                    occurenceData.sessionPerYearOptionsForYearlyRecurring &&
                    occurenceData.sessionPerYearOptionsForYearlyRecurring.sessionPerYearOptions &&
                    occurenceData.sessionPerYearOptionsForYearlyRecurring.sessionPerYearOptions
                      .length > 0 &&
                    occurenceData.sessionPerYearOptionsForYearlyRecurring.sessionPerYearOptions.map(
                      (o, index) => (
                        <MenuItem key={index} value={o} className={styles.occurrenceOption}>
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography>{`${o.sessionNumber} times`} </Typography>
                            </Grid>
                            <Grid item>
                              <Chip
                                className={styles.occurrenceOptionChip}
                                label={`Save ${o.percentage}%`}
                              />
                            </Grid>
                          </Grid>
                        </MenuItem>
                      )
                    )}
                </Select>
                <Typography className={styles.textDark}>{translate('perYear')}</Typography>
              </Grid>
              <Box mt={4}>
                <Grid container item alignItems="center">
                  <Typography className={`${styles.textDark} ${isOnMobile ? 'w_100' : ''}`}>
                    {translate('firstService')}
                  </Typography>
                  <DateSelector
                    autoOk
                    disablePast
                    disableToolbar
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                  <Typography className={styles.textDark}>{translate('at')}</Typography>
                  <Box ml={isOnMobile ? 1 : 2}>
                    <TimeSelector selectedTime={selectedTime} translate={translate} onChange={handleTimeChange} />
                  </Box>
                </Grid>
              </Box>
            </Box>
            {showFrequency && (
              <>
                {!isOnMobile && <Divider />}
                <Grid className={styles.frequencyContent}>
                  <Typography className={` ${styles.frequencyPadding} pt_20`} variant="h3">
                    {translate('frequencySchedule')}
                  </Typography>
                  <Grid>
                    <List>
                      {frequencies.map((frequency, index) => (
                        <ListItem
                          className={clsx(
                            index % 2 !== 0 ? styles.grayBackground : '',
                            styles.frequencyPadding
                          )}
                          key={index}
                        >
                          <Box mr={3}>
                            <Typography>{index + 1}</Typography>
                          </Box>
                          <Typography>
                            {moment(frequency).format(translate('Common:datetimeFormatWithDayName'))}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions className={styles.modalFooter}>
        <Grid container justify={isOnMobile ? 'flex-end' : 'flex-start'} wrap="nowrap">
          <Grid container item wrap="nowrap">
            <Grid item>
              <Box mr={isOnMobile ? 1 : 2}>
                <img src={lightBubSrc} alt="lightBubSrc" />
              </Box>
            </Grid>
            <Grid item container direction="column">
              <Typography className={styles.textDark} variant="body2">
                {translate('didYouKnow')}
              </Typography>
              <Typography className={styles.textDark} variant="body2">
                {translate('recommend')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={isOnMobile ? styles.btnContainer : ''}>
            <Button
              className={clsx('primary_yellow_btn', styles.actionButton)}
              variant="contained"
              disabled={!selectedOccurrenceOption || !selectedTime || !selectedDate || frequencies.length <= 0}
              onClick={handleClickConfirm}
            >
              {translate('confirm')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
const bookingFormSelector = formValueSelector('BookingForm');

const mapStateToProps = (state) => {
  const selectedService = state.service && state.service.selectedService;
  const directServiceId = (selectedService && selectedService.id) || null;
  const isOpenModal = state.modal.openDateTimeModal;
  return {
    directServiceId,
    selectedOptionalItems: bookingFormSelector(state, 'selectedOptionalSalesItems') || [],
    selectedSalesItem: bookingFormSelector(state, 'selectedSalesItem') || null,
    selectedFrequency: bookingFormSelector(state, 'frequency') || '',
    isOpenModal: isOpenModal || false
  };
};
const mapDispatchToProps = (dispatch) => ({
  changeField: (field, value) => dispatch(change('BookingForm', field, value)),
  toggleDateTimeModalDispatch: (value) => dispatch(toggleDateTimeModal(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(YearlyModal);
