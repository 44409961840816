import React, { useEffect, useState } from 'react';
import { Modal, Grid, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { TextInput, GoogleMap } from 'components';
import styles from './styles.module.scss';

let LocationConfirmation = (props) => {
  const { open, location,
    confirmLocationCancel,
     translate, pristine, invalid, submitting, handleSubmit, ...rest } = props;

  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (location) {
      setMarkers([{
        location: {
          lat: location.lat,
          lng: location.lng,
        },
        content: location.address
      }])
    };

  }, [location])


  return <Modal
    className={styles.confirmationModal}
    open={open}
  >
    <Grid className={styles.modalWrapper}>
      <Grid className={styles.modalHeader}>
        <Grid className={styles.title}>{translate('Header:location.confirmYourAddress')}</Grid>
        <Grid className={styles.closeModal} onClick={e => confirmLocationCancel()}>
          <Close className="close-icon"/>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Grid container >
          <Grid item xl={12} lg={12} md={12} sm={12}>
            <Grid className={styles.mapStyles}>
              <GoogleMap places={markers} zoom={15} />
            </Grid>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={styles.address}>
            <span>{location.address}</span>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
            <Field
              name='unitNumber'
              component={TextInput}
              label={translate('Header:location.unitNumber')}
              margin="dense"
              required
              variant="filled"
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={styles.additionalNoteeTitle}>
            <span>{translate('Header:location.additionalNoteTitle')}</span>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Field
              name='remarks'
              component={TextInput}
              label={translate('Header:location.additionalNote')}
              margin="dense"
              variant="filled"
              multiline
              rows="4"
            />

          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
            <Grid className="mt_10">
              <Button
                variant="contained"
                type="submit"
                className='primary_yellow_btn full_width'
                disabled={pristine || submitting || invalid}
              >
                {translate('Common:confirm')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  </Modal>

}

LocationConfirmation = reduxForm({
  form: 'locationForm',
  enableReinitialize: true
})(LocationConfirmation);

const mapStateToProps = (_state, ownProps) => {
  const location = ownProps.location || {};
  return ({
    initialValues: {
      address: location.address,
      placeId: location.placeId,
      countryCode: location.countryCode,
      countryName: location.countryName,
      locality: location.locality,
      lat: location.lat,
      lng: location.lng,
      unitNumber: location.unitNumber,
      remarks: location.remarks,
    },

  });
}

export default compose(
  connect(mapStateToProps, null),

)(LocationConfirmation);
