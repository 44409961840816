import React from "react";
import { Helmet } from "react-helmet";

// SEO for pages - to use as one pager first - will add more as we handle dynamic url for services and partners
const defaultMetadata = {
  // META TITLE - Google typically shows 55-64 characters (keep it under 60)
  defaultTitle: "ServisHero - Book Your Services Now!",
  // META DESCRIPTION - up to 160 chars
  // Add clear Call-to-action (CTA) in your descriptions like Apply today, Check-out, Contact us today etc.
  defaultDescription:
    "Book on-demand home services for your home or office today. Services we provide includes cleaning, air-cond services, disinfection, electrical, plumbing, handyman and so on.",
  defaultSiteUrl: "https://book.servishero.com",
  // The Canonical tag is an HTML link tag with “rel=canonical” attribute
  // that is used if you have a single page URL that has the same content
  // with other multiple page URLs.
  defaultCanonical: {
    isCanonical: true,
    canonicalUrl: "https://book.servishero.com",
  },
  // keywords are not very relevant anymore, they should be used to construct title & description instead.
  // good to have
  keywords: {},
  defaultIcons: {
    16: {
      sizes: "16x16",
      type: "img/png",
      href: "/public/servishero-favicon-16x16.png",
      rel: "icon",
    },
    32: {
      sizes: "32x32",
      type: "img/png",
      href: "/public/servishero-favicon-32x32.png",
      rel: "icon",
    },
    70: {
      sizes: "70x70",
      type: "img/png",
      href: "/public/servishero-favicon-70x70.png",
      rel: "icon",
    },
    96: {
      sizes: "96x96",
      type: "img/png",
      href: "/public/servishero-favicon-96x96.png",
      rel: "icon",
    },
    128: {
      sizes: "128x128",
      type: "img/png",
      href: "/public/servishero-favicon-128x128.png",
      rel: "icon",
    },
    192: {
      sizes: "192x192",
      type: "img/png",
      href: "/public/servishero-favicon-192x192.png",
      rel: "icon",
    },
    228: {
      sizes: "228x228",
      type: "img/png",
      href: "/public/servishero-favicon-228x228.png",
      rel: "icon",
    },
    270: {
      sizes: "270x270",
      type: "img/png",
      href: "/public/servishero-favicon-270x270.png",
      rel: "icon",
    },
    310: {
      sizes: "310x310",
      type: "img/png",
      href: "/public/servishero-favicon-310x310.png",
      rel: "icon",
    },
    // apple
    120: {
      sizes: "120x120",
      type: "img/png",
      href: "/public/servishero-favicon-120x120.png",
      rel: "apple-touch-icon",
    },
    152: {
      sizes: "152x152",
      type: "img/png",
      href: "/public/servishero-favicon-152x152.png",
      rel: "apple-touch-icon",
    },
    167: {
      sizes: "167x167",
      type: "img/png",
      href: "/public/servishero-favicon-167x167.png",
      rel: "apple-touch-icon",
    },
    180: {
      sizes: "180x180",
      type: "img/png",
      href: "/public/servishero-favicon-180x180.png",
      rel: "apple-touch-icon",
    },
    // android
    196: {
      sizes: "196x196",
      type: "img/png",
      href: "/public/servishero-favicon-196x196.png",
      rel: "shortcut icon",
    },
  },
  // robots provides instructions to web crawlers on whether to index or noindex a web page.
  // FOLLOW –The search engine crawler will follow all the links in that web page
  // INDEX –The search engine crawler will index the whole web page
  // NOFOLLOW – The search engine crawler will NOT follow the page and any links in that web page
  // NOINDEX – The search engine crawler will NOT index that web page
  defaultRobots: "index, follow",
};

const Seo = ({ children, metadata }) => {
  const {
    defaultTitle,
    defaultDescription,
    defaultSiteUrl,
    defaultCanonical,
    defaultIcons,
    defaultRobots,
  } = defaultMetadata;

  const Icons = () => {
    defaultIcons.map((icon) => {
      <link
        rel={icon.rel}
        type={icon.type}
        href={icon.href}
        sizes={icon.sizes}
      />;
    });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>{metadata ? metadata.title : defaultTitle}</title>
        {metadata && metadata.canonical ? (
          metadata.canonical.isCanonical ? (
            <link rel="canonical" href={metadata.canonicalUrl} />
          ) : (
            nil
          )
        ) : (
          <link rel="canonical" href={defaultCanonical.canonicalUrl} />
        )}
        <meta
          name="description"
          content={metadata ? metadata.description : defaultDescription}
        />
        {metadata && <meta name="keywords" content={metadata.keywords} />}
        <Icons />
        <meta
          name="robots"
          content={metadata ? metadata.robots : defaultRobots}
        />
        <meta
          property="og:title"
          content={metadata ? metadata.title : defaultTitle}
        />
        <meta
          property="og:url"
          content={metadata ? metadata.siteUrl : defaultSiteUrl}
        />
        <meta
          property="og:description"
          content={metadata ? metadata.description : defaultDescription}
        />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/assets.servishero.com/Images/icons/icon-256x256.png"
        />
      </Helmet>
      {children}
    </>
  );
};

export default Seo;
