/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField } from '@material-ui/core';

const REGEX_CHARACTER_EXCEPT_NUMBER = /(?![+])[\D]/;

const TextInput = (props) => {
  const {
    children,
    classes,
    input,
    inputClass,
    inputProps,
    label,
    name,
    helperText,
    meta: { touched, error, warning },
    ...rest
  } = props;

  function allowNumberOnly(event) {
    // allow keyCode for number from 0 to 9 (48 to 57)
    // delete (8), backspace(46), dot(190), up-down-left-right anchor (37 -> 40)
    // numpad (96 -> 105)
    // 61, 187 was +
    // copy & paste ctrl/cmd + v/c (86, 67)
    let allow = true;
    allow =
      allow &&
      ![8, 9, 46, 65, 86, 67, 187, 61, 13].includes(event.keyCode) &&
      (event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105) &&
      (event.keyCode < 37 || event.keyCode > 40);

    const iptProps = props.inputProps || (props.InputProps && props.InputProps.inputProps);
    // if it is decimal, then we need to check step attributes for this input and allow dot key(190, 110).
    if (iptProps && iptProps.step) {
      allow = allow && event.keyCode !== 190 && event.keyCode !== 110;
    }
    return allow && event.preventDefault();
  }

  const isValid = (string) => {
    const isContainPlus = string.indexOf('+') === -1;
    if (isContainPlus) return true;
    /**
     * for e.g:
     * +090909091, 090909 : split [0] was "" & length <=2
     * +090909091+ :split [0] was "" & length is 3
     * 090+909091  : split[0] was 090
     */
    const splitString = string.split('+');
    const isCorrectPosition = splitString[0] === '' && splitString.length <= 2;
    return isCorrectPosition;
  };

  const handleChange = (e) => {
    const word = e.target.value;
    if (props.type !== 'tel') return input.onChange(word);

    const isNotNumber = REGEX_CHARACTER_EXCEPT_NUMBER.exec(word);
    const validResult = isValid(word);

    if (isNotNumber || !validResult) return;
    input.onChange(word);
  };

  return (
    <TextField
      id={name}
      fullWidth
      label={label}
      name={name}
      InputProps={{
        classes,
        inputProps
      }}
      className={inputClass}
      error={touched && error !== undefined}
      helperText={touched ? error || helperText : helperText}
      {...input}
      {...rest}
      onChange={handleChange}
      onKeyDown={(event) => props.type === 'tel' && allowNumberOnly(event)}
    >
      {children}
    </TextField>
  );
};

export default TextInput;
