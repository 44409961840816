import React from 'react';
import { Dialog, Grid, Typography, useMediaQuery, IconButton } from '@material-ui/core';
import styles from './styles.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { showSuccessMessage } from 'lib/notifier';

const NewsModal = ({ openNewsModal, setOpenNewsModal, newsContent }) => {
  const isOnMobile = useMediaQuery('(max-width:767px)', { noSsr: true });
  const { t: translate } = useTranslation('Common');

  const handleCopy = () => {
    // current not work on mobile
    // TODO: have to find a way to copy on mobile
    try {
      navigator.clipboard.writeText(newsContent.promocode);
      showSuccessMessage(translate('copied'), 500);
    } catch(e) {}
  };
  return (
    <Dialog
      onClose={() => setOpenNewsModal(false)}
      maxWidth="md"
      classes={{ paper: styles.newsModal }}
      open={openNewsModal}
    >
      <Grid container justify="flex-end" className={isOnMobile ? 'p_15' : 'p_25'}>
        <IconButton onClick={() => setOpenNewsModal(false)}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid container className={styles.contentContainer}>
        {newsContent.image && (
          <img className={styles.image} src={newsContent.image} alt="news_image" />
        )}
        <Grid item container className="mt_20">
          <Typography variant="h1">{newsContent.header}</Typography>
        </Grid>

        {newsContent.promocode && (
          <Grid
            container
            wrap="nowrap"
            justify="space-around"
            alignItems="center"
            className={styles.codeContainer}
            onClick={!isOnMobile && handleCopy}
          >
            <Typography variant="h4">{newsContent.promocode}</Typography>
            {!isOnMobile && (
              <Typography variant="h3" className="ml_10">
                {translate('copy')}
              </Typography>
            )}
          </Grid>
        )}
        <Grid item container className="mt_20">
          <div dangerouslySetInnerHTML={{ __html: newsContent.message }} />
        </Grid>
      </Grid>
    </Dialog>
  );
};
export default NewsModal;
