import React, { useEffect } from 'react';
import styles from '../payment.module.scss';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Box,
  Divider,
  Button,
  CircularProgress,
  List,
  ListItem
} from '@material-ui/core';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import setIconSource from 'lib/setIconSource';
import dateIcon from 'assets/icons/DateTime.svg';
import locationIcon from 'assets/icons/location.svg';
import moment from 'moment';
import 'moment/locale/th';
import { formatPrice } from 'lib/currencyFormatter';
import { setVoucher } from 'redux/modal';
import { openModalVoucher } from 'redux/modal';
import { Trans } from 'react-i18next';

const RECURRING_SUMMARY = gql`
  query($orderId: ID!, $paymentType: String, $discountCode: String) {
    recurringBookingSummary(
      orderId: $orderId
      paymentType: $paymentType
      discountCode: $discountCode
    ) {
      endDate
      startDate
      serviceName
      order {
        publicId
        paymentPlans {
          month
          numberSessionPerMonth
          totalPrice {
            amountCents
            currencyCode
          }
        }
        paymentType
        totalSessionNumber
        totalPrice {
          amountCents
          currencyCode
        }
        discountDetails {
          name
          percentage
          priceAbsoluteAmount {
            amountCents
            currencyCode
          }
          absoluteAmountPerSession {
            amountCents
            currencyCode
          }
        }
        totalPrice {
          amountCents
          currencyCode
        }
        pricePerSession {
          amountCents
          currencyCode
        }
        discountedPricePerSession {
          amountCents
          currencyCode
        }
        sessions
        directService {
          id
        }
        address {
          address
        }
        totalDiscountedPrice {
          amountCents
          currencyCode
        }
        salesItems {
          name
          price {
            currencyCode
            amountCents
          }
        }
        directService {
          id
          name
        }
        job {
          id
          publicId
          isRecurringJob
        }
      }
    }
  }
`;

const RecurringSummary = ({
  voucherCode,
  openModalVoucherDispatch,
  orderId,
  setVoucherDispatch,
  setOrder,
  translate,
  paymentType,
  setFetchApiError
}) => {
  if (!orderId) return null;
  let variables = {
    orderId
  };
  if (paymentType) variables = { ...variables, paymentType };

  const { loading, error, data } = useQuery(RECURRING_SUMMARY, {
    variables
  });

  if (loading) {
    return (
      <div className="loading-content">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    setFetchApiError(true);
    return null;
  }

  const {
    recurringBookingSummary: { order, startDate, endDate }
  } = data;

  if (order) {
    setOrder && setOrder(order);
  }

  return (
    <>
      {/* summary content */}
      <Grid container item className={styles.summaryContent}>
        <Grid container item className={styles.cardContainer} wrap="nowrap" alignItems="center">
          <Box mr={2}>
            <Grid item>
              <img src={setIconSource('Cleaning')} alt="image_source" />
            </Grid>
          </Box>
          <Grid item>
            <Typography variant="h3" className={styles.title}>
              {order.directService.name}
            </Typography>
            <Typography>{order.salesItems && order.salesItems[0].name}</Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          className={styles.cardContainer}
          alignItems="center"
          justify="center"
          direction="column"
        >
          <Grid item container wrap="nowrap">
            <Box mr={2}>
              <img src={dateIcon} alt="icon_date" />
            </Box>
            <Typography className={styles.textGray}>
              {`${moment(startDate).format(translate('Common:dateFormat2'))} -
              ${moment(endDate).format(translate('Common:dateFormat2'))}`}
            </Typography>
          </Grid>
          <Grid item container wrap="nowrap">
            <Box mr={2}>
              <img src={locationIcon} alt="icon_location" />
            </Box>
            <Typography className={styles.textGray}>{order.address.address}</Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid
          container
          className={styles.cardContainer}
          wrap="nowrap"
          direction="column"
          justify="center"
        >
          <Grid item container wrap="nowrap" alignItems="center">
            <Grid item container justify="center" className="weight_bold">
              <Trans
                i18nKey="Payment:youWillPay"
                values={{
                  price: formatPrice(
                    order.discountedPricePerSession.amountCents,
                    order.discountedPricePerSession.currencyCode
                  )
                }}
                components={{
                  blue: <Typography className={styles.pricePerSession} variant="h3" />
                }}
              />
            </Grid>
          </Grid>
          <Grid container item justify="center" className="weight_bold mt_15">
            {paymentType === 'monthly_recurring' && order.paymentPlans
              ? formatPrice(
                  order.paymentPlans[0].totalPrice.amountCents,
                  order.paymentPlans[0].totalPrice.currencyCode
                )
              : formatPrice(
                  order.totalDiscountedPrice.amountCents,
                  order.totalDiscountedPrice.currencyCode
                )}
          </Grid>
          <Typography variant="body2" align="center" className={styles.description}>
            {translate('willBeCharged')}
          </Typography>
          <Typography variant="body2" align="center" className={styles.description}>
            {translate('description')}
          </Typography>
        </Grid>
      </Grid>

      {/* payment plan */}

      {order.paymentPlans.length > 0 && (
        <Grid item container className={`${styles.summaryContent} mt_15`}>
          <Typography variant="h3" className="p_20">
            {translate('paymentPlan')}
          </Typography>
          <List className={`w_100 ${styles.list}`}>
            {order.paymentPlans.map((plan, index) => (
              <ListItem
                className={`${styles.listItem} ${index % 2 !== 0 && styles.highLight}`}
                key={index}
              >
                <Typography variant="body2" className={`${styles.textDark} ${styles.monthDisplay}`}>
                  {plan.month}
                </Typography>
                <Typography variant="body2" className={styles.textDark}>{`${
                  plan.numberSessionPerMonth
                } ${translate('session')}`}</Typography>
                <Typography variant="body1" className="weight_bold">
                  {formatPrice(plan.totalPrice.amountCents, plan.totalPrice.currencyCode)}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    orderId: state.payment && state.payment.currentOrderId,
    voucherCode: (state.modal && state.modal.voucherCode) || ''
  };
};

const mapDispatchToProps = (dispatch) => ({
  openModalVoucherDispatch: (status) => dispatch(openModalVoucher(status)),
  setVoucherDispatch: (index) => dispatch(setVoucher(index))
});
export default connect(mapStateToProps, mapDispatchToProps)(RecurringSummary);
