import React from 'react';
import styles from './welcome.module.scss';
import { connect } from 'react-redux';
import { Grid, Container, Button, Typography, Box, useMediaQuery } from '@material-ui/core';
import backgroundSrc from 'assets/image/welcome_bg.svg';
import plusCircleSrc from 'assets/icons/plusCircle.svg';
import subscriptionSrc from 'assets/icons/subscription.svg';
import rightIconSrc from 'assets/icons/righticon.svg';
import { rootPath, buildFullPath } from 'lib/helper';
import { useTranslation } from 'react-i18next';
import { SHApplicationConnection } from 'components';

const Welcome = () => {
  const { t: translate } = useTranslation('Common');
  const isOnMobile = useMediaQuery('(max-width:767px)', { noSsr: true });
  return (
    <div className={styles.page_wrapper}>
      <img className={styles.image_cover} src={backgroundSrc} alt="bg_img" />
      <Container maxWidth="md" className={styles.container}>
        <Grid item container alignItems="center" direction="column">
          <Grid item>
            <Typography variant="h1" className={styles.title}>
              {translate('welcomeToSH')}
            </Typography>
          </Grid>
          <Box mb={3}>
            <Grid item>
              <Typography className={styles.subtitle}>
                {translate('thanksForSigningUp')}
              </Typography>
            </Grid>
          </Box>
          <Grid container item className="mt_15" spacing={2}>
            <Grid item container md={6} xs={12} className={styles.serviceWrapper}>
              <img className="mr_10" src={plusCircleSrc} alt="plus_circle" />
              <Typography className={styles.serviceTitle} >
                {translate('bookFirstServiceWithPromotion')}
              </Typography>
              <Typography className={styles.serviceDescription}>
                {translate('findServiceAndMakeFirstBooking')}
              </Typography>
            </Grid>
            <Grid container md={6} xs={12} item className={styles.serviceWrapper}>
              <img className="mr_10" src={subscriptionSrc} alt="plus_circle" />
              <Typography className={styles.serviceTitle}>{translate('buySubscription')}</Typography>
              <Typography className={styles.serviceDescription}>
                {translate('buySubscriptionDesc')}
              </Typography>
            </Grid>
            <Grid item container xs={12} alignItems="center" justify="center" className={styles.actions}>
              <Button
                variant="contained"
                href={buildFullPath()}
                className="primary_yellow_btn mt_10"
              >
                {translate('bookNow')}
                <img src={rightIconSrc} alt="right_icon" />
              </Button>
              </Grid>
          </Grid>
          <SHApplicationConnection translate={translate} />
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, null)(Welcome);
