import { POST, GET, PUT } from 'lib/api';
import ExternalAPI from 'constants/ExternalAPI';
import { showErrorMessage } from 'lib/notifier';
import { toggleUpdateContactDialog } from './service';
import { setCurrentUser, setAuthToken } from './user';
// Actions
const SET_TOKEN = 'SET_TOKEN';
const OPEN_AUTH_DIALOAG = 'OPEN_AUTH_DIALOAG';
const SET_CURRENT_USER = 'SET_CURRENT_USER';
const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
const REQUIRE_FIELD = ['name', 'phone_number', 'email'];

const initialState = {
  openAuthDialog: false
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_AUTH_DIALOAG:
      return {
        ...state,
        openAuthDialog: action.status
      };

    default:
      return state;
  }
}

// Action Creators

export function handleOpeningAuthDialog(status) {
  return { type: OPEN_AUTH_DIALOAG, status };
}

function checkRequireField(userInfo) {
  const missingField = REQUIRE_FIELD.filter((field) => !userInfo[field]);
  return missingField.length ? true : false;
}
// side effects, only as applicable
// e.g. thunks, epics, etc

export function signInWithCredentials(email, password, searchParams) {
  const data = {
    auth: { email: email.replace(/\s+$/, ''), password: password.replace(/\s+$/, '') }
  };
  return (dispatch) => {
    return POST(ExternalAPI('GLOBAL', 'AUTH_ENDPOINT'), null, data, searchParams)
      .then(
        (response) => {
          if (response && response.data) {
            if (response.data.jwt) dispatch(setAuthToken(response.data.jwt));
            const isOpenContactForm = checkRequireField(response.data.user);
            if (response.data.user) dispatch(setCurrentUser(response.data.user));
            if (isOpenContactForm) dispatch(toggleUpdateContactDialog(true));
          }
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function signUpMethod(name, email, password, phone_number, country_code, preferred_lang, searchParams) {
  const data = {
    consumer: {
      name: name.replace(/\s+$/, ''),
      email: email.replace(/\s+$/, ''),
      password: password.replace(/\s+$/, ''),
      phone_number: phone_number.replace(/\s+$/, ''),
      country_code,
      preferred_lang
    }
  };

  return (dispatch) => {
    return POST(ExternalAPI('GLOBAL', 'SIGN_UP_ENDPOINT'), null, data, searchParams)
      .then(
        (response) => {
          if (response && response.data) {
            if (response.data.user) dispatch(setCurrentUser(response.data.user));
            if (response.data.jwt) dispatch(setAuthToken(response.data.jwt));
            const isOpenContactForm = checkRequireField(response.data.user);
            if (isOpenContactForm) dispatch(toggleUpdateContactDialog(true));
          }
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        err.data && err.data.errors
          ? showErrorMessage(`Your account could not be created: \n${err.data.errors.join('\n ,')}`)
          : showErrorMessage('Your account could not be created.');
        return Promise.reject(err);
      });
  };
}

export function signInWithFacebook(accessToken, countryCode, searchParams) {
  const data = {
    auth: {
      access_token: accessToken,
      country_code: countryCode,
    }
  };
  return (dispatch) => {
    return POST(ExternalAPI('GLOBAL', 'FB_AUTH_ENDPOINT'), null, data, searchParams)
      .then(
        (response) => {
          if (response && response.data) {
            if (response.data.user) dispatch(setCurrentUser(response.data.user));
            if (response.data.jwt) dispatch(setAuthToken(response.data.jwt));
            const isOpenContactForm = checkRequireField(response.data.user);
            if (isOpenContactForm) dispatch(toggleUpdateContactDialog(true));
          }
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        err.data && err.data.errors
          ? showErrorMessage(`Your account could not be created: \n${err.data.errors.join('\n ,')}`)
          : showErrorMessage('Your account could not be created.');
        return Promise.reject(err);
      });
  };
}

export function signInWithAppleId(options={}, searchParams) {
  const  { identityToken, userName, userIdentity, countryCode} = options;
  const data = {
    auth: {
      name: userName,
      identity_token: identityToken,
      user_identity: userIdentity,
      country_code: countryCode,
    }
  };

  return (dispatch) => {
    return POST(ExternalAPI('GLOBAL', 'APPLE_AUTH_ENDPOINT'), null, data, searchParams)
      .then(
        (response) => {
          if (response && response.data) {
            if (response.data.user) dispatch(setCurrentUser(response.data.user));
            if (response.data.jwt) dispatch(setAuthToken(response.data.jwt));
            const isOpenContactForm = checkRequireField(response.data.user);
            if (isOpenContactForm) dispatch(toggleUpdateContactDialog(true));
          }
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        err.data && err.data.errors
          ? showErrorMessage(`Your account could not be created: \n${err.data.errors}`)
          : showErrorMessage('Your account could not be created.');
        return Promise.reject(err);
      });
  };
}

export function forgotPassword(email) {
  return (dispatch) => {
    return POST(ExternalAPI('GLOBAL', 'FORGOT_PASSWORD_ENDPOINT'), '', {
      email
    })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        err.data && err.data.errors
          ? showErrorMessage(`${err.data.errors}`)
          : showErrorMessage('No account found with this email address, please try again.');
        return Promise.reject(err);
      });
  };
}

export function getUserProfile() {
  return async (dispatch, getState) => {
    const { accessToken } = getState().user;
    try {
      const response = await GET(ExternalAPI('GLOBAL', 'CURRENT_USER_ENDPOINT'), accessToken);
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response);
    }
  };
}

export function changePassword(data) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().user;
    try {
      const response = await PUT(
        ExternalAPI('GLOBAL', 'CHANGE_PASSWORD_ENDPOINT'),
        accessToken,
        data
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response);
    }
  };
}

export function updateUserProfile(data, searchParams) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().user;
    try {
      const response = await PUT(ExternalAPI('GLOBAL', 'CURRENT_USER_ENDPOINT'), accessToken, data, searchParams);
      if (response.data) dispatch(setCurrentUser(response.data));
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response);
    }
  };
}

export function forgotCurrentPassword(data, searchParams) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().user;
    try {
      const response = await POST(
        ExternalAPI('GLOBAL', 'FORGOT_PASSWORD_ENDPOINT'),
        accessToken,
        data,
        searchParams
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error.response);
    }
  };
}
