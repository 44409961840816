import React from 'react';
import GoogleMapReact from 'google-map-react';

const getInfoWindowString = place => {
  return `
    <div>
      <div style="font-size: 16px;">
        ${place.content}
      </div>
    </div>`;
};

// Return map bounds based on list of places
const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds();

  places.forEach((place) => {
    bounds.extend(new maps.LatLng(
      parseFloat(place.location.lat),
      parseFloat(place.location.lng),
    ));
  });
  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds);
    });
  });
};



const fitBounds = (map, maps, places, zoom = 16) => {
  // Get bounds by our places
  const bounds = getMapBounds(map, maps, places);
  // Fit map to bounds
  map.fitBounds(bounds);
  // Bind the resize listener
  bindResizeListener(map, maps, bounds);

  const zoomChangeBoundsListener =
    maps.event.addListenerOnce(map, 'bounds_changed', () => {
      if ( map.getZoom() > zoom ) {
        map.setZoom(zoom); // set zoom to default value
      }
    });

  setTimeout(() => { maps.event.removeListener(zoomChangeBoundsListener); }, 2000);
};

const GoogleMap = (props) => {
  // Define some default variables for map
  props = {
    center: {
      lat: 3.108463,
      lng: 101.528399
    },
    zoom: 16,
    showInfoWindow: false,
    ...props,
  }


  function apiIsLoaded(map, maps) {
    const { places, onClickMarker, showInfoWindow, zoom } = props;
    const markers = [];
    const infowindows = [];
    places.forEach((place) => {
      markers.push(new maps.Marker({
        position: {
          lat: parseFloat(place.location.lat),
          lng: parseFloat(place.location.lng),
        },
        icon: place.icon ? place.icon : null,
        map,
      }));

      if (showInfoWindow) {
        infowindows.push(new maps.InfoWindow({
          content: getInfoWindowString(place),
        }));
      }
    });

    markers.forEach((marker, i) => {
      if (showInfoWindow) {
        marker.addListener('mouseover', () => {
          infowindows[i].open(map, marker);
        });
        marker.addListener('mouseout', () => {
          infowindows[i].close();
        });
      }

      if (onClickMarker) {
        marker.addListener('click', () => {
          onClickMarker(marker);
        });
      }
    });

    if (places.length > 0) {
      fitBounds(map, maps, places, zoom);
    }
  };

  return (
    // Important! Always set the container height explicitly
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.REACT_APP_WEB_GG_MAP_API_KEY,
        language: 'en'
      }}
      yesIWantToUseGoogleMapApiInternals
      defaultCenter={props.center}
      defaultZoom={props.zoom}
      onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
    />
  );
}

export default GoogleMap;
