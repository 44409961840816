import React from 'react';
import UnderConstructImage from 'assets/image/under_construct.svg';
import { Button, Grid, Typography, Container } from '@material-ui/core';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import SHApplication from '../sh_application_connection';
import { buildFullPath } from 'lib/helper';

const UnderConstruct = () => {
  const { t: translate } = useTranslation('Common');

  return (
    <Container className={styles.container} maxWidth="md">
      <img className="mt_20" src={UnderConstructImage} alt="under_construct" />
      <Typography align="center" variant="h2" className="mt_35 mb_10">
        {translate('tryingHard')}
      </Typography>
      <Typography align="center" variant="subtitle1">
        {translate('downloadToTrack')}
      </Typography>
      <SHApplication />
      <Button href={buildFullPath()} className="primary_yellow_btn mt_20 mb_20">
        {translate('backToHome')}
      </Button>
    </Container>
  );
};

export default UnderConstruct;
