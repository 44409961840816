import React from 'react';
import { Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import styles from './style.module.scss';
import setIconSource from 'lib/setIconSource';
import clsx from 'clsx';

const CheckboxInput = ({
  title,
  subtitle,
  price,
  value,
  onClick,
  name,
  selectStyle,
  selectedValues = [],
  isUseDefaultIcon
}) => {
  const imgSrc = setIconSource(name, isUseDefaultIcon);
  return (
    <FormControlLabel
      value={value}
      className={styles.answerContainer}
      onChange={onClick}
      control={
        <Grid
          container
          className={clsx(styles.increaseButton, selectStyle)}
          alignItems="center"
          justify="space-between"
        >
          <Grid container item xs={10} wrap="nowrap">
            {imgSrc && (
              <Grid item className={styles.imageContainer}>
                <img className={styles.imageIcon} src={imgSrc} alt="image_icon" />
              </Grid>
            )}
            <Grid item>
              {title && (
                <Typography className={styles.increaseItem} variant="subtitle1">
                  {title}
                </Typography>
              )}
              {subtitle && <Typography>{subtitle}</Typography>}
              {price && <Typography className={styles.price}>{price}</Typography>}
            </Grid>
          </Grid>
          <Grid container justify="flex-end" item xs={2}>
            <Checkbox
              checked={selectedValues.includes(value)}
              classes={{ checked: styles.checked }}
              value={value}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default CheckboxInput;
