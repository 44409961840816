import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { connect } from 'react-redux';
import {
  Typography,
  Dialog,
  Button,
  Grid,
  IconButton,
  Breadcrumbs,
  useMediaQuery
} from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/th';
import InfoIcon from 'assets/icons/Info.svg';
import WhiteInfoIcon from 'assets/icons/white_info.svg';
import { change, formValueSelector } from 'redux-form';
import { DatePickerHandler, TimeslotButton } from 'components';
import CloseIcon from '@material-ui/icons/Close';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { toggleDateTimeModal } from 'redux/modal';

const workingHours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

const timeFormatter = (formatString) => (hour) => moment(hour, "h").format(formatString);
const dateTimeCombination = (date, time, timeFormatString, dateFormatString) => {
  if (!date || !time) return '';

  const parsedTime = moment(time, timeFormatString);
  return moment(date).startOf('day')
    .hours(parsedTime.hours())
    .minutes(parsedTime.minutes())
    .format(dateFormatString);
}

const TimePicker = ({ selectedTime, onTimeChange, disabledTimeSlots, translate }) => {
  const isOnMobile = useMediaQuery('(max-width:767px)', { noSsr: true });
  const morningTimeslots = workingHours.filter(hour => hour < 12).map(timeFormatter(translate('Common:timeFormat')));
  const afternoonTimeslots = workingHours.filter(hour => hour >= 12).map(timeFormatter(translate('Common:timeFormat')));

  const handleClick = (value) => {
    onTimeChange(value);
  };

  const renderMorningSlot = () => {
    if (isOnMobile) {
      return morningTimeslots.map((hour, index) => (
        <Button
          className={`shade_light_btn mr_10 mt_10 ${styles.buttonTimeSlot}
            ${ selectedTime === hour ? styles.selectedBtn : null }`}
          disabled={disabledTimeSlots.includes(hour)}
          key={index}
          onClick={() => handleClick(hour)}
        >
          {hour}
        </Button>
      ));
    }

    return morningTimeslots.map((hour, index) => {
      return <TimeslotButton
        key={index}
        disabled={disabledTimeSlots.includes(hour)}
        onClick={disabledTimeSlots.includes(hour) ? null : () => handleClick(hour)}
        isSelected={hour === selectedTime}
      >
        <Typography>{hour}</Typography>
      </TimeslotButton>
    });
  };

  const renderAfternoonSlot = () => {
    if (isOnMobile) {
      return afternoonTimeslots.map((hour, index) => (
        <Button
          className={`shade_light_btn mr_10 mt_10 ${styles.buttonTimeSlot}
            ${ selectedTime === hour ? styles.selectedBtn : null }`}
          disabled={disabledTimeSlots.includes(hour)}
          key={index}
          onClick={() => handleClick(hour)}
        >
          {hour}
        </Button>
      ));
    }
    return afternoonTimeslots.map((hour, index) => {
      return <TimeslotButton
        key={index}
        disabled={disabledTimeSlots.includes(hour)}
        onClick={disabledTimeSlots.includes(hour) ? null : () => handleClick(hour)}
        isSelected={hour === selectedTime}
      >
        <Typography>{hour}</Typography>
      </TimeslotButton>
    });
  };

  return (
    <>
      <Typography className={`${styles.subtitle} pl_5`} variant="subtitle1">
        {translate('selectTime')}
      </Typography>
      <Grid className={styles.selectTime} container>
        <Typography className="weight_bold pt_5 pb_5 pl_5" variant="subtitle2">
          {translate('morningSlot')}
        </Typography>
        <Grid className={styles.timeSlot} item container>
          {renderMorningSlot()}
        </Grid>

        <Typography className="weight_bold pt_15 pb_5 pl_5" variant="subtitle2">
          {translate('afternoonSlot')}
        </Typography>
        <Grid className={styles.timeSlot} item container>
          {renderAfternoonSlot()}
        </Grid>
      </Grid>
    </>
  );
};

const DateTimeForm = ({
  translate,
  selectedDate,
  selectedTime,
  onDateChange,
  onTimeChange,
  disabledTimeSlots
}) => {
  const tomorrow = moment().add(1, 'day');
  return (
    <>
      <Grid className={styles.datePickerContainer} item md={8}>
        <DatePickerHandler
          selectedDate={selectedDate}
          onDateChange={onDateChange}
          minDate={tomorrow}
        />
      </Grid>
      <Grid className={styles.timePickerContainer} item md={4}>
        <TimePicker
          translate={translate}
          selectedTime={selectedTime}
          onTimeChange={onTimeChange}
          disabledTimeSlots={disabledTimeSlots}
        />
      </Grid>
    </>
  );
};


const OnceOff = ({
  toggleDateTimeModalDispatch,
  changeField,
  translate,
  selectedTimeslots,
  isOpenModal
}) => {
  const [selectedDate, changeSelectedDate] = useState(moment().add(1, 'day'));
  const [selectedTime, changeSelectedTime] = useState('');
  const [alternativeDate, changeAlternativeDate] = useState(selectedDate);
  const [alternativeTime, changeAlternativeTime] = useState('');
  const [selectingAlternativeTimeslot, setSelectingAlternativeTimeslot] = useState(false);
  // 992px here is hacked to solve broken issue on Ipad
  const isOnMobile = useMediaQuery('(max-width:992px)', { noSsr: true });
  const [ref, setRef] = useState(null);
  useEffect(() => {
    if (ref && selectingAlternativeTimeslot) {
      // wait until document is loaded
      setTimeout(() => {
        document.getElementById('info_container').scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  }, [selectingAlternativeTimeslot]);

  const bookingTimeslotsConfirmationHandler = () => {
    const bookingTimeSlot = dateTimeCombination(selectedDate, selectedTime, translate('Common:timeFormat'));
    const alternativeTimeslot = dateTimeCombination(alternativeDate, alternativeTime, translate('Common:timeFormat'));

    changeField('timeslots', [bookingTimeSlot, alternativeTimeslot]);
    toggleDateTimeModalDispatch(false);
    setSelectingAlternativeTimeslot(false);
  };

  const goingNextStepHandler = () => {
    changeAlternativeDate(selectedDate)
    setSelectingAlternativeTimeslot(true);
  };

  const goingBackStepHandler = () => {
    setSelectingAlternativeTimeslot(false);
    changeAlternativeTime('');
  };

  const onChangeSelectedDate = (date) => {
    changeSelectedDate(date);
    changeSelectedTime('');
  }

  const onChangeAlternativeDate = (date) => {
    changeAlternativeDate(date);
    changeAlternativeTime('');
  }

  // We just allow consumer to book a service over 24 hours from now,
  // so this function is for getting disable time slots within 24 hours
  const getDisabledTimeSlots = (date, formatString) => {
    const today = moment();
    const tomorrow = moment().add(1, 'day');
    if (today.isSame(date, 'day')) {
      return workingHours.map(timeFormatter(formatString));
    } else if (tomorrow.isSame(date, 'day')) {
      const currentHour = today.hours();
      return workingHours.filter(hour => hour <= currentHour).map(timeFormatter(formatString));
    } else {
      return [];
    }
  };

  const disabledTimeSlots = getDisabledTimeSlots(selectedDate, translate('Common:timeFormat'));
  let disabledAlternativeTimeSlots = [];

  if (selectingAlternativeTimeslot) {
    disabledAlternativeTimeSlots = getDisabledTimeSlots(alternativeDate, translate('Common:timeFormat'));

    if (moment(selectedDate).isSame(moment(alternativeDate), 'day')) {
      disabledAlternativeTimeSlots.push(selectedTime);
    }
  }

  return (
    <Dialog
      open={isOpenModal}
      classes={{ paper: styles.dialog }}
      aria-labelledby="max-width-dialog-title"
    >
      <Grid className={styles.modalTitle} container justify="space-between" alignItems="center">
        {isOnMobile && (
          <Grid className={styles.closeContainer} container justify="flex-end">
            <IconButton
              className={styles.closeBtn}
              onClick={() => {
                if (!selectedTimeslots) changeField('dateType', '');
                toggleDateTimeModalDispatch(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
        <Breadcrumbs
          className={styles.breadCrumbContainer}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          classes={{ ol: styles.olBreadCrumb }}
        >
          <Typography
            className={`${selectingAlternativeTimeslot ? styles.bread : styles.currentBread} ${
              styles.cursorStyle
            }`}
            color={selectingAlternativeTimeslot ? 'inherit' : 'textPrimary'}
            onClick={() => setSelectingAlternativeTimeslot(false)}
          >
            {translate('timeslot')}
            {selectedDate && selectedTime && (
              <span className={styles.selectedDateTime}>
                { dateTimeCombination(
                    selectedDate,
                    selectedTime,
                    translate('Common:timeFormat'),
                    translate('Common:datetimeFormat')
                  )
                }
              </span>
            )}
          </Typography>
          <Typography
            className={`${selectingAlternativeTimeslot ? styles.currentBread : styles.bread}
            ${ selectedTime && styles.cursorStyle }`}
            color={selectingAlternativeTimeslot ? 'textPrimary' : 'inherit'}
            onClick={() => selectedTime && setSelectingAlternativeTimeslot(true)}
          >
            {translate('alternativeTimeslot')}
            {alternativeDate && alternativeTime && (
              <span className={styles.selectedDateTime}>
                { dateTimeCombination(
                    alternativeDate,
                    alternativeTime,
                    translate('Common:timeFormat'),
                    translate('Common:datetimeFormat')
                  )
                }
              </span>
            )}
          </Typography>
        </Breadcrumbs>
        {!isOnMobile && (
          <IconButton
            className={styles.closeBtn}
            onClick={() => {
              if (!selectedTimeslots) changeField('dateType', '');
              toggleDateTimeModalDispatch(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Grid>
      <Grid ref={(newRef) => setRef(newRef)} container className={styles.upperContent}>
        { selectingAlternativeTimeslot && <>
          <Grid container id="info_container" className={styles.alternativeText}>
            <img src={WhiteInfoIcon} className="mr_10" alt="blue_info_source" />
            <Typography variant="body1">{translate('pleaseSelect')}</Typography>
          </Grid>
          <DateTimeForm
            translate={translate}
            selectedDate={alternativeDate}
            selectedTime={alternativeTime}
            onDateChange={onChangeAlternativeDate}
            onTimeChange={changeAlternativeTime}
            disabledTimeSlots={disabledAlternativeTimeSlots}
          />
        </> }

        { !selectingAlternativeTimeslot &&
          <DateTimeForm
            translate={translate}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            onDateChange={onChangeSelectedDate}
            onTimeChange={changeSelectedTime}
            disabledTimeSlots={disabledTimeSlots}
          /> }
      </Grid>
      <Grid className={styles.lowerContent} container>
        <Grid className={styles.imageContainer} container item md={8} alignItems="center">
          <Grid item>
            <img src={InfoIcon} alt="info_source" />
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {selectingAlternativeTimeslot ? translate('alternativeNote') : translate('onceOffNote')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item md={4} justify="flex-end" alignItems="center">
          { selectingAlternativeTimeslot &&
            <Grid container justify="flex-end" wrap="nowrap">
              <Button
                className={`mr_10 shade_light_btn ${styles.btnConfirm}`}
                onClick={goingBackStepHandler}
                variant="contained"
              >
                {translate('back')}
              </Button>
              <Button
                disabled={!alternativeDate || !alternativeTime}
                className={`primary_yellow_btn ${styles.btnConfirm}`}
                onClick={bookingTimeslotsConfirmationHandler}
                variant="contained"
              >
                {translate('confirm')}
              </Button>
            </Grid>
          }

          { !selectingAlternativeTimeslot &&
            <Button
              disabled={!selectedDate || !selectedTime}
              className={`primary_yellow_btn ${styles.btnNext}`}
              onClick={goingNextStepHandler}
              variant="contained"
            >
              {translate('next')}
            </Button>
          }
        </Grid>
      </Grid>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeField: (field, value) => dispatch(change('BookingForm', field, value)),
  toggleDateTimeModalDispatch: (value) => dispatch(toggleDateTimeModal(value))
});
const bookingFormSelector = formValueSelector('BookingForm');
const mapStateToProps = (state) => {
  const isOpenModal = state.modal.openDateTimeModal;
  return {
    selectedTimeslots: bookingFormSelector(state, 'timeslots'),
    isOpenModal: isOpenModal || false
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnceOff);
