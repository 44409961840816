import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Container,
  Link,
  useMediaQuery
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles.module.scss';
import appIcon from 'assets/icons/appIcon.svg';
import appStoreSrc from 'assets/image/appStore.svg';
import googleStoreSrc from 'assets/image/googlePlay.svg';
import qrSrc from 'assets/image/qrCode.svg';

// component
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toggleGetAppDialog } from 'redux/modal';

const GetTheAppDialog = (props) => {
  const openGetTheAppDialog = useSelector(state => state.modal.openGetTheAppDialog);
  const { t: translate } = useTranslation('Common');
  const isOnMobile = useMediaQuery('(max-width:767px)', { noSsr: true });
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(toggleGetAppDialog(false));
  };

  return (
    <>
      <Dialog
        open={openGetTheAppDialog}
        classes={{ paper: styles.dialog }}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent className={styles.dialogContent}>
          <Grid container className={styles.dialogContainer}>
            <Grid item container md={5} className={styles.dialogRightSide}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <div className={styles.closeButtonWrap}>
                <IconButton aria-label="close" className={styles.closeButton} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className={styles.mobileTitle}>
                <Typography variant="h1">
                  {translate('getApp')}
                </Typography>
              </div>
              <Grid item className={styles.imageStyle}>
                <img src={appIcon} alt="mobile" />
              </Grid>
            </Grid>
            <Grid item container md={7} className={styles.dialogLeftSide}>

              <Grid item xs={12} className={styles.leftPageTitle}>
                <Typography variant="h1">
                  {translate('getApp')}
                </Typography>
              </Grid>
              <Grid item xs={12} className="mt_10">
                <Typography variant="body1" className={`${styles.textStyle} ${styles.downloadAppNow}`}>
                  {translate('downloadAppNow')}
                </Typography>
              </Grid>

              <Grid item container alignItems="center" spacing={2} className={styles.appRow}>
                <Grid item >
                  <Link href="https://itunes.apple.com/my/app/servishero-on-demand-services/id992334828?mt=8" target="_blank">
                    <img src={appStoreSrc} alt="app_store" />
                  </Link>
                </Grid>
                <Grid item >
                  <Link href="https://play.google.com/store/apps/details?id=com.servishero.consumer&amp;hl=en" target="_blank">
                    <img className={styles.google_img} src={googleStoreSrc} alt="google_store" />
                  </Link>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography className={`${styles.textStyle} ${styles.scanToDownload}`}>
                  {translate('scanToDownload')}
                </Typography>
              </Grid>
              <Grid item className={styles.qr_code}>
                <img src={qrSrc} alt="qr_code" />
              </Grid>

            </Grid>

          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GetTheAppDialog;
