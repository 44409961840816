import React, { useState } from 'react';
import styles from '../../style.module.scss';
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  useMediaQuery
} from '@material-ui/core';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import arrowUp from 'assets/icons/arrowUp.svg';
import arrowDown from 'assets/icons/arrowDown.svg';

const StepperComponent = ({ processNumber, translate }) => {
  const [openBar, setOpenBar] = useState(false);
  const isOnMobile = useMediaQuery('(max-width:992px)', { noSsr: true });
  const steps = [
    translate('describeYourPlace'),
    translate('bookingYourSession'),
    translate('additionalItems'),
    translate('dateAndTime'),
    translate('summary')
  ];
  const handleClick = () => {
    setOpenBar(!openBar);
  };
  return (
    <Grid container>
      <Grid className={`${openBar ? styles.circle : styles.smallCircle}`} item container>
        <CircularProgressbar
          value={processNumber}
          maxValue={steps.length}
          text={`${processNumber}/${steps.length}`}
          styles={{
            path: {
              stroke: processNumber !== steps.length ? '#2f80ed' : '#34C759'
            }
          }}
        />
      </Grid>
      <Grid className={styles.step} item container direction="column">
        {isOnMobile && (
          <Grid item className={styles.collapseContainer}>
            <Button onClick={handleClick} className={styles.btn_collapse}>
              {openBar ? translate('collapse') : translate('viewSteps')}
              <img src={openBar ? arrowDown : arrowUp} alt="arrowUp" />
            </Button>
            {!openBar && <Typography variant="subtitle1">{steps[processNumber - 1]}</Typography>}
          </Grid>
        )}

        <Stepper
          classes={{ root: styles.stepBar }}
          activeStep={processNumber}
          orientation="vertical"
          className={openBar ? '' : styles.displayNone}
        >
          {steps.map((label, index) => (
            <Step
              classes={{
                vertical: processNumber > index ? styles.vertical : styles.notAvailable
              }}
              key={label}
            >
              <StepLabel>
                <Typography variant="subtitle1">{label}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default StepperComponent;
