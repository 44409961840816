import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { change, reduxForm, formValueSelector, reset } from 'redux-form';
import { useQuery } from '@apollo/react-hooks';
import { Grid, Card, Typography, CardContent, CircularProgress } from '@material-ui/core';
import styles from './style.module.scss';
import { OnceOffModal, YearlyModal, WeeklyModal } from '../modal';
import { updateProcess } from 'redux/modal';
import DescribeComponent from './describe';
import FrequencyComponent from './frequency';
import SuggestionComponent from './suggestion';
import OptionalComponent from './optional';
import DateTimeComponent from './datetime';
import { OutOfServiceModal } from 'components';

const GET_SERVICE_DETAILS = gql`
  query($serviceId: ID!) {
    service(serviceId: $serviceId) {
      id
      name
      description
      questions {
        id
        text
        criteriaAttribute
        questionType
        answers {
          id
          text
          value
        }
      }
      optionalSalesItems {
        id
        name
        price {
          amountCents
          currencyCode
        }
        duration
        description
        status
      }
    }
  }
`;

const mapServiceTypeToModal = {
  weekly: WeeklyModal,
  yearly: YearlyModal,
  once_off: OnceOffModal
};

let BookingForm = ({
  changeField,
  directServiceId,
  selectedOptionalItems,
  selectedSalesItem,
  serviceType,
  isRecurringService,
  sessions,
  updateProcessDispatch,
  translate,
  resetForm,
  locality,
  timeslots,
  answers,
  dateType,
  setFetchApiError
}) => {
  if (!directServiceId) return null;
  const { loading, error, data } = useQuery(GET_SERVICE_DETAILS, {
    variables: { serviceId: directServiceId }
  });
  const [ref, setRef] = useState(null);
  // reset everything
  useEffect(() => {
    resetForm();
    updateProcessDispatch(0);
  }, []);

  // term disable auto scroll
  // useEffect(() => {
  //   // scroll into view
  //   if (ref) {
  //     ref.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [ref]);

  const renderModal = () => {
    const Modal = mapServiceTypeToModal[dateType];
    if (!Modal) return null;
    return <Modal translate={translate} />;
  };

  if (loading) {
    return (
      <div className="loading-content">
        <CircularProgress />
      </div>
    );
  }

  if (error || !data) {
    setFetchApiError(true);
    return null;
  }

  const { service } = data;

  return (
    <Grid>
      {renderModal()}

      <OutOfServiceModal />
      {/* About service */}
      <Card className={styles.card} style={{ marginTop: 0 }}>
        <CardContent style={{ minHeight: 'unset' }} className={styles.cardContent}>
          <Typography variant="h1" className={styles.title} gutterBottom>
            {translate('aboutService')}
          </Typography>
          <Typography className={`${styles.textDark} mt_8`} variant="subtitle1">
            {service.description}
          </Typography>
        </CardContent>
      </Card>

      {/* Select form */}
      <DescribeComponent
        translate={translate}
        service={service}
        changeField={changeField}
        resetForm={resetForm}
      />

      {/* Suggest form */}
      {answers && (
        <SuggestionComponent
          translate={translate}
          updateProcessDispatch={updateProcessDispatch}
          setRef={setRef}
          changeField={changeField}
          answers={answers}
          service={service}
          directServiceId={directServiceId}
          selectedSalesItem={selectedSalesItem}
          locality={locality}
          setFetchApiError={setFetchApiError}
        />
      )}

      {/* optional */}
      {answers && service && service.optionalSalesItems.length > 0 && (
        <OptionalComponent
          translate={translate}
          selectedOptionalItems={selectedOptionalItems}
          changeField={changeField}
          optionalSalesItems={service.optionalSalesItems}
        />
      )}

      {/* select date time */}
      {answers && selectedSalesItem && (
        <DateTimeComponent
          setRef={setRef}
          changeField={changeField}
          serviceType={serviceType}
          isRecurringService={isRecurringService}
          updateProcessDispatch={updateProcessDispatch}
          translate={translate}
          timeslots={timeslots}
          dateType={dateType}
        />
      )}

      {/* show recurring sessions */}
      {sessions && <FrequencyComponent setRef={setRef} translate={translate} sessions={sessions} />}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const bookingFormSelector = formValueSelector('BookingForm');
  const selectedService = state.service && state.service.selectedService;
  const directServiceId = (selectedService && selectedService.id) || null;
  const isRecurringService = (selectedService && selectedService.recurring) || false;
  const recurringType = (selectedService && selectedService.recurringType) || null;
  const locality =
    state.user &&
    state.user.bookingLocation &&
    state.user.bookingLocation.locality;
  const {
    selectedOptionalSalesItems,
    selectedSalesItem,
    frequency,
    sessions,
    timeslots,
    answers,
    dateType
  } = bookingFormSelector(
    state,
    'selectedOptionalSalesItems',
    'selectedSalesItem',
    'frequency',
    'sessions',
    'timeslots',
    'answers',
    'dateType'
  );
  return {
    initialValues: {
      directServiceId,
      selectedOptionalSalesItems: [],
      selectedSalesItem: null,
      frequency: ''
    },
    directServiceId,
    selectedOptionalItems: selectedOptionalSalesItems || [],
    selectedSalesItem: selectedSalesItem || null,
    frequency: frequency || '',
    sessions: sessions || '',
    serviceType: recurringType,
    isRecurringService,
    locality,
    timeslots: timeslots || '',
    answers: answers || '',
    dateType: dateType || ''
  };
};
const mapDispatchToProps = (dispatch) => ({
  changeField: (field, value) => dispatch(change('BookingForm', field, value)),
  updateProcessDispatch: (index) => dispatch(updateProcess(index)),
  resetForm: () => dispatch(reset('BookingForm'))
});
BookingForm = reduxForm({ form: 'BookingForm', destroyOnUnmount: false })(BookingForm);

export default connect(mapStateToProps, mapDispatchToProps)(BookingForm);
