export default {
  loginInWithEmail: 'you can log in with Email',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  email: 'Email',
  login: 'Log in',
  signUp: 'Sign up',
  forgotPwd: 'Forgot Password?',
  required: 'Required',
  invalidEmail: 'Invalid email address',
  didNotHaveAccountYet: "Don't have an account?",
  signUpWithEmail: 'Sign up with Email',
  continueWithFB: 'Continue with Facebook',
  continueWithApple: 'Continue with Apple',
  loginSuccess: 'Successful Login',
  authFailed: 'Authentication failed. Please try again.',
  plzCheckYourEmailForResetingPwd: 'Please check your email to reset your password!',
  createdAccountSuccessfully: 'Your account has been successfully created',
  alrdHaveAccount: 'Already have an account?',
  fullName: 'Full Name',
  phoneNumber: 'Phone Number',
  signUpAgreeing: 'By clicking on "Sign Up", you agree to the <0> Terms and Conditions</0>',
  resetPwdDescription: 'Enter the email address used to register with ServisHero to retrieve a temporary password',
  getNewPwd: 'Request a new password',
  viewProfile: 'View Profile',
  logOut: 'Log out',
  confirmPasswordNotMatch: 'Confirm password does not match with new password',
  resetYourPassword: 'Reset your password',
  informEmailSending: "We've emailed you a new password",
  informEmailSendingContent: "We've sent an email with a new password to <0>{{email}}</0>. Please check the Spam or Junk Mail folder if you didn't receive the email.",
  backToLogIn: 'Back to Log in',
  changePassword: 'Change Password',
  dontHaveCurrentPwd: 'If you forgot your password or logged in by Facebook/Apple, <0>click here</0> to get a new password.',
  currentPassword: 'Current Password',
  newPassword: 'New password',
  confirmNewPassword: 'Re-type new password',
  changedYourPasswordSuccessfully: 'Changed your password successfully',
  sendNewPasswordToEmail: "We've sent the new password to your email :{{email}}.",
  myProfile: 'My Profile',
  currentPasswordNotCorrect: 'Your current password was not correct',
}
