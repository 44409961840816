import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { Typography, Popover, Grid, Card, CardHeader, Divider } from '@material-ui/core';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import setIconSource from 'lib/setIconSource';
import DefaultServiceImage from 'assets/image/defaultServiceImage.svg';

const GET_DIRECT_CATEGORIES_API = gql`
  {
    directCategories {
      id
      name
      icon
      image
      disabled
      disabledImage
      disabledMessage
      minPrice {
        amountCents
        currencyCode
      }
      directServices {
        id
        name
        description
        image
        disabled
        disabledImage
        disabledMessage
        recurring
        recurringType
      }
    }
  }
`;

const ServiceSelector = (props) => {
  const { bookingLocation, selectServiceCallback, translate, ...restProps } = props;
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [displayServices, setDisplayServices] = useState([]);
  const [directCategories, setDirectCategories] = useState([]);
  const { refetch: fetchCategoryServices } = useQuery(GET_DIRECT_CATEGORIES_API, {
    fetchPolicy: 'no-cache',
    skip: true
  });

  useEffect(async () => {
    if (!bookingLocation) {
      setDirectCategories([]);
      return;
    }

    const { errors, data } = await fetchCategoryServices();
    if (!errors) {
      setDirectCategories(data.directCategories);
    }
  }, [bookingLocation]);

  const handleClickCategoryCard = (categoryObject) => (e) => {
    setSelectedCategory(categoryObject);
    if (Array.isArray(categoryObject.directServices)) {
      setDisplayServices(categoryObject.directServices);
    }
  };

  return (
    <Popover {...restProps} classes={{paper: styles.servicePopover}}>
      <Grid
        container
        direction="row"
        space={0}
        style={{
          width: 767,
          height: 444,
          padding: 0,
          GridShadow: '0px 1px 8px rgba(0, 0, 0, 0.15)',
          borderRadius: 12
        }}
      >
        <Grid container className={`${styles.wrapper} ${styles.categoriesWrapper}`}>
          <Grid item style={{ width: '100%', flex: '0 1 auto' }}>
            <Typography>{translate('category')}</Typography>
          </Grid>

          <div className={styles.restSession}>
            <Grid container style={{ maxHeight: '100%', width: '100%', overflowY: 'auto' }}>
              {directCategories &&
                Array.isArray(directCategories) &&
                directCategories.map((category) => {
                  return (
                    <Card
                      key={category.id}
                      className={`${styles.fullWidth} ${styles.card} ${
                        selectedCategory && selectedCategory.id === category.id
                          ? styles.selectedCard
                          : ''
                      }`}
                      onClick={handleClickCategoryCard(category)}
                    >
                      <CardHeader
                        style={{ padding: 8, borderRadius: 8 }}
                        avatar={
                          <img
                            src={category.image || setIconSource(category.name, true)}
                            style={{ height: 40, width: 40 }}
                          />
                        }
                        title={category.name}
                      />
                    </Card>
                  );
                })}
            </Grid>
          </div>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid style={{ flexGrow: 100 }}>
          {displayServices && displayServices.length > 0 && (
            <Grid
              container
              className={`${styles.wrapper} ${styles.servicesWrapper} ${styles.fullWidth}`}
            >
              <Grid item style={{ width: '100%', flex: '0 1 auto' }}>
                <Typography>{translate('service.selectService')}</Typography>
              </Grid>

              <div className={styles.restSession}>
                <Grid container className={styles.serviceRow} direction="column">
                  {displayServices.map((service) => (
                    <Grid
                      key={service.id}
                      container
                      item
                      direction="row"
                      style={{ width: '100%', padding: '12px 0px' }}
                      onClick={(e) => selectServiceCallback(service)}
                    >
                      <Grid item className={styles.serviceLogoWrapper}>
                        <img src={service.image || DefaultServiceImage} alt="mobile" />
                      </Grid>
                      <Grid
                        direction="column"
                        container
                        item
                        style={{
                          flex: 1,
                          paddingLeft: 12,
                          width: '100%'
                        }}
                      >
                        <Grid>
                          <Typography variant="h4" className={styles.title}>
                            {service.name}
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography variant="h4" className={styles.description}>
                            {service.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Popover>
  );
};

export default ServiceSelector;
