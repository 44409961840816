import React from 'react';
import moment from 'moment';
import 'moment/locale/th';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { SummaryComponent } from '../summary';
import { CircularProgress } from '@material-ui/core';

const ONCE_OFF_PREVIEW = gql`
  query(
    $serviceId: ID!
    $selectedSalesItem: SelectedSalesItem!
    $selectedOptionalSalesItems: [ID!]!
    $discountCode: String
  ) {
    onceOffOrderPreview(
      serviceId: $serviceId
      selectedSalesItem: $selectedSalesItem
      selectedOptionalSalesItems: $selectedOptionalSalesItems
      discountCode: $discountCode
    ) {
      subtotalAmount {
        amountCents
        currencyCode
      }
      totalAmount {
        amountCents
        currencyCode
      }
      code
      message
    }
  }
`;

const OnceOffComponent = ({
  selectedSalesItem,
  selectedOptionalItems,
  directServiceId,
  timeslots,
  dateType,
  discountCode,
  setFetchApiError
}) => {
  const { loading, error, data } = useQuery(ONCE_OFF_PREVIEW, {
    variables: {
      serviceId: directServiceId,
      selectedOptionalSalesItems: selectedOptionalItems,
      selectedSalesItem,
      discountCode: discountCode || ''
    }
  });
  if (loading) return <div className='loading-content'><CircularProgress /></div>;

  if (error || !data) {
    setFetchApiError(true);
    return null;
  }
  
  const {
    onceOffOrderPreview: { totalAmount }
  } = data;

  return (
    <SummaryComponent
      selectedSalesItem={selectedSalesItem}
      totalAmount={totalAmount}
      timeslots={moment(timeslots[0]).toISOString()}
      alternative={timeslots[1] ? moment(timeslots[1]).toISOString() : null}
      dateType={dateType}
    />
  );
};

export default OnceOffComponent;
