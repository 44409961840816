import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  SvgIcon
} from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import payNow from 'assets/icons/pay_now.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload_icon.svg';
import payNowQrCode from 'assets/image/pay_now_qr_code.jpeg';
import styles from './styles.module.scss';
import ProofPaymentDetails from './proof_payment_details';

const TransferViaPayNowSG = (props) => {
  const [openProofPaymentDetails, setOpenProofPaymentDetails] = useState(false);
  const { className, expanded, handleChangePaymentMethod,
          proofPaymentFile, setProofPaymentFile, isDisablePaymentMethod, item } = props;

  const handleChangeProofPaymentFile = async (files) => {
    const fileUpload = files[0];
    setProofPaymentFile(fileUpload);
  };

  const handleOpeningProofPaymentDetails = () => {
    setOpenProofPaymentDetails(true);
  }

  const handleClosingProofPaymentDetails = () => {
    setOpenProofPaymentDetails(false);
  }

  return (
    <Accordion
      disabled={isDisablePaymentMethod}
      className={className}
      expanded={expanded}
      classes={{root: styles.bankTransferPayNow}}
      onChange={() => handleChangePaymentMethod('bank_transfer')}
    >
      <AccordionSummary classes={{ content: styles.paymentMethodContent}} aria-controls="panel1a-content" id="panel1a-header">
        <Box className={styles.paymentMethodIconAndText}>
          <img src={payNow} className={styles.paymentMethodIcon} alt="pay now" />
          <Typography>Transfer funds via PayNow</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item>
            <Typography>Online Banking or ATM deposit and send proof of payment here.</Typography>
          </Grid>
          <Grid item container spacing={2} className={styles.bankTransferRow}>
            <Grid item>
              <img className={styles.qr_code} src={item.imageUrl || payNowQrCode} alt="qr_code" />
            </Grid>
            <Grid item container direction="column">
              <Typography variant="h2">Scan to pay</Typography>
              <Typography variant="h4" className="pt_5 pb_10">
                UEN: 201525344W
              </Typography>
              <Typography className={styles.supportedBanks}>
                Supported Banks: Bank of China , Citibank , DBS/POSB , HSBC Bank , Industrial and
                Commercial Bank of China Limited , Maybank , OCBC Bank , Standard Chartered Bank &
                United Overseas Bank Limited
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <TextField
              id='avata_proof_payment'
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => handleChangeProofPaymentFile(e.target.files)}
              InputProps={{
                inputProps:{
                  'accept': 'image/*'
                }
              }}
            />

            { !proofPaymentFile &&
              <label htmlFor='avata_proof_payment' className={styles.proofUploaderZone}>
                <Grid container className={styles.proofUploaderZoneContent}>
                  <Grid item container direction="column">
                    <Typography variant="h3">Upload Proof of Payment</Typography>
                    <Typography variant="h4" className="pt_5 pb_10">Screenshot in PNG or JPG format</Typography>
                  </Grid>
                  <Grid item>
                    <SvgIcon component={UploadIcon} />
                  </Grid>
                </Grid>
              </label>
            }

            { proofPaymentFile &&
              <>
                <Grid container className={styles.successProofUploadedContent} >
                  <Grid item container wrap="nowrap" spacing={1} alignItems="center">
                    <Grid item>
                      <CheckIcon className={styles.leafColor}/>
                    </Grid>
                    <Grid item container direction="column">
                      <Typography variant="h4" className={styles.leafColor}>File uploaded successfully</Typography>
                      <Typography variant="subtitle2" className="pt_5" style={{cursor: 'pointer'}} onClick={handleOpeningProofPaymentDetails}>
                        Click to review</Typography>
                    </Grid>
                  </Grid>
                  <label htmlFor='avata_proof_payment' style={{width:150, cursor: 'pointer'}}>
                    <Grid container justify='flex-end'>
                      <AutorenewIcon />
                      <Typography variant="h3">Replace</Typography>
                    </Grid>
                  </label>
                </Grid>

                {/* Show proof preview after chosen file */}
                { openProofPaymentDetails && <ProofPaymentDetails
                  openProofPaymentDetails={openProofPaymentDetails}
                  handleClosingProofPaymentDetails={handleClosingProofPaymentDetails}
                  proofPaymentFile={proofPaymentFile}
                />}
              </>
            }
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
export default TransferViaPayNowSG;
