const UPDATE_PROCESS = 'UPDATE_PROCESS';
const SET_MODAL_VOUCHER = 'SET_MODAL_VOUCHER';
const SET_VOUCHER = 'SET_VOUCHER';
const SET_OUT_SERVICE_MODAL = 'SET_OUT_SERVICE_MODAL';
const TOGGLE_GET_THE_APP_DIALOG = 'TOGGLE_GET_THE_APP_DIALOG';
const TOGGLE_CONTACT_SUPPORT_DIALOG = 'TOGGLE_CONTACT_SUPPORT_DIALOG';
const TOGGLE_DATE_TIME_MODAL = 'TOGGLE_DATE_TIME_MODAL';
const TOGGLE_ERROR_PAGE = 'TOGGLE_ERROR_PAGE';
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

// Actions

const initialState = {
  processBooking: 0,
  openModalVoucher: false,
  voucherCode: '',
  openOutServiceModal: false,
  openContactSupportDialog: false,
  openGetTheAppDialog: false,
  openDateTimeModal: false,
  errorPage: false,
  currentPage: null
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MODAL_VOUCHER:
      return {
        ...state,
        openModalVoucher: action.modalState
      };

    case SET_VOUCHER:
      return {
        ...state,
        voucherCode: action.code
      };

    case UPDATE_PROCESS:
      return {
        ...state,
        processBooking: action.processBooking
      };

    case SET_OUT_SERVICE_MODAL:
      return {
        ...state,
        openOutServiceModal: action.status
      };

    case TOGGLE_GET_THE_APP_DIALOG:
      return {
        ...state,
        openGetTheAppDialog: action.status
      };

    case TOGGLE_CONTACT_SUPPORT_DIALOG:
      return {
        ...state,
        openContactSupportDialog: action.status
      };

    case TOGGLE_DATE_TIME_MODAL:
      return {
        ...state,
        openDateTimeModal: action.status
      };

    case TOGGLE_ERROR_PAGE:
      return {
        ...state,
        errorPage: action.status
      };

    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.page
      };

    default:
      return state;
  }
}

// Action Creators

export function updateProcess(index) {
  return { type: UPDATE_PROCESS, processBooking: index };
}

export function setVoucher(code) {
  return { type: SET_VOUCHER, code };
}

export function openModalVoucher(status) {
  return { type: SET_MODAL_VOUCHER, modalState: status };
}

export function openOutServiceModal(status) {
  return { type: SET_OUT_SERVICE_MODAL, status: status };
}

export function toggleGetAppDialog(status) {
  return { type: TOGGLE_GET_THE_APP_DIALOG, status: status };
}

export function toggleContactSupportDialog(status) {
  return { type: TOGGLE_CONTACT_SUPPORT_DIALOG, status: status };
}

export function toggleDateTimeModal(status) {
  return { type: TOGGLE_DATE_TIME_MODAL, status: status };
}

export function toggleErrorPage(status) {
  return { type: TOGGLE_ERROR_PAGE, status: status };
}

export function setCurrentPage(page) {
  return { type: SET_CURRENT_PAGE, page: page}
}