import React, { useState } from 'react';
import {
  Card,
  CardMedia,
  CardActionArea,
  Typography,
  CircularProgress,
  Grid
} from '@material-ui/core';
import styles from './styles.module.scss';
import DefaultServiceImage from 'assets/image/defaultServiceImage.svg';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import NewsModal from '../news_modal';

const NewsAndUpdates = (props) => {
  const GET_ADVERTISEMENTS = gql`
    {
      advertisements {
        coverMessage
        header
        image
        message
        promocode
        bookable
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_ADVERTISEMENTS);
  const [openNewsModal, setOpenNewsModal] = useState(false);
  const [newsContent, setNewsContent] = useState(false);
  if (loading)
    return (
      <div className="loading-content">
        <CircularProgress />
      </div>
    );

  const handleClickCard = (item) => {
    setNewsContent(item);
    setOpenNewsModal(true);
  };

  return (
    <>
      <NewsModal
        newsContent={newsContent}
        openNewsModal={openNewsModal}
        setOpenNewsModal={setOpenNewsModal}
      />
      {data && data.advertisements.length > 0 && (
        <div className={styles.listServices}>
          {data.advertisements.map((adsItem, index) => (
            <Grid key={index} className={styles.serviceItemWrapper}>
              <Card onClick={() => handleClickCard(adsItem)} className={styles.serviceItem}>
                <CardActionArea className={styles.cardContent}>
                  <Grid className={styles.blurBackground}></Grid>
                  <CardMedia
                    component="img"
                    alt={adsItem.name}
                    image={adsItem.image || DefaultServiceImage}
                    title={adsItem.name}
                    className={styles.cardImage}
                  />
                  <div className={styles.serviceName}>
                    <div className={styles.cardTitle}>
                      <Typography gutterBottom component="h4">
                        <b>{adsItem.header}</b>
                      </Typography>
                    </div>
                    <div className={styles.cardExcerpt}>
                      <Typography gutterBottom component="h3">
                        {adsItem.coverMessage}
                      </Typography>
                    </div>
                  </div>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </div>
      )}
    </>
  );
};

export default NewsAndUpdates;
