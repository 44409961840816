import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { Popover, Grid, List, ListItem, ListItemText,
  Collapse, IconButton, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore} from '@material-ui/icons';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import CloseIcon from '@material-ui/icons/Close';
import DefaultServiceImage from 'assets/image/defaultServiceImage.svg';


const GET_DIRECT_CATEGORIES_API = gql`
  {
    directCategories {
      id
      name
      icon
      image
      disabled
      disabledImage
      disabledMessage
      minPrice {
        amountCents
        currencyCode
      }
      directServices {
        id
        name
        description
        image
        disabled
        disabledImage
        disabledMessage
        recurring
        recurringType
      }
    }
  }
`;

const ServiceSelectorMobile = (props) => {
  const { bookingLocation, selectServiceCallback, translate, handleCloseServicePopover, anchorEl,
    anchorOrigin,
    transformOrigin,
    open, } = props;
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [directCategories, setDirectCategories] = useState([]);
  const { refetch: fetchCategoryServices } = useQuery(GET_DIRECT_CATEGORIES_API, {
    fetchPolicy: 'no-cache',
    skip: true
  });

  useEffect(async () => {
    if (!bookingLocation) {
      setDirectCategories([]);
      return;
    }

    const { errors, data } = await fetchCategoryServices();
    if (!errors) {
      setDirectCategories(data.directCategories);
    }
  }, [bookingLocation]);

  const handleClickCategoryCard = (categoryObject) => (e) => {
    if (selectedCategory && selectedCategory.id === categoryObject.id) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categoryObject);
    }
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      classes={{paper: styles.servicePopover}}>

      <Grid container direction="column" className={styles.locationPopoverContent }>
        <Grid
          item container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <div className={styles.closeButtonWrap}>
            <IconButton
              aria-label="close"
              className={styles.closeButton}
              onClick={handleCloseServicePopover}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid item className="pl_15">
          <Typography variant="h3">{translate('category')}</Typography>
        </Grid>
        <Grid item>
          <List>
            { directCategories &&
              Array.isArray(directCategories) &&
              directCategories.map((category, index) => {
                return (
                  <React.Fragment key={index}>
                    <ListItem button onClick={handleClickCategoryCard(category)} className="pl_15 dark_text">
                      <ListItemText primary={category.name} />
                      {selectedCategory && selectedCategory.id === category.id ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse in={selectedCategory && selectedCategory.id === category.id} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {Array.isArray(category.directServices) && category.directServices.map((service) => (
                        <ListItem button
                          classes={{root: styles.listItemRoot}}
                          key={service.id} onClick={e => selectServiceCallback(service)}>
                          <div className={styles.serviceLogoWrapper}>
                            <img src={service.image || DefaultServiceImage} alt="mobile" />
                          </div>
                          <ListItemText
                            classes={{root: styles.listItemTextRoot}}
                            primary={service.name}
                            secondary={
                              <React.Fragment>
                                {service.description}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                  </React.Fragment>
                )
              })
            }
          </List>
        </Grid>
      </Grid>
    </Popover>
  );
};


export default ServiceSelectorMobile;
