import React from 'react';
import styles from '../payment.module.scss';
import { connect } from 'react-redux';
import { Grid, Typography, Box, Divider, Button, CircularProgress } from '@material-ui/core';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import clsx from 'clsx';
import setIconSource from 'lib/setIconSource';
import dateIcon from 'assets/icons/DateTime.svg';
import locationIcon from 'assets/icons/location.svg';
import voucherIcon from 'assets/icons/Voucher.svg';
import moment from 'moment';
import 'moment/locale/th';
import { formatPrice } from 'lib/currencyFormatter';
import voucherBackground from 'assets/image/promoBackground.svg';
import { openModalVoucher, setVoucher } from 'redux/modal';
import { setCardRequirement } from 'redux/payment';

const GET_ORDER_DETAILS = gql`
  query($orderId: ID!, $discountCode: String) {
    order(orderId: $orderId, discountCode: $discountCode) {
      id
      discountData
      publicId
      additionalNotes
      job {
        id
        publicId
        timeslot1
        timeslot2
        isRecurringJob
      }
      address {
        id
        address
      }
      totalPrice {
        amountCents
        currencyCode
      }
      totalDiscountedPrice {
        amountCents
        currencyCode
      }
      totalDuration
      discountData
      salesItems {
        name
        price {
          amountCents
          currencyCode
        }
        duration
      }
      directService {
        id
        name
      }
      discountAmount {
        amountCents
        currencyCode
      }
      payableAmount {
        amountCents
        currencyCode
      }
      creditCardValidation
    }
  }
`;

const OnceOffSummary = (props) => {
  const {
    setOrder,
    openModalVoucherDispatch,
    orderId,
    voucherCode,
    setVoucherDispatch,
    translate,
    setCardRequirementDispatch,
    setFetchApiError
  } = props;
  if (!orderId) return null;
  const { loading, error, data } = useQuery(GET_ORDER_DETAILS, {
    variables: {
      orderId,
      discountCode: voucherCode
    }
  });

  if (loading)
    return (
      <div className="loading-content">
        <CircularProgress />
      </div>
    );

  if (error) {
    setFetchApiError(true);
    return null;
  }

  const { order } = data;
  if (order) {
    setOrder && setOrder(order);
  }

  return (
    <Grid container item className={styles.summaryContent}>
      <Grid container item className={styles.cardContainer} wrap="nowrap" alignItems="center">
        <Box mr={2}>
          <Grid item>
            <img src={setIconSource('Cleaning')} alt="image_source" />
          </Grid>
        </Box>
        <Grid item>
          <Typography variant="h3" className={styles.title}>
            {order.directService.name}
          </Typography>
          <Typography>{order.salesItems && order.salesItems[0].name}</Typography>
        </Grid>
      </Grid>

      <Divider />

      <Grid
        container
        className={styles.cardContainer}
        alignItems="center"
        justify="center"
        direction="column"
      >
        <Grid item container wrap="nowrap">
          <Box mr={2}>
            <img src={dateIcon} alt="icon_date" />
          </Box>
          <Grid item container>
            <Typography className={styles.textGray}>
              {moment(order.job.timeslot1).format(translate('Common:datetimeFormat'))}
            </Typography>
            <Grid item container>
              <Typography className={`${styles.textGray} mb_15`} variant="body2">{`${translate(
                'Booking:alternative'
              )}: ${moment(order.job.timeslot2).format(
                translate('Common:datetimeFormat')
              )}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container wrap="nowrap">
          <Box mr={2}>
            <img src={locationIcon} alt="icon_location" />
          </Box>
          <Typography className={styles.textGray}>{order.address.address}</Typography>
        </Grid>
      </Grid>

      <Divider />

      {voucherCode ? (
        <>
          <Grid container className={styles.cardContainer}>
            <Grid item container alignItems="center">
              <img src={voucherIcon} alt="voucher_image" />
              <Box ml={2} mr={2}>
                <Typography className={styles.address}>{voucherCode}</Typography>
              </Box>
              <Button
                className={clsx('shade_light_btn', styles.btnRemove)}
                variant="contained"
                color="default"
                onClick={() => {
                  setVoucherDispatch(null);
                  setCardRequirementDispatch(null);
                }}
              >
                {translate('remove')}
              </Button>
            </Grid>
          </Grid>
          <Divider />
        </>
      ) : (
        <Grid container className={styles.backgroundContainer}>
          <Grid
            item
            container
            direction="column"
            style={{ backgroundImage: `url(${voucherBackground})` }}
            className={styles.applyNow}
          >
            <Typography variant="subtitle1">{translate('promo')}</Typography>
            <Grid item>
              <Button
                className={clsx('shade_light_btn', styles.btnApply)}
                onClick={() => openModalVoucherDispatch(true)}
              >
                {translate('applyNow')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        className={styles.cardContainer}
        wrap="nowrap"
        alignItems="center"
        justify="center"
        direction="column"
      >
        <Grid item container justify="space-between" wrap="nowrap" alignItems="center">
          <Typography variant="h3">{translate('total')}</Typography>
          {formatPrice(order.totalPrice.amountCents, order.totalPrice.currencyCode) !==
            formatPrice(
              order.totalDiscountedPrice.amountCents,
              order.totalDiscountedPrice.currencyCode
            ) && (
            <Typography variant="h4" className={styles.subTotal}>
              {formatPrice(order.totalPrice.amountCents, order.totalPrice.currencyCode)}
            </Typography>
          )}
        </Grid>
        <Grid item container justify="space-between" wrap="nowrap" alignItems="center">
          <Typography variant="body2" className={styles.description}>
            {translate('description')}
          </Typography>
          <Typography variant="h2" className={styles.textNoWrap}>
            {formatPrice(
              order.totalDiscountedPrice.amountCents,
              order.totalDiscountedPrice.currencyCode
            )}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    orderId: state.payment && state.payment.currentOrderId,
    voucherCode: (state.modal && state.modal.voucherCode) || null
  };
};

const mapDispatchToProps = (dispatch) => ({
  openModalVoucherDispatch: (status) => dispatch(openModalVoucher(status)),
  setVoucherDispatch: (index) => dispatch(setVoucher(index)),
  setCardRequirementDispatch: (value) => dispatch(setCardRequirement(value))
});
export default connect(mapStateToProps, mapDispatchToProps)(OnceOffSummary);
