import { POST } from "lib/api";
import ExternalAPI from "constants/ExternalAPI";
import { showErrorMessage } from "lib/notifier";

// Actions
const SET_CURRENT_ORDER_ID = "SET_CURRENT_ORDER_ID";
const SET_CARD_VALIDATION = "SET_CARD_VALIDATION";
const SET_PROMO_CODE = "SET_PROMO_CODE";

const initialState = {
  currentOrderId: "",
  cardNumberValidate: "",
  currentDateType: "",
  promoCode: "",
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_ORDER_ID:
      return {
        ...state,
        currentOrderId: action.order.id,
        currentDateType: action.order.dateType,
      };

    case SET_CARD_VALIDATION:
      return {
        ...state,
        cardNumberValidate: action.requirement,
      };

    case SET_PROMO_CODE:
      return {
        ...state,
        promoCode: action.code,
      };

    default:
      return state;
  }
}

// Action Creators

export function setCurrentOrderId(id, dateType) {
  return { type: SET_CURRENT_ORDER_ID, order: { id, dateType } };
}

export function setCardRequirement(requirement) {
  return { type: SET_CARD_VALIDATION, requirement };
}

// side effects, only as applicable
// e.g. thunks, epics, etc

export function digitalPayment(
  orderId,
  paymentMethod,
  redirectUrl,
  discountCode,
  countryCode
) {
  const data = {
    orderId,
    paymentMethod,
    redirectUrl,
    discountCode,
  };
  return (dispatch) => {
    return POST(ExternalAPI(countryCode, "ADYEN_GET_LINK"), null, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        showErrorMessage("Request fail");
        return Promise.reject(err);
      });
  };
}

export function integrate2c2pPayment(orderId, discountCode, encryptedCardInfo) {
  const data = {
    order_id: orderId,
    discount_code: discountCode,
    encryptedCardInfo,
    description: "Hello",
  };
  return (dispatch) => {
    return POST(ExternalAPI("th", "PAYMENT_2C2P"), null, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function uploadProofPayment(formData, country) {
  return (dispatch) => {
    return POST(
      ExternalAPI(country, "ATTACHMENT_PROOF_PAYMENT_ENDPOINT"),
      null,
      formData
    )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        showErrorMessage("Upload fail");
        return Promise.reject(err);
      });
  };
}

export function setPromoCode(code) {
  return { type: SET_PROMO_CODE, code };
}
