export default {
  language: {
    countryNLanguage: 'ประเทศและภาษา',
    language: 'ภาษา'
  },
  jobs: 'งานของฉัน',
  bookingService: 'จองบริการ',
  category: 'บริการทั้งหมด',
  jobsHistory: 'งานเก่า',
  location: {
    placeholder: 'ค้นหาสถานที่',
    label: 'สถานที่',
    searchPlaceholder: 'รายละเอียดสถานที่',
    unitNumber: 'บ้านเลขที่',
    additionalNoteTitle: 'แนะนำการเดินทาง จุดเด่น หรือสถานที่ใกล้เคียง',
    additionalNote: 'ข้อมูลอื่นๆเพิ่มเติม',
    confirmYourAddress: 'ยืนยันสถานที่',
    chooseLocationFirst: 'โปรดเลือกสถานที่เพื่อจองบริการ',
  },
  service: {
    label: 'บริการ',
    placeholder: 'เลือกบริการที่คุณต้องการ',
    category: 'หมวดหมู่บริการ',
    selectService: 'เลือกบริการที่ต้องการ',
  },
}