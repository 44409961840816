import React, { useState } from 'react';
// Material components
import { Button, Grid, IconButton } from '@material-ui/core';
import styles from './upload_profile.module.scss';
import UploadIcon from 'assets/icons/upload_icon.svg';
import { showErrorMessage } from 'lib/notifier';
const ALLOW_FILE_TYPES = ['jpg', 'jpeg', 'png', 'svg'];
import CancelIcon from '@material-ui/icons/Cancel';

const validateFileType = (file = {}) => {
  const { name, type } = file;
  const fileExtArray = name.split('.');
  const fileExt = fileExtArray[fileExtArray.length - 1];
  const error = type || fileExt;
  return ALLOW_FILE_TYPES.includes(fileExt) ? null : error;
};

const FilesUploadInput = ({ onSubmit, handleRemoveImage, uploadedPicture }) => {
  const [files] = useState(uploadedPicture || []);
  const error = [];
  const handleSubmitFile = (uploadFiles) => {
    const filesLength = uploadFiles.length;
    if (filesLength <= 0) return null;

    const validFiles = [];
    for (let i = 0; i < filesLength; i += 1) {
      const isErrorFormat = validateFileType(uploadFiles[i]);
      isErrorFormat ? error.push(isErrorFormat) : validFiles.push(uploadFiles[i]);
    }
    if (error.length > 0) {
      showErrorMessage(`Unsupported format: ${error.join()}`);
    }
    onSubmit(validFiles);
  };

  const handleRemove = (index) => {
    handleRemoveImage(index);
  };
  return (
    <Grid container direction="column">
      <label className={styles.btnContainer} htmlFor="upload-photo">
        <input
          style={{ display: 'none' }}
          id="upload-photo"
          name="upload-photo"
          type="file"
          onChange={(e) => handleSubmitFile(e.target.files)}
          multiple
        />
        <Button
          className={`primary_yellow_btn ${styles.uploadBtn}`}
          color="primary"
          variant="contained"
          component="span"
        >
          Upload
          <img className={styles.uploadIcon} src={UploadIcon} alt="upload_icon" />
        </Button>
      </label>
      <Grid container className="mt_10">
        {files.length > 0 &&
          files.map((file, index) => (
            <ImageThumb handleRemove={handleRemove} index={index} key={index} image={file} />
          ))}
      </Grid>
    </Grid>
  );
};

const ImageThumb = ({ image, index, handleRemove }) => {
  return (
    <Grid item className={styles.imageConfig}>
      <img className={styles.img} src={image} alt={image.name}></img>
      <IconButton onClick={() => handleRemove(index)} className={styles.buttonClose}>
        <CancelIcon />
      </IconButton>
    </Grid>
  );
};

export default FilesUploadInput;
