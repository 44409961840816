import React from 'react';
import {
  Button,
  DialogContent,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';

import styles from './../../styles.module.scss';
import mailBoxSrc from 'assets/image/mailBox.svg';
import { Trans } from 'react-i18next';

const AfterSendingResetPwdEmailInstruction = props => {
  const { handleShowingLoginForm, translate, email } = props;

  return <DialogContent>
    <Grid
      className={styles.form_container}
      container
      alignItems="center"
      justify="center"
      direction="column"
    >
      <Grid container justify="center">
        <img src={mailBoxSrc} alt="mail_box" />
      </Grid>
      <Box mt={2} mb={3}>
        <Grid container justify="center">
          <Typography className={styles.title} variant="h4">
            {translate('informEmailSending')}
          </Typography>
        </Grid>
      </Box>
      <Grid container justify="center">
        <Typography className={styles.content}>
        <Trans i18nKey="Auth:informEmailSendingContent" >
          <strong>{{email: email}}</strong>
        </Trans>
        </Typography>
      </Grid>
      <Box mt={3}>
        <Grid container justify="center">
          <Button
            onClick={() => {
              handleShowingLoginForm(true);
            }}
            className={`primary_yellow_btn ${styles.backToLoginBtn}`}
            variant="contained"
          >
            {translate('backToLogIn')}
          </Button>
        </Grid>
      </Box>
    </Grid>
  </DialogContent>
}

export default AfterSendingResetPwdEmailInstruction;
