
const { store } = require('services/store');

export function rootPath(rootUrl) {
  rootUrl = rootUrl || window.location.origin;
  const { preferredLanguage, geoLocation: { countryCode }} = store.getState().user;
  return [rootUrl, countryCode, preferredLanguage].join('/');
};

export function buildFullPath(page, rootUrl='') {
  page = page && page.trim() || undefined;
  if (!page) return rootPath();

  if (page.charAt(0) === '/') {
    return [rootPath(rootUrl), page].join('');
  }
  return [rootPath(rootUrl), page].join('/');
};
