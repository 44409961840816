import axios from "axios";
const COUNTRIES_AND_LANGUAGES = [
  {
    country: {
      name: "Malaysia",
      code: "my",
    },
    languages: [
      {
        name: "EN",
        code: "en",
        redirectUrl: "/my/en",
        default: true,
      },
    ],
  },
  {
    country: {
      name: "Singapore",
      code: "sg",
    },
    languages: [
      {
        name: "EN",
        code: "en",
        redirectUrl: "/sg/en",
        default: true,
      },
    ],
  },
  {
    country: {
      name: "Thailand",
      code: "th",
    },
    languages: [
      {
        name: "EN",
        code: "en",
        redirectUrl: "/th/en",
        default: false,
      },
      {
        name: "ไทย",
        code: "th",
        redirectUrl: "/th/th",
        default: true,
      },
    ],
  },
];
const KUALA_LUMPUR = [
  "Petaling Jaya",
  "Subang Jaya",
  "Puchong",
  "Shah Alam",
  "Klang",
  "Rawang",
  "Seri Kembangan",
  "Sungai Buloh",
  "Ampang Jaya",
  "Ampang",
  "Cheras",
  "Batu Caves",
  "Kuala Lumpur",
  "Wilayah Persekutuan Kuala Lumpur",
  "Wilayah Persekutuan",
  "Balakong",
  "Semenyih",
  // "Bandar Puncak Alam",
  // "Beranang",
  "Telok Panglima Garang",
  "Pulau Indah",
  "Selangor",
  "Bandar Baru Bangi",
  "Kajang",
  "Putrajaya",
];
const IPOH = ["Ipoh"];
const MELAKA = [
  "Alor Gajah",
  "Asahan",
  "Ayer Keroh",
  "Bemban",
  "Durian Tunggal",
  "Jasin",
  "Kem Trendak",
  "Kuala Sungai Baru",
  "Lubok China",
  "Masjid Tanah",
  "Melaka",
  "Melaka",
  "Merlimau",
  "Selandar",
  "Sungai Rambai",
  "Sungai Udang",
  "Tanjong Kling",
];
const JOHOR_BAHRU = [
  "Gelang Patah",
  "Iskandar",
  "Johor Bahru",
  "Nusajaya",
  "Pasir Gudang",
  "Skudai",
  "Kulai",
  "Ulu Tiram",
];
const PENANG = [
  "George Town",
  "Air Itam",
  "Jelutong",
  "Gelugor",
  "Bayan Lepas",
  "Balik Pulau",
  "Tanjung Bungah",
  "Batu Ferringhi",
  "Simpang Ampat",
  "Penang",
  "Pulau Pinang",
];
const SINGAPORE = ["Singapore"];
const BANGKOK = [
  "Samut Prakan",
  "สมุทรปราการ",
  "Pathum Thani",
  "Chang Wat Pathum Thani",
  "Nonthaburi",
  "นนทบุรี",
  "Chang Wat Nonthaburi",
  "Samut Sakhon",
  "Chang Wat Samut Sakhon",
  "Nakhon Pathom",
  "นครปฐม",
  "Chang Wat Nakhon Pathom",
  "Bangkok",
  "Krung Thep Maha Nakhon",
  "Thailand",
  "Thai",
  "TH",
  "ประเทศไทย",
  "ไทย",
];

export function detectLocality(
  country,
  locality_long_name,
  locality_short_name
) {
  if ((!locality_long_name && !locality_short_name) || !country) return null;
  locality_long_name = locality_long_name.trim();
  locality_short_name = locality_short_name.trim();
  switch (country.toLowerCase()) {
    case "my":
      if (
        KUALA_LUMPUR.includes(locality_long_name) ||
        KUALA_LUMPUR.includes(locality_short_name)
      )
        return "Kuala Lumpur";
      if (
        JOHOR_BAHRU.includes(locality_long_name) ||
        JOHOR_BAHRU.includes(locality_short_name)
      )
        return "Johor Bahru";
      if (
        PENANG.includes(locality_long_name) ||
        PENANG.includes(locality_short_name)
      )
        return "Penang";
      if (
        IPOH.includes(locality_long_name) ||
        IPOH.includes(locality_short_name)
      )
        return "Ipoh";
      if (
        MELAKA.includes(locality_long_name) ||
        MELAKA.includes(locality_short_name)
      )
        return "Melaka";
      return locality_long_name;
    case "sg":
      if (
        SINGAPORE.includes(locality_long_name) ||
        SINGAPORE.includes(locality_short_name)
      )
        return "Singapore";
      return locality_long_name;
    case "th":
      if (
        BANGKOK.includes(locality_long_name) ||
        BANGKOK.includes(locality_short_name)
      )
        return "Bangkok";
      return locality_long_name;
    default:
      return null;
  }
}

export function getCountryByIpAddress() {
  return axios.get("https://iplist.cc/api");
}

export function isSupportedCountry(code) {
  if (!code) return false;

  code = code.toLowerCase();
  return ["my", "th", "sg"].includes(code);
}

export function getDefaultLanguage(countryCode) {
  if (!countryCode) return "en";

  const result = COUNTRIES_AND_LANGUAGES.find(
    (item) => item.country.code === countryCode.toLowerCase()
  );
  if (!result) return "en";

  const defaultLanguage = result.languages.find(
    (language) => language.default === true
  );
  return defaultLanguage.code;
}

export function supportedCountries() {
  return COUNTRIES_AND_LANGUAGES;
}

export function isSupportedLanguage(countryCode, languageCode) {
  if (!countryCode || !languageCode) return false;

  const result = COUNTRIES_AND_LANGUAGES.find(
    (item) => item.country.code === countryCode.toLowerCase()
  );
  if (!result) return false;

  const resultLanguage = result.languages.find(
    (language) => language.code === languageCode
  );
  return !!resultLanguage;
}

export async function getCurrentLocationByCoordinate(lat, lng, language) {
  const { error, data } = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_WEB_GG_MAP_API_KEY}&language=${language}`
  );
  if (error) {
    return Promise.reject(error);
  }
  const firstResult = data.results[0];
  if (!firstResult) {
    return Promise.resolve({});
  }

  const countryItem = firstResult.address_components.find((item) =>
    item.types.includes("country")
  );
  const countryName = countryItem ? countryItem.long_name : null;
  const countryCode = countryItem ? countryItem.short_name.toLowerCase() : null;
  const localityItem = firstResult.address_components.find(
    (item) =>
      item.types.includes("locality") ||
      item.types.includes("administrative_area_level_1")
  );
  const locality = localityItem
    ? detectLocality(
        countryCode,
        localityItem.long_name,
        localityItem.short_name
      )
    : null;

  const location = {
    address: firstResult.formatted_address,
    placeId: firstResult.place_id,
    countryCode: countryCode,
    countryName: countryName,
    locality: locality,
    lat: firstResult.geometry.location.lat,
    lng: firstResult.geometry.location.lng,
    unitNumber: null,
    remarks: null,
  };
  return Promise.resolve(location);
}
