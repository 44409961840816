import React, { useContext } from 'react';
import {
  Grid,
} from '@material-ui/core';
import { showSuccessMessage } from 'lib/notifier';
import { AppContext } from 'routes';

import SignUpForm from '../forms/signUpForm';
import { useDispatch, useSelector } from 'react-redux';
import { signUpMethod, handleOpeningAuthDialog } from 'redux/auth';
import { withRouter } from 'react-router-dom';
import { buildFullPath } from 'lib/helper';

const SignUpHandler = ( props ) => {
  const { translate, history } = props;
  const dispatch = useDispatch();
  const isOnBookingForm = useSelector(state => state.service.isOnBookingForm);
  const countryCode = useSelector(state => state.user.geoLocation.countryCode);
  const preferredLanguage = useSelector(state => state.user.preferredLanguage);
  const contextProps = useContext(AppContext);

  const submitSignUp = async (values) => {
    const { email, fullName, password, phoneNumber } = values;

    await dispatch(signUpMethod(fullName, email, password, phoneNumber, countryCode, preferredLanguage, contextProps.searchParams ));
    showSuccessMessage(translate('createdAccountSuccessfully'))
    dispatch(handleOpeningAuthDialog(false));
    if (!isOnBookingForm) {
      // Go to welcome if consumer is not in booking page
      location.replace(buildFullPath('welcome'));
    }
  };

  return (
    <Grid container>
      <SignUpForm onSubmit={submitSignUp} translate={translate} />
    </Grid>
  );
};

export default withRouter(SignUpHandler);
