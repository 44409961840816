import React, { useState, useContext } from 'react';
import {
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Link,
  SvgIcon,
  Divider,
  Typography,
  Menu
} from '@material-ui/core';
import { AppContext } from 'routes';

import { ReactComponent as ServisHeroLogo } from 'assets/logos/sh-logo.svg';
import { ReactComponent as AddBookingIcon } from 'assets/icons/add-booking.svg';
import { ReactComponent as SelectedAddBookingIcon } from 'assets/icons/selected_plus.svg';
import { ReactComponent as SelectedJobsIcon } from 'assets/icons/selected_jobs.svg';

import { ReactComponent as JobsIcon } from 'assets/icons/jobs.svg';
import { ReactComponent as NotiIcon } from 'assets/icons/noti.svg';
import { ReactComponent as UserIcon } from 'assets/icons/circleUserIcon.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menuIcon.svg';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles.module.scss';
import setIconSource from 'lib/setIconSource';
import { useTranslation } from 'react-i18next';
import { supportedCountries } from 'lib/locationAndLanguage';
import { useDispatch, useSelector } from 'react-redux';
import { handleOpeningAuthDialog } from 'redux/auth';
import { toggleContactSupportDialog, toggleGetAppDialog } from 'redux/modal';
import { rootPath, buildFullPath } from 'lib/helper';
import { withRouter } from 'react-router-dom';

import helpIcon from 'assets/icons/Help.svg';
import mobileIcon from 'assets/icons/mobile.svg';
import infoIcon from 'assets/icons/Info.svg';

import ecoworldLogo from 'assets/logos/partner_logo/sh_x_ecoworld.svg';
import livingOsLogo from 'assets/logos/partner_logo/sh_x_livingos.svg';
import aigLogo from 'assets/logos/partner_logo/ShXAiXEa_m.svg';
import senhengLogo from 'assets/logos/partner_logo/Senheng_M.svg'
import loginIcon from 'assets/image/user.svg';

const partners = {
  'a1351ce8-0227-4dbf-87d4-6b089d384202': ecoworldLogo,
  'ae478e20-5361-416d-90a9-7d35b5f639d4': livingOsLogo,
  '1eaa6113-4e32-4190-bc33-603001611db6': aigLogo,
  '79cd1ea3-c21c-475b-b752-53bf9df1b155': senhengLogo
};

const MenuDropdown = (props) => {
  const {
    translate,
    handleOpenMenuDropdown,
    anchorElForMenuDropdown,
    handleCloseMenuDropdown,
    handleShowingContactSupport,
    handleShowingGetApp,
    setAnchorElForMenuDropdown,
    countryCode,
    preferredLanguage,
    history
  } = props;
  const selectedPage = useSelector((state) => state.modal.currentPage);

  const countries = supportedCountries();

  const handleClose = () => {
    setAnchorElForMenuDropdown(null);
  };

  const pushToPage = (name) => {
    history.push({
      pathname: `/${countryCode}/${preferredLanguage}/${name}`
    });
  };

  return (
    <>
      <IconButton aria-label="menu" className="ml_10" onClick={handleOpenMenuDropdown}>
        <SvgIcon component={MenuIcon} />
      </IconButton>

      <Menu
        anchorEl={anchorElForMenuDropdown}
        keepMounted
        open={Boolean(anchorElForMenuDropdown)}
        onClose={handleCloseMenuDropdown}
        getContentAnchorEl={null}
        classes={{ paper: styles.menuDialog }}
        marginThreshold={0}
      >
        <Grid container direction="column" className={styles.userMenuPopover}>
          <IconButton aria-label="close" className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Grid item container justify="center">
            <IconButton href={rootPath()} aria-label="menu">
              <SvgIcon viewBox="0 0 31 31" color="primary" component={ServisHeroLogo} />
            </IconButton>
          </Grid>

          <Divider className="mt_10 mb_10" />

          <Grid
            item
            container
            className={styles.rowStyle}
            direction="column"
            alignItems="flex-start"
          >
            <Button
              className={`${selectedPage === 'booking' ? styles.selectedSection : null} mr_10 ${
                styles.quickSelectBtn
              }`}
              onClick={() => pushToPage('')}
              startIcon={
                <SvgIcon
                  component={selectedPage === 'booking' ? SelectedAddBookingIcon : AddBookingIcon}
                />
              }
            >
              {translate('bookingService')}
            </Button>
            {/* <Button
              className={`${selectedPage === 'jobs' ? styles.selectedSection : null} ${
                styles.quickSelectBtn
              }`}
              onClick={() => pushToPage('jobs')}
              startIcon={
                <SvgIcon component={selectedPage === 'jobs' ? SelectedJobsIcon : JobsIcon} />
              }
            >
              {translate('jobsHistory')}
            </Button> */}
          </Grid>

          <Divider className="mt_10 mb_10" />

          <Grid
            item
            container
            className={styles.rowStyle}
            onClick={(e) => handleShowingContactSupport(e)}
          >
            <Grid item>
              <img src={helpIcon} alt="mobile" />
            </Grid>
            <Grid item className="pl_15">
              <Typography variant="subtitle1">{translate('Common:contactForSupport')}</Typography>
            </Grid>
          </Grid>

          <Link
            href={buildFullPath('terms', 'https://servishero.com')}
            target="_blank"
            className={styles.linkText}
          >
            <Grid item container className={styles.rowStyle}>
              <Grid item>
                <img src={infoIcon} alt="mobile" />
              </Grid>
              <Grid item className="pl_15">
                <Typography variant="body1">{translate('Common:termsOfService')}</Typography>
              </Grid>
            </Grid>
          </Link>

          <Grid item container className={styles.rowStyle} onClick={(e) => handleShowingGetApp(e)}>
            <Grid item>
              <img src={mobileIcon} alt="mobile" />
            </Grid>
            <Grid item className="pl_15">
              <Typography variant="subtitle1">{translate('Common:getApp')}</Typography>
            </Grid>
          </Grid>

          <Divider className="mt_10 mb_10" />

          <Grid className={styles.rowStyle}>
            <Typography style={{ fontWeight: 700 }}>
              {translate('language.language')}{' '}
            </Typography>
          </Grid>

          <Grid className={styles.rowStyle}>
            {countries.map((item, cindex) => {
              const itemCountryCode = item.country.code;
              return (
                <Grid container key={cindex} direction="column">
                  <Grid
                    container
                    key={cindex}
                    justify="space-between"
                    alignItems="center"
                    style={{ width: '100%' }}
                  >
                    <Grid container item justify="flex-start" alignItems="flex-start" xs={6}>
                      <Grid item className="mr_10" style={{ width: 24, height: 24 }}>
                        <img
                          src={setIconSource(itemCountryCode.toUpperCase() + 'FlagIcon')}
                          alt={itemCountryCode}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{ fontWeight: countryCode === itemCountryCode ? 700 : 500 }}
                        >
                          {item.country.name}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={6}>
                      {item.languages.map((language, lindex) => {
                        const languageCode = language.code;
                        const isCurrentPreferrefLanguage =
                          countryCode === itemCountryCode && languageCode === preferredLanguage;
                        return (
                          <Button
                            key={lindex}
                            className={`${styles.countryButton}
                        ${isCurrentPreferrefLanguage ? styles.selectedCountryButton : null}`}
                            href={isCurrentPreferrefLanguage ? null : language.redirectUrl}
                            disabled={isCurrentPreferrefLanguage}
                          >
                            {language.name}
                          </Button>
                        );
                      })}
                    </Grid>
                  </Grid>
                  {cindex < countries.length - 1 && <Divider className="mt_10 mb_10"></Divider>}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};

const AppHeaderMobile = ({ history }) => {
  const { t: translate } = useTranslation('Header');
  const dispatch = useDispatch();
  const countryCode = useSelector((state) => state.user.geoLocation.countryCode);
  const preferredLanguage = useSelector((state) => state.user.preferredLanguage);
  const currentUser = useSelector((state) => state.user.currentUser);
  const contextProps = useContext(AppContext);
  const currentPartner = contextProps.searchParams.partnerId;
  const [anchorElForMenuDropdown, setAnchorElForMenuDropdown] = useState(null);
  const topBarRef = React.createRef();

  const handleOpenMenuDropdown = (e) => {
    setAnchorElForMenuDropdown(topBarRef.current);
  };

  const handleCloseMenuDropdown = () => {
    setAnchorElForMenuDropdown(null);
  };

  const handleOpenLoginForm = () => {
    dispatch(handleOpeningAuthDialog(true));
  };

  const handleShowingContactSupport = (e) => {
    e.preventDefault();
    dispatch(toggleContactSupportDialog(true));
  };

  const handleShowingGetApp = (e) => {
    e.preventDefault();
    dispatch(toggleGetAppDialog(true));
  };

  return (
    <>
      <AppBar position="static" color="inherit" ref={topBarRef} className={styles.appBar}>
        <Toolbar className="p_0">
          <Grid container alignItems="center">
            <Grid item container xs={4} justify="flex-start">
              {/* Menu dropdown */}
              <MenuDropdown
                translate={translate}
                handleOpenMenuDropdown={handleOpenMenuDropdown}
                anchorElForMenuDropdown={anchorElForMenuDropdown}
                handleCloseMenuDropdown={handleCloseMenuDropdown}
                handleShowingContactSupport={handleShowingContactSupport}
                handleShowingGetApp={handleShowingGetApp}
                countryCode={countryCode}
                preferredLanguage={preferredLanguage}
                setAnchorElForMenuDropdown={setAnchorElForMenuDropdown}
                history={history}
              />
              {/* End Menu dropdown */}
            </Grid>
            <Grid className={styles.containerImage} item container xs={5} justify="center">
              {!!currentPartner && !!partners[currentPartner] ? (
                <IconButton
                  href={rootPath()}
                  edge="start"
                  aria-label="menu"
                  className={`${styles.partnerLogoButton}`}
                >
                  <img
                    src={partners[currentPartner]}
                    alt="partner_logo"
                    className={styles.partnerLogo}
                  />
                </IconButton>
              ) : (
                <IconButton href={rootPath()} edge="start" aria-label="menu">
                  <SvgIcon viewBox="0 0 31 31" color="primary" component={ServisHeroLogo} />
                </IconButton>
              )}
            </Grid>
            <Grid className={styles.loginContainer} item container xs={3} justify="flex-end">
              {/* User dropdown */}
              {currentUser && (
                <>
                  {/* Have not supported notification yet. */}
                  {/* <IconButton aria-label="menu">
                    <SvgIcon component={NotiIcon} />
                  </IconButton> */}
                  <Button className={`${styles.myProfileNav}`} href={buildFullPath('profile')}>
                    <SvgIcon
                      viewBox="0 0 32 32"
                      component={UserIcon}
                      className={`mr_10 ${styles.userIcon}`}
                    />
                  </Button>
                </>
              )}

              {/* End User dropdown */}
              {!currentUser && (
                <Link
                  onClick={handleOpenLoginForm}
                  className={`mr_20 ${styles.linkText}`}
                  component="button"
                >
                  {/* {translate('Auth:login')}/{translate('Auth:signUp')} */}
                  <img src={loginIcon} alt="login_icon" />
                </Link>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default withRouter(AppHeaderMobile);
