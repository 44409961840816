import React from 'react';
import { Grid, Radio, Typography, FormControlLabel } from '@material-ui/core';
import styles from './style.module.scss';

const SelectInput = ({ title, subtitle, price, value, translate }) => {
  return (
    <FormControlLabel
      value={value}
      className={styles.answerContainer}
      control={
        <Grid
          container
          className={styles.increaseButton}
          alignItems="center"
          justify="space-between"
        >
          <Grid container item xs={11}>
            <Grid xs={8} item>
              {title && (
                <Typography className={styles.increaseItem} variant="body1">
                  {title}
                </Typography>
              )}
              {subtitle && <Typography variant="body2">{subtitle}</Typography>}
            </Grid>
            <Grid item container xs={4} direction="column" wrap="nowrap" alignItems="flex-end">
              <Typography className={styles.price}>{price}</Typography>
              <Typography variant="body2">/ {translate('session')}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Radio classes={{ checked: styles.checked }} value={value} />
          </Grid>
        </Grid>
      }
    />
  );
};

export default SelectInput;
