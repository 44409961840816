// const RAILS_CORE_DOMAIN_PROD_MY = 'https://servishero-core-my.herokuapp.com';
// const RAILS_CORE_DOMAIN_PROD_SG = 'https://servishero-core-sg.herokuapp.com';
// const RAILS_CORE_DOMAIN_PROD_TH = 'https://servishero-core-th.herokuapp.com';
// const AUTH_DOMAIN = 'https://servishero-auth.herokuapp.com';

const BACK_END_DOMAIN_MY = process.env.REACT_APP_BACK_END_DOMAIN_MY;
const BACK_END_DOMAIN_SG = process.env.REACT_APP_BACK_END_DOMAIN_SG;
const BACK_END_DOMAIN_TH = process.env.REACT_APP_BACK_END_DOMAIN_TH;
const AUTHENTICATION_DOMAIN = process.env.REACT_APP_AUTHENTICATION_DOMAIN;

export let PUSH_NOTIFICATION_ENDPOINT;
export let AUTH_ENDPOINT;
export let CURRENT_USER_ENDPOINT;
export let GRAPHQL_ENDPOINT_MY;
export let GRAPHQL_ENDPOINT_SG;
export let GRAPHQL_ENDPOINT_TH;
export let GRAPHQL_ENDPOINT;
export let SIGN_UP_ENDPOINT;
export let FORGOT_PASSWORD_ENDPOINT;
export let CHANGE_PASSWORD_ENDPOINT;
export let FB_AUTH_ENDPOINT;
export let ATTACHMENT_UPLOAD_ENDPOINT;
export let ATTACHMENT_PROOF_PAYMENT_ENDPOINT;
export let TNC_LINK;
export let ADYEN_CALLBACK_ENDPOINT;

TNC_LINK = "https://servishero.com/my/en/terms";

const PRODUCTION_API = {
  GLOBAL: {
    PUSH_NOTIFICATION_ENDPOINT: `${AUTHENTICATION_DOMAIN}/push_tokens`,
    AUTH_ENDPOINT: `${AUTHENTICATION_DOMAIN}/login`,
    CURRENT_USER_ENDPOINT: `${AUTHENTICATION_DOMAIN}/current_user`,
    SIGN_UP_ENDPOINT: `${AUTHENTICATION_DOMAIN}/signup`,
    FORGOT_PASSWORD_ENDPOINT: `${AUTHENTICATION_DOMAIN}/forgot`,
    CHANGE_PASSWORD_ENDPOINT: `${AUTHENTICATION_DOMAIN}/change`,
    UPLOAD_AVATAR_ENDPOINT: `${AUTHENTICATION_DOMAIN}/avatar`,
    FB_AUTH_ENDPOINT: `${AUTHENTICATION_DOMAIN}/facebook_token`,
    APPLE_AUTH_ENDPOINT: `${AUTHENTICATION_DOMAIN}/apple_token`,
  },
  my: {
    GRAPHQL_ENDPOINT: `${BACK_END_DOMAIN_MY}/graphql`,
    ATTACHMENT_UPLOAD_ENDPOINT: `${BACK_END_DOMAIN_MY}/api/v1/upload_attachment`,
    ATTACHMENT_PROOF_PAYMENT_ENDPOINT: `${BACK_END_DOMAIN_MY}/api/v1/payments/upload_proof_of_payment`,
    ADYEN_CALLBACK_ENDPOINT: `${BACK_END_DOMAIN_MY}/public/payments/new`,
    ADYEN_GET_LINK: `${BACK_END_DOMAIN_MY}/api/v1/paymentlink`,
  },
  sg: {
    GRAPHQL_ENDPOINT: `${BACK_END_DOMAIN_SG}/graphql`,
    ATTACHMENT_UPLOAD_ENDPOINT: `${BACK_END_DOMAIN_SG}/api/v1/upload_attachment`,
    ATTACHMENT_PROOF_PAYMENT_ENDPOINT: `${BACK_END_DOMAIN_SG}/api/v1/payments/upload_proof_of_payment`,
    ADYEN_CALLBACK_ENDPOINT: `${BACK_END_DOMAIN_SG}/public/payments/new`,
    ADYEN_GET_LINK: `${BACK_END_DOMAIN_SG}/api/v1/paymentlink`,
  },
  th: {
    GRAPHQL_ENDPOINT: `${BACK_END_DOMAIN_TH}/graphql`,
    ATTACHMENT_UPLOAD_ENDPOINT: `${BACK_END_DOMAIN_TH}/api/v1/upload_attachment`,
    ATTACHMENT_PROOF_PAYMENT_ENDPOINT: `${BACK_END_DOMAIN_TH}/api/v1/payments/upload_proof_of_payment`,
    ADYEN_CALLBACK_ENDPOINT: `${BACK_END_DOMAIN_TH}/public/payments/new`,
    ADYEN_GET_LINK: `${BACK_END_DOMAIN_TH}/api/v1/paymentlink`,
    PAYMENT_2C2P: `${BACK_END_DOMAIN_TH}/api/v1/payment_2c2p/submit_payment`,
  },
};

export default (countryIdentifier, endpoint) => {
  return PRODUCTION_API[countryIdentifier][endpoint];
};
