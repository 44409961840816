import { openSnackbar } from '../services/Notifier';

export const notify = (options) => {
  openSnackbar(options);
};

// error can be hash object or string
export const showErrorMessage = (error, autoHideDuration) => {

  const message = (error.data || {}).message ||
    ((error.response || {}).data || {}).message ||
    error.message || error;

  notify({ message, variant: 'error', autoHideDuration });
};

// error can be hash object or string
export const showLocationErrorMessage = (error, autoHideDuration) => {

  const message = (error.data || {}).message ||
    ((error.response || {}).data || {}).message ||
    error.message || error;

  notify({ message, variant: 'location', autoHideDuration });
};

// message is string
export const showSuccessMessage = (message, autoHideDuration) => {
  notify({ message, variant: 'success', autoHideDuration });
};

// message is string
export const showInfoMessage = (message, autoHideDuration) => {
  notify({ message, variant: 'info', autoHideDuration });
};

// message is string
export const showWarningMessage = (message, autoHideDuration) => {
  notify({ message, variant: 'warning', autoHideDuration });
};


export const showSubscriptionMarmingMessage = ({ message, autoHideDuration, closedCallback }) => {
  notify({ message, variant: 'info', autoHideDuration, closedCallback });
};