import React, { useContext } from 'react';
import { Container, Grid, Button } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/th';
import styles from './styles.module.scss';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserProfile } from 'redux/auth';
import { showSuccessMessage, showErrorMessage } from 'lib/notifier';
import { AppContext } from 'routes';

import { ProfileForm } from 'components';

const UpdateProfileHandler = (props) => {
  const { translate, backToProfilePage, userProfile = {}, updatingProfileCallback } = props;
  const countryCode = useSelector((state) => state.user.geoLocation.countryCode);
  const dispatch = useDispatch();
  const contextProps = useContext(AppContext);

  const submitProfile = async (values) => {
    const data = {
      user: {
        id: values.id,
        name: values.name,
        email: values.email,
        dob: (values.dateOfBirth && moment(values.dateOfBirth).format('YYYY-MM-DD')) || null,
        gender: values.gender || null,
        phone_number: values.phoneNumber,
        country_code: countryCode
      }
    };
    const res = await dispatch(updateUserProfile(data, contextProps.searchParams)).catch((e) => ({ e }));
    if (res.e) return showErrorMessage(res.e.data.errors[0], 10000);
    showSuccessMessage(translate('changedYourProfileSuccessfully'));
    updatingProfileCallback(res.data);
  };
  return (
    <Grid xs={12} md={6} lg={5} item className={styles.profileContent}>
      <Grid container className={`mobile_title_size ${styles.formTitle}`} alignItems="center">
        <Button onClick={() => backToProfilePage()} className="back_btn">
          <ArrowBackIosIcon />
        </Button>
        <h1>{translate('editProfile')}</h1>
      </Grid>
      <Grid className={styles.formContent}>
        <ProfileForm onSubmit={submitProfile} translate={translate} />
      </Grid>
    </Grid>
  );
};

export default UpdateProfileHandler;
