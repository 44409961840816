import React from "react";
import TagManager from "react-gtm-module";

const getTagId = (countryCode) => {
  const tagID = {
    my: "GTM-MQXD2JX",
    sg: "GTM-WHWVDTX",
    th: "GTM-585F2JG",
  }

  if (!!countryCode && Object.keys(tagID).find(country => country === countryCode.toLowerCase())) {
    return tagID[`${countryCode.toLowerCase()}`];
  } else {
    return tagID["my"];
  }
}

export const initGTag = (countryCode) => {
  const tagManagerArgs = {
    gtmId: getTagId(countryCode),
  };

  TagManager.initialize(tagManagerArgs);
}
