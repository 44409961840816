import React from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import styles from './style.module.scss';

const IncreaseInput = ({ value, handleChangeUnits }) => {
  const handleIncrement = () => {
    handleChangeUnits && handleChangeUnits(value + 1);
  };

  const handleDecrement = () => {
    handleChangeUnits && handleChangeUnits(value - 1);
  };

  return (
    <Grid container className={styles.increaseButton} alignItems="center" justify="space-between" wrap='nowrap'>
      <Button
        classes={{ label: styles.plusIcon }}
        className={styles.button}
        style={!value ? { opacity: 0.1 } : null}
        disabled={!value}
        onClick={handleDecrement}
      >
        -
      </Button>

      <Typography className={styles.increaseItem} variant="subtitle2">
        {value}
      </Typography>
      <Button
        classes={{ label: styles.plusIcon }}
        className={styles.button}
        onClick={handleIncrement}
      >
        +
      </Button>
    </Grid>
  );
};

export default IncreaseInput;
