import React from 'react';
import styles from './style.module.scss';
import {
  Typography,
  CardContent,
  Card,
  RadioGroup,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { SelectInput } from 'components';
import { useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/client';
import { formatPrice } from 'lib/currencyFormatter';
import { openOutServiceModal } from 'redux/modal';
import { useDispatch } from 'react-redux';

const GET_SUGGESTION_ITEM = gql`
  query($request: Request!) {
    suggestions(request: $request) {
      baseVariationId
      id
      name
      price {
        amountCents
        currencyCode
      }
      recommended
      duration
      totalArea
      heroEarnings {
        amountCents
        currencyCode
      }
    }
  }
`;

const SuggestionComponent = ({
  answers,
  directServiceId,
  changeField,
  setRef,
  updateProcessDispatch,
  translate,
  selectedSalesItem,
  locality,
  setFetchApiError
}) => {
  const { loading, error, data } = useQuery(GET_SUGGESTION_ITEM, {
    variables: {
      request: {
        serviceId: directServiceId,
        locality: locality || '',
        answers
      }
    }
  });
  if (loading)
    return (
      <div className="loading-content">
        <CircularProgress />
      </div>
    );

  if (error || !data) {
    setFetchApiError(true);
    return null;
  }
  const { suggestions } = data;

  // show out of service modal
  if (!suggestions || suggestions.length <= 0) {
    const dispatch = useDispatch();
    dispatch(openOutServiceModal(true));
    return null;
  }

  const handleSelectSession = (value) => {
    const { baseVariationId, duration, heroEarnings, name, price } = value;
    changeField('selectedSalesItem', {
      baseVariationId,
      name,
      price,
      heroEarnings,
      duration
    });
    updateProcessDispatch(3);
  };

  const renderSuggestion = (suggestions) => {
    const recommendSession = suggestions.find((item) => item.recommended);
    const remainSessions = suggestions.slice(recommendSession ? 1: 0, suggestions.length);
    return (
      <RadioGroup
        className={styles.radioGroup}
        value={selectedSalesItem && selectedSalesItem.baseVariationId}
      >
        {!!recommendSession && (
          <SelectInput
            onClick={() => handleSelectSession(recommendSession)}
            key={recommendSession.baseVariationId}
            value={recommendSession.baseVariationId}
            price={formatPrice(
              recommendSession.price && recommendSession.price.amountCents,
              recommendSession.price && recommendSession.price.currencyCode
            )}
            subtitle={recommendSession.name}
            selectStyle={styles.w_80}
            translate={translate}
          />
        )}
        {remainSessions.length > 0 && (
          <Typography className={styles.suggestionSubtitle} variant="subtitle1">
            {translate('otherSessionType')}
          </Typography>
        )}

        {remainSessions.map((item, index) => (
          <SelectInput
            key={item.baseVariationId}
            onClick={() => handleSelectSession(item)}
            value={item.baseVariationId}
            price={formatPrice(item.price.amountCents, item.price.currencyCode)}
            subtitle={item.name}
            selectStyle={styles.w_80}
            translate={translate}
          />
        ))}
      </RadioGroup>
    );
  };

  return (
    <Card ref={(newRef) => setRef(newRef)} className={styles.card}>
      <CardContent className={styles.cardContent}>
        <Typography variant="h1" className={`${styles.title} mb_25`}>
          {translate('bookingYourSession')}
        </Typography>
        <Grid className="mt_5">
          <Typography variant="subtitle1" className={styles.title}>
            {translate('pickYourSessionType')}
          </Typography>
          <Typography variant="subtitle1" className={`${styles.textDark} mb_25`}>
            {translate('pickYourSessionTypeExplain')}
          </Typography>
        </Grid>
        {suggestions.length > 0 && renderSuggestion(suggestions)}
      </CardContent>
    </Card>
  );
};

export default SuggestionComponent;
