export default {
  aboutService: 'About this Service',
  frequencySchedule: 'Frequency Schedule',
  describeYourPlace: 'Describe Your Request',
  askingPlaceExplain: 'We will suggest service options based on your answers',
  otherSessionType: 'Other options',
  bookingYourSession: 'Select Your Session',
  pickYourSessionType: 'Pick a suggested option',
  pickYourSessionTypeExplain: 'Based on the information provided, we have some session options available for you',
  dateAndTime: 'Date & Time',
  additionalItems: 'Additional Items',
  additionalItemsExtraItems: 'Additional services and items your session - additional time might be added to your session if you select these. You can find more information in About This Service.',
  total: 'Total',
  includedTaxes: 'Amount include Taxes if applicable',
  onceOffNote: 'Please select your preferred date & time of service (2 timeslots). This calendar does not reflect our availability. We will contact you after the job is matched.',
  confirm: 'Confirm',
  selectTime: 'Select Time',
  morningSlot: 'Morning Slot',
  afternoonSlot: 'Afternoon Slot',
  dateTime: 'Date & Time',
  regular: 'How regularly would you like this service?',
  justOnce: 'Just Once',
  selectDateTime: 'Select Date & Time',
  repeat: 'I want it repeated',
  repeatDescription: 'Select a plan and save more',
  selectSchedule: 'Select schedule',
  selectDuration: 'Select a Plan Duration',
  willOccur: 'Service will occur on ...',
  firstService: 'First service will occur on',
  didYouKnow: 'Did you know?',
  recommend: 'More sessions you subscribed, less cost per session you get.',
  next: 'Next',
  back: 'Back',
  confirm: 'Confirm',
  congratulations: 'Congratulations!',
  saved: 'You saved <bold>{{price}}</bold> by selecting this Plan',
  normal: '(Normal price: {{price}} /session)',
  custom: 'Cutomise your subscription',
  need: 'I need my service',
  selectOcc: 'select frequency',
  times: '{{time}} times',
  perYear: 'per year.',
  at: 'at',
  yearly: 'Yearly recurring',
  weekly: 'Weekly recurring',
  checkout: 'Continue to Checkout',
  attachment: 'Photos will give Heroes better understanding of your needs',
  alternativeNote: "By providing an additional preferred timeslot, you'll increase the speed of your Job being claimed by one of our Heroes.",
  pleaseSelect: 'Please provide an additional alternative preferred timeslot',
  totalAmount: 'Total Amount',
  checkoutText: 'Check out',
  timeslot: 'First Preferred Timeslot',
  alternativeTimeslot: 'Alternative Preferred Timeslot',
  inputBrandName: 'Input brand name',
  alternative: 'Alternative',
  summary: 'Summary',
  saveAmount: 'Save {{price}}',
  youWillPay: 'You’ll pay',
  session: 'session',
  collapse: 'Collapse',
  viewSteps: 'View Steps',
  totalSessions: 'Total {{numberSession}} Sessions',
};
