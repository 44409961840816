import React, { useEffect, useState } from 'react';
import {
  Avatar, Grid, Button, Link, Badge, TextField
} from '@material-ui/core';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { getUserProfile } from 'redux/auth';
import { signOut, setCurrentUser } from 'redux/user';
import { useDispatch, useSelector } from 'react-redux';
import { ChangePasswordHandler, UpdateProfileHandler } from './components';
import { showErrorMessage } from 'lib/notifier';
import moment from 'moment';
import 'moment/locale/th';
import { uploadAvatar } from 'redux/service';
import { reset } from 'redux-form';
import { Redirect } from 'react-router-dom';

const ALLOW_FILE_TYPES = ['jpg', 'jpeg', 'png', 'svg'];

const validateFileType = (file = {}) => {
  const { name, type } = file;
  const fileExtArray = name.split('.');
  const fileExt = fileExtArray[fileExtArray.length - 1];
  const error = type || fileExt;
  return ALLOW_FILE_TYPES.includes(fileExt) ? null : error;
};

const MyProfilePage = (_props) => {
  const { t: translate } = useTranslation('Profile');
  const [userProfile, setUserProfile] = useState(null);
  const [isProfileDetails, setIsProfileDetails] = useState(true);
  const [isChangingPwd, setIsChangingPwd] = useState(false);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const currentUser = useSelector(state => state.user.currentUser);
  const currentCountry = useSelector(state => state.user.geoLocation.countryCode);
  const preferredLanguage = useSelector(state => state.user.preferredLanguage);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchAccountDetails() {
      if (!currentUser) return;

      await dispatch(reset('profileForm'));
      try {
        const res = await dispatch(getUserProfile());
        if (res.status === 200) {
          setUserProfile(res.data);
          dispatch(setCurrentUser(res.data));
        }
      } catch (error) {
        showErrorMessage(error);
      }
    }
    fetchAccountDetails();
  },[]);

  function showChangingPassword(event) {
    event.preventDefault();
    setIsProfileDetails(false);
    setIsUpdatingProfile(false);
    setIsChangingPwd(true);
  }

  async function logOutHandler() {
    await dispatch(signOut());
  }

  function showEditProfile() {
    setIsChangingPwd(false);
    setIsProfileDetails(false);
    setIsUpdatingProfile(true);
  }

  function backToProfilePage() {
    setIsChangingPwd(false);
    setIsUpdatingProfile(false);
    setIsProfileDetails(true);
  }

  function updatingProfileCallback(newProfile) {
    backToProfilePage();
    setUserProfile(newProfile);
    dispatch(setCurrentUser(newProfile));
  }

  const handleSubmitFile = async (uploadFiles) => {
    if (uploadFiles.length <= 0) return null;

    const fileUpload = uploadFiles[0];
    const isErrorFormat = validateFileType(fileUpload);
    if (isErrorFormat) {
      showErrorMessage(`Unsupported format: ${isErrorFormat}`);
    } else {
      const formData = new FormData();
      formData.append('photo', fileUpload);
      const res = await dispatch(uploadAvatar(formData));
      setUserProfile(res.data);
    }
  };

  if (!currentUser) return <Redirect to={`/${currentCountry}/${preferredLanguage}`} />;

  return (
    <div className={styles.profilePageRoot}>
      <Grid container className={`height_100 ${styles.overflow_y}`} direction="row" justify="center" alignItems="flex-start">
        { userProfile && isProfileDetails &&
          <Grid xs={12} md={6} lg={5} item className={styles.profileContent}>
            <Grid container item xs={12} md={12} lg={12} direction='column' justify="center" alignItems="center" className={styles.coverImg}>
              <div>
                <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={
                    <div className={ styles.cameraAltOutlinedIconWrapper }>
                      <TextField
                        id='avata-uploader'
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => handleSubmitFile(e.target.files)}
                        InputProps={{
                          inputProps:{
                            'accept': 'image/*'
                          }
                        }}
                      />
                      <label htmlFor='avata-uploader'>
                        <CameraAltOutlinedIcon fontSize="small" />
                      </label>
                    </div>
                  }
                >
                  { userProfile.image_url && <Avatar src={userProfile.image_url} className={styles.userAvatar} /> }
                  { !userProfile.image_url && <Avatar className={styles.userAvatar}>{userProfile.name.match(/\b(\w)/g).join('')}</Avatar> }
                </Badge>
              </div>
              <div className={styles.userName}>
                <h1>{userProfile.name}</h1>
              </div>
            </Grid>

            <Grid container className={styles.details}>
              <Grid item xs={12} md={12} lg={12} className={styles.rowDetails}>
                <h2 className={styles.personalDetailsLabel}><b>{translate('personalDetails')}</b></h2>
              </Grid>
              <Grid container item xs={12} md={12} lg={12} direction="row" justify="flex-start" alignItems="center" className={styles.rowDetails}>
                <Grid xs={5} md={5} lg={5} item>
                  {translate('gender')}
                </Grid>
                <Grid xs={7} md={7} lg={7} item>
                  <span className={styles.gender}>{userProfile.gender ? translate(userProfile.gender) : ''}</span>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={12} lg={12}  direction="row" justify="flex-start" alignItems="center"className={styles.rowDetails}>
                <Grid xs={5} md={5} lg={5} item>
                  {translate('dateOfBirth')}
                </Grid>
                <Grid xs={7} md={7} lg={7} item>
                  {userProfile.dob ? moment(userProfile.dob).format(translate('Common:dateFormat2')) : ''}
                </Grid>
              </Grid>
              <Grid container item xs={12} md={12} lg={12}  direction="row" justify="flex-start" alignItems="center"className={styles.rowDetails}>
                <Grid xs={5} md={5} lg={5} item>
                  {translate('Auth:email')}
                </Grid>
                <Grid xs={7} md={7} lg={7} item>
                  {userProfile.email}
                </Grid>
              </Grid>
              <Grid container item xs={12} md={12} lg={12} direction="row" justify="flex-start" alignItems="center"className={styles.rowDetails}>
                <Grid xs={5} md={5} lg={5} item>
                  {translate('Auth:phoneNumber')}
                </Grid>
                <Grid xs={7} md={7} lg={7} item>
                  {userProfile.phone_number}
                </Grid>
              </Grid>
              <Grid container item xs={12} md={12} lg={12}  direction="row" alignItems="center"className={styles.rowDetails}>
                <Grid xs={5} md={5} lg={5} item>
                  {translate('Auth:password')}
                </Grid>
                <Grid xs={7} md={7} lg={7} item>
                <Link
                  component="button"
                  className='link_style'
                  onClick={event => showChangingPassword(event)}
                >
                  {translate('changePassword')}
                </Link>
                </Grid>
              </Grid>

              <Grid container item xs={12} md={12} lg={12}  direction="row" alignItems="center"className={styles.rowDetails}>
                <Button variant="contained" disableElevation className={`primary_yellow_btn ${styles.editProfileBtn}`} onClick={showEditProfile}>
                  {translate('editProfile')}
                </Button>
                <Button variant="contained" disableElevation className={`shade_light_btn ${styles.logOutBtb}`} onClick={logOutHandler}>
                  {translate('Auth:logOut')}
                </Button>
              </Grid>

            </Grid>
          </Grid>
        }
        { isChangingPwd && <ChangePasswordHandler
            translate={translate}
            backToProfilePage={backToProfilePage}
          />
        }

        { isUpdatingProfile && <UpdateProfileHandler
          translate={translate}
          backToProfilePage={backToProfilePage}
          userProfile={userProfile}
          updatingProfileCallback={updatingProfileCallback}
        />}
      </Grid>
    </div>)
}

export default MyProfilePage;