import React, { useState } from 'react';
import {
  Container, Grid, IconButton, Button, Link,
  Typography,
  InputAdornment
} from '@material-ui/core';
import { TextInput } from 'components';
import { Field, reduxForm } from 'redux-form';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import styles from './styles.module.scss';
import { Trans } from 'react-i18next';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, forgotCurrentPassword } from 'redux/auth';
import { showSuccessMessage, showErrorMessage } from 'lib/notifier';

let ChangePasswordForm = (props) => {
  const { handleSubmit, translate, pristine, invalid, submitting } = props;
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const currentUser = useSelector(state => state.user.currentUser);
  const dispatch = useDispatch();
  function handleClickShowCurrentPassword() {
    setShowCurrentPassword(!showCurrentPassword);
  }
  function handleClickShowNewPassword() {
    setShowNewPassword(!showNewPassword);
  }
  function handleClickShowConfirmNewPassword() {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  }

  const getCurrentPassword = async (event) => {
    event.preventDefault();
    await dispatch(forgotCurrentPassword({ email: currentUser.email }))
    showSuccessMessage(translate('Auth:sendNewPasswordToEmail', {email: currentUser.email}), 10000);
  }

  return (
    <Grid container className={styles.formBody}>
      <form onSubmit={handleSubmit}>
        <Grid className="mt_10" container item>
          <Field
            component={TextInput}
            variant="filled"
            name="password"
            label={translate('Auth:currentPassword')}
            type={showCurrentPassword ? 'text' : 'password'}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowCurrentPassword}
                    tabIndex={-1}
                  >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            required
          />
        </Grid>

        <Grid className="mt_10 mb_20" container item>
          <Typography>
            <Trans i18nKey="Auth:dontHaveCurrentPwd">
              <Link href="#" onClick={getCurrentPassword} />
            </Trans>
          </Typography>
        </Grid>

        <Grid className="mt_10" container item>
          <Field
            component={TextInput}
            variant="filled"
            name="newPassword"
            label={translate('Auth:newPassword')}
            type={showNewPassword ? 'text' : 'password'}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    tabIndex={-1}
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            required
          />
        </Grid>
        <Grid className="mt_10" container item>
          <Field
            component={TextInput}
            variant="filled"
            name="confirmNewPassword"
            label={translate('Auth:confirmNewPassword')}
            type={showConfirmNewPassword ? 'text' : 'password'}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowConfirmNewPassword}
                    tabIndex={-1}
                  >
                    {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            required
          />
        </Grid>
        <Button type="submit" className={`primary_yellow_btn ${styles.savePwdBtn}`}
          variant="contained" disabled={submitting || invalid}>
          {translate('Common:save')}
        </Button>
      </form>
    </Grid>
  )
}

const validate = (values, props) => {
  const { translate } = props;
  const errors = {};

  if (!values.newPassword) {
    errors.newPassword = translate('Auth:required');
  }
  if (values.newPassword !== values.confirmNewPassword) {
    errors.confirmNewPassword = translate('Auth:confirmPasswordNotMatch');
  }
  return errors;
};

ChangePasswordForm = reduxForm({ form: 'changePwdForm', validate })(ChangePasswordForm);

const ChangePasswordHandler = ( props ) => {
  const { translate, backToProfilePage } = props;
  const dispatch = useDispatch();

  const submitChangePassword = async (values) => {
    const data = {
      password: {
        currentPassword: values.password,
        newPassword: values.newPassword
      }
    }
    const {e} = await dispatch(changePassword(data)).catch(e => ({ e }));
    if (e) return showErrorMessage(translate('Auth:currentPasswordNotCorrect'))
    showSuccessMessage(translate('Auth:changedYourPasswordSuccessfully'));
    backToProfilePage();
  };
  return (
    <Grid xs={12} md={6} lg={5} item className={styles.changingPwdContent}>
      <Grid container className={`mobile_title_size ${styles.formTitle}`}>
        <Button onClick={() => backToProfilePage()} className='back_btn'>
          <ArrowBackIosIcon />
        </Button>
        <h1>{translate('Auth:changePassword')}</h1>
      </Grid>
      <ChangePasswordForm onSubmit={submitChangePassword} translate={translate} />
    </Grid>
  );
};

export default ChangePasswordHandler;