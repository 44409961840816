import React, { useState, useEffect, useCallback } from 'react';
import styles from './style.module.scss';
import { RadioGroup, Typography, CardContent, Card, Box, TextField } from '@material-ui/core';
import { SelectInput, IncreaseInput, CheckboxInput, FilesUploadInput } from 'components';
import { uploadJobFile } from 'redux/service';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { updateProcess } from 'redux/modal';

const supportedQuestionType = ['normal', 'multiple_selection', 'free_form'];

const DescribeComponent = ({ service, changeField, translate, resetForm }) => {
  const [answers, setAnswer] = useState({});
  const dispatch = useDispatch();
  const countryCode = useSelector((state) => state.user.geoLocation.countryCode);

  useEffect(() => {
    changeField('selectedSalesItem', null);
    let defaultAnswer = {};
    // prepare default answer
    service.questions.forEach((question) => {
      if (question.questionType === 'attachment')
        return (defaultAnswer = { ...defaultAnswer, [question.criteriaAttribute]: [] });
      if (!supportedQuestionType.includes(question.questionType))
        return (defaultAnswer = { ...defaultAnswer, [question.criteriaAttribute]: 1 });
    });
    setAnswer(defaultAnswer);
  }, [service]);

  useEffect(() => {
    if (Object.keys(answers).length === service.questions.length) {
      const payload = Object.keys(answers).map((question) => ({
        attribute: question,
        value: answers[question]
      }));
      resetForm();
      changeField('answers', payload);
      dispatch(updateProcess(1));
    }
  }, [answers]);

  const renderQuestion = (question, qIndex) => {
    const multipleComponent = () => {
      const handleMultiSelect = (event) => {
        // for multiple select
        let selectedValue = answers[question.criteriaAttribute];
        if (!selectedValue) {
          return setAnswer({ ...answers, [question.criteriaAttribute]: [event.target.value] });
        }
        const currentSelect = event.target.value;
        const findIndex = selectedValue.indexOf(currentSelect);
        if (findIndex >= 0 && selectedValue.length !== 1) {
          selectedValue.splice(findIndex, 1);
        }
        if (findIndex === -1) selectedValue = [...selectedValue, currentSelect];
        setAnswer({ ...answers, [question.criteriaAttribute]: selectedValue });
      };

      return (
        <RadioGroup className={styles.radioGroup}>
          {question.answers.map((answer, index) => (
            <CheckboxInput
              onClick={handleMultiSelect}
              key={index}
              value={answer.value}
              subtitle={answer.text}
              selectStyle={styles.w_80}
              selectedValues={answers[question.criteriaAttribute]}
            />
          ))}
        </RadioGroup>
      );
    };

    const normalComponent = () => {
      const handleChange = (event) => {
        return setAnswer({ ...answers, [question.criteriaAttribute]: event.target.value });
      };
      return (
        <RadioGroup
          value={answers[question.criteriaAttribute]}
          className={styles.radioGroup}
          onChange={handleChange}
        >
          {question.answers.map((answer, index) => (
            <SelectInput key={index} value={answer.value} subtitle={answer.text} />
          ))}
        </RadioGroup>
      );
    };

    const attachmentComponent = () => {
      const handleRemoveImage = (index) => {
        let selectedValue = answers[question.criteriaAttribute];
        selectedValue.splice(index, 1);
        setAnswer({
          ...answers,
          [question.criteriaAttribute]: selectedValue
        });
      };

      const handleSubmitFile = async (files) => {
        const promises = [];
        let selectedValue = answers[question.criteriaAttribute];
        const filesLength = files.length;
        for (let i = 0; i < filesLength; i += 1) {
          const formData = new FormData();
          formData.append('photo', files[i]);
          promises.push(dispatch(uploadJobFile(formData, countryCode)));
        }
        const result = await Promise.all(promises);
        result.forEach((item) => {
          if (item.data && item.data.location) {
            selectedValue = [...selectedValue, item.data.location];
          }
        });
        setAnswer({
          ...answers,
          [question.criteriaAttribute]: selectedValue
        });
      };
      return (
        <>
          <Typography className="mb_10" variant="subtitle1">
            {translate('attachment')}
          </Typography>
          <FilesUploadInput
            uploadedPicture={answers[question.criteriaAttribute]}
            handleRemoveImage={handleRemoveImage}
            onSubmit={handleSubmitFile}
          />
        </>
      );
    };

    const increaseComponent = () => {
      const handleChangeUnits = (value = 1) => {
        setAnswer({ ...answers, [question.criteriaAttribute]: value });
      };

      return (
        <IncreaseInput
          value={answers[question.criteriaAttribute]}
          handleChangeUnits={handleChangeUnits}
        />
      );
    };

    const freeFormComponent = () => {
      const handleChange = debounce((value) => {
        setAnswer({ ...answers, [question.criteriaAttribute]: value });
      }, 1000);
      return (
        <DebounceInput
          insertedValue={answers[question.criteriaAttribute]}
          updateForm={handleChange}
          translate={translate}
        />
      );
    };

    const mapQuestionTypeToComponent = {
      multiple_selection: multipleComponent,
      normal: normalComponent,
      attachment: attachmentComponent,
      incremental: increaseComponent,
      free_form: freeFormComponent
    };

    const renderType = () => {
      const Component = mapQuestionTypeToComponent[question.questionType];
      if (!Component) return null;
      return <Component />;
    };
    return (
      <Box mt={3} key={qIndex}>
        <Typography className={styles.question} variant="subtitle1">
          {question.text}
        </Typography>
        {renderType()}
      </Box>
    );
  };
  return (
    <Card className={styles.card}>
      <CardContent className={styles.cardContent}>
        <Typography variant="h1" className={styles.title}>
          {translate('describeYourPlace')}
        </Typography>
        <Typography className={`${styles.textDark} mt_8`} variant="subtitle1">
          {translate('askingPlaceExplain')}
        </Typography>

        {service &&
          service.questions &&
          service.questions.map((question, qIndex) => renderQuestion(question, qIndex))}
      </CardContent>
    </Card>
  );
};

const DebounceInput = ({ translate, updateForm, insertedValue }) => {
  const [value, setValue] = useState(insertedValue);
  const handleChange = (e) => {
    const userInput = e.target.value;
    setValue(userInput);
    updateForm(userInput);
  };
  return (
    <TextField
      value={value}
      className={styles.freeForm}
      onChange={handleChange}
      variant="outlined"
      placeholder={translate('inputBrandName')}
    />
  );
};

export default DescribeComponent;
