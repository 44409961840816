import React from 'react';
import LoadingImage from 'assets/image/servishero-loading.gif';


const SHIconLoading = (props) => {
  return (
    <div >
      <img src={LoadingImage} alt="loading image" />
    </div>
  );
}

export default SHIconLoading;