import React, { useState, useEffect, useContext } from "react";
import styles from "./payment.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Button,
  Grid,
  Typography,
  Box,
  useMediaQuery,
  IconButton,
  FormControlLabel,
  Checkbox,
  Link,
  CircularProgress,
} from "@material-ui/core";
import { AppContext } from "routes";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "moment/locale/th";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { PromoModal } from "components";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  PaymentMethod,
  OnceOffSummary,
  RecurringSummary,
  PaymentType,
} from "./components";
import { Trans } from "react-i18next";
import { buildFullPath } from "lib/helper";
import { showErrorMessage } from "lib/notifier";
import { digitalPayment, uploadProofPayment } from "redux/payment";
import { setPromoCode } from "redux/payment";
import { toggleErrorPage, setVoucher } from "redux/modal";

const SUBMIT_PAYMENT = gql`
  mutation (
    $orderId: ID!
    $discountCode: String
    $paymentMethod: String!
    $paymentDetails: PaymentDetails
    $recurringPaymentType: String
    $agreedOn: DateTime
  ) {
    submitPayment(
      orderId: $orderId
      discountCode: $discountCode
      paymentMethod: $paymentMethod
      paymentDetails: $paymentDetails
      recurringPaymentType: $recurringPaymentType
      agreedOn: $agreedOn
    ) {
      id
      paymentMethod
      paid
      amount {
        amountCents
        currencyCode
      }
      errorReason
      order {
        id
        totalPrice {
          amountCents
          currencyCode
        }
        totalDiscountedPrice {
          amountCents
          currencyCode
        }
        payableAmount {
          amountCents
          currencyCode
        }
      }
    }
  }
`;

const DISCOUNT_CODE_DETAILS = gql`
  query ($orderId: ID!, $discountCode: String!) {
    checkDiscountCodeValid(orderId: $orderId, discountCode: $discountCode) {
      valid
      creditCardValidation
      validPrefixCardNumbers
      bankApplied
    }
  }
`;

const Payment = ({ history }) => {
  const orderId = useSelector(
    (state) => state.payment && state.payment.currentOrderId
  );
  const dateType = useSelector(
    (state) => state.payment && state.payment.currentDateType
  );
  if (!orderId) return null;
  const contextProps = useContext(AppContext);
  const isRecurringBooking = dateType !== "once_off";
  const isOnMobile = useMediaQuery("(max-width:767px)", { noSsr: true });
  const { t: translate } = useTranslation("Payment");
  const [submitPayment, { loading: submitPaymentLoading }] =
    useMutation(SUBMIT_PAYMENT);
  const { refetch: checkValidCode } = useQuery(DISCOUNT_CODE_DETAILS, {
    fetchPolicy: "no-cache",
    skip: true,
  });
  const [order, setOrder] = useState({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [proofPaymentFile, setProofPaymentFile] = useState(null);
  const [agreedTermsAndConditions, setAgreedTermsAndConditions] =
    useState(false);
  const [fetchApiError, setFetchApiError] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const cardValidation = useSelector(
    (state) =>
      (state.payment &&
        state.payment.cardNumberValidate &&
        state.payment.cardNumberValidate.creditCardValidation) ||
      false
  );
  const dispatch = useDispatch();
  const countryCode = useSelector(
    (state) => state.user.geoLocation.countryCode
  );
  const language = useSelector(
    (state) => state.user && state.user.preferredLanguage
  );
  const voucherCode = useSelector(
    (state) => state.modal && state.modal.voucherCode
  );
  const promoCode = useSelector(
    (state) => state.payment && state.payment.promoCode
  );
  const jobId = order.job && order.job.publicId;
  const jobName = (order.directService && order.directService.name) || "";
  let query = isRecurringBooking
    ? `?subscriptionId=${order.publicId}&jobName=${jobName}`
    : `?jobId=${jobId}&jobName=${jobName}`;
  // keep search params to show co-branding logo
  if (contextProps.searchParams && contextProps.searchParams.urlSearchParams) {
    // urlSearchParams are ?params, so need to replace with & to match upper query
    query += contextProps.searchParams.urlSearchParams.replace("?", "&");
  }

  useEffect(() => {
    if (!promoCode) return;

    const codeValidation = async () => {
      const status = await checkValidCode({
        orderId,
        discountCode: promoCode,
      }).catch((e) => ({ e }));
      // check code then remove it
      dispatch(setPromoCode(""));
      if (status.e) return;

      dispatch(setVoucher(promoCode));
    };
    // validate partner code
    codeValidation();
  });

  useEffect(() => {
    // recurring monthly payment type only support credit
    if (selectedPaymentType === "monthly_recurring")
      setSelectedPaymentMethod("credit_card");
  }, [selectedPaymentType]);

  useEffect(() => {
    // open ui error when fetch fail
    fetchApiError && dispatch(toggleErrorPage(true));
  }, [fetchApiError]);

  const handleConfirmPayment = async () => {
    let paymentDetails = {};
    try {
      if (selectedPaymentMethod === "bank_transfer" && proofPaymentFile) {
        const formData = new FormData();
        formData.append("photo", proofPaymentFile);
        formData.append("order_id", orderId);
        setLoadingState(true);
        const { error, data } = await dispatch(
          uploadProofPayment(formData, countryCode)
        ).catch((error) => ({ error }));
        if (error) {
          setLoadingState(false);
          return showErrorMessage(JSON.stringify(error));
        }

        if (!data || !data.proof_payment) {
          setLoadingState(false);
          return showErrorMessage(translate("timeOutError"));
        }

        paymentDetails = {
          bankSlipUrl: data.proof_payment,
        };
      }

      await submitPayment({
        variables: {
          paymentMethod: selectedPaymentMethod,
          orderId,
          discountCode: voucherCode || null,
          agreedOn: moment().toISOString(),
          paymentDetails: paymentDetails,
        },
      });
      setLoadingState(false);
      location.replace(buildFullPath(`thankyou${query}`));
    } catch (error) {
      setLoadingState(false);
      return showErrorMessage(error);
    }
  };
  const handleGoToPayment = async () => {
    return history.push(`/${countryCode}/${language}/validation`);
  };

  const renderPaymentBtn = (selectedPaymentMethod) => {
    if (
      !selectedPaymentMethod ||
      selectedPaymentMethod === "cash" ||
      selectedPaymentMethod === "bank_transfer"
    ) {
      const isDisabledBtn =
        !selectedPaymentMethod ||
        (selectedPaymentMethod === "bank_transfer" && !proofPaymentFile);

      return (
        <Button
          onClick={handleConfirmPayment}
          variant="contained"
          color="default"
          fullWidth
          disabled={isDisabledBtn || submitPaymentLoading || loadingState}
          className="primary_yellow_btn"
        >
          {loadingState && <CircularProgress className={styles.loadingIcon} />}
          {translate("confirmBook")}
        </Button>
      );
    }

    const isDisabledBtn =
      isRecurringBooking &&
      selectedPaymentType === "monthly_recurring" &&
      !agreedTermsAndConditions;
    return (
      <Button
        onClick={handleGoToPayment}
        className={`${styles.payBtn} primary_yellow_btn`}
        variant="contained"
        color="primary"
        fullWidth
        disabled={isDisabledBtn || loadingState}
      >
        {translate("toPayment")}
      </Button>
    );
  };

  return (
    <>
      <PromoModal />
      <Grid className={styles.summary}>
        <Container>
          <Grid item container className={styles.dialogTitle}>
            {!isOnMobile && (
              <Box mb={2}>
                <Button
                  className={styles.backBtn}
                  onClick={() => history.goBack()}
                >
                  <ArrowBackIosIcon />
                  <Typography className={styles.btnText} variant="h3">
                    {translate("back")}
                  </Typography>
                </Button>
              </Box>
            )}

            {isOnMobile && (
              <Grid container item justify="flex-end">
                <IconButton
                  className={styles.closeBtn}
                  onClick={() => history.goBack()}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item container>
              <Typography className={styles.title} variant="h1">
                {translate("confirmPay")}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            className={styles.content}
            wrap={isOnMobile ? "wrap" : "nowrap"}
            direction={isOnMobile ? "column-reverse" : "row"}
          >
            <Grid item xs={12} sm={12} md={5} className={styles.paymentMethod}>
              {isRecurringBooking && (
                <PaymentType
                  setSelectedPaymentType={setSelectedPaymentType}
                  translate={translate}
                  selectedPaymentType={selectedPaymentType}
                  setFetchApiError={setFetchApiError}
                />
              )}

              {/* <Typography className={styles.title} variant="h3">
                {translate('payWith')}
              </Typography> */}
              <Typography variant="h3">
                {selectedPaymentType === "monthly_recurring"
                  ? translate("supportCreditOnly")
                  : translate("payWith")}
              </Typography>

              <Grid className={`mt_10 ${styles.paymentMethodWrap}`}>
                <PaymentMethod
                  order={order}
                  selectedPaymentMethod={selectedPaymentMethod}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                  translate={translate}
                  proofPaymentFile={proofPaymentFile}
                  setProofPaymentFile={setProofPaymentFile}
                  dateType={dateType}
                  cardValidation={cardValidation}
                  selectedPaymentType={selectedPaymentType}
                  setFetchApiError={setFetchApiError}
                />
              </Grid>

              {/* show recurring policy */}
              {isRecurringBooking &&
                selectedPaymentType === "monthly_recurring" && (
                  <Grid container>
                    <Typography
                      className={`${styles.notice} mt_30 mb_10`}
                      variant="h3"
                    >
                      {translate("notice")}
                    </Typography>
                    <Typography variant="body1">
                      {translate("noticeContent")}
                    </Typography>
                    <FormControlLabel
                      className="mt_10"
                      control={
                        <Checkbox
                          classes={{ root: styles.checkbox }}
                          checked={agreedTermsAndConditions}
                          onChange={() =>
                            setAgreedTermsAndConditions(
                              !agreedTermsAndConditions
                            )
                          }
                        />
                      }
                      label={
                        <Trans
                          i18nKey="Payment:agreed"
                          components={{
                            bold: (
                              <Link
                                href={buildFullPath(
                                  "terms",
                                  "https://servishero.com"
                                )}
                                target="_blank"
                                className="link_style"
                              />
                            ),
                          }}
                        />
                      }
                    />
                  </Grid>
                )}

              <Grid className={styles.paymentBtnWrap}>
                {renderPaymentBtn(selectedPaymentMethod)}
              </Grid>
            </Grid>

            <Grid container item xs={12} sm={12} md={7}>
              {!isRecurringBooking && (
                <OnceOffSummary
                  setFetchApiError={setFetchApiError}
                  translate={translate}
                  setOrder={setOrder}
                />
              )}
              {isRecurringBooking && (
                <RecurringSummary
                  setFetchApiError={setFetchApiError}
                  translate={translate}
                  setOrder={setOrder}
                  paymentType={selectedPaymentType}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default withRouter(Payment);
