import React from 'react';
import { Grid, Button, FormLabel, Radio, FormControlLabel, RadioGroup } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { TextInput, KeyboardDatePickerHandler } from 'components';
import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/th';

let ProfileForm = (props) => {
  const {
    handleSubmit,
    translate,
    invalid,
    pristine,
    submitting,
    excludeFields,
    handleClose
  } = props;
  const dispatch = useDispatch();
  const genders = [
    { name: translate('Profile:male'), value: 'male' },
    { name: translate('Profile:female'), value: 'female' }
  ];

  const radioButton = ({ input, ...rest }) => (
    <RadioGroup row {...input} {...rest}>
      {genders.map((item, i) => {
        return (
          <FormControlLabel key={i} value={`${item.value}`} control={<Radio />} label={item.name} />
        );
      })}
    </RadioGroup>
  );

  const isDate = (input) => {
    if (input && input.toString() === 'Invalid Date') return false;
    if (Object.prototype.toString.call(input) === '[object Date]') return true;
    return false;
  };

  function updateDateOfBirth(date) {
    if (isDate(date)) {
      dispatch(change('profileForm', 'dateOfBirth', value));
    }
  }

  const isShowField = (field) => {
    return !excludeFields.includes(field);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.formStyle}>
      {isShowField('name') && (
        <Grid className="mt_10" container item>
          <Field
            component={TextInput}
            variant="filled"
            name="name"
            label={translate('name')}
            type="text"
            margin="normal"
            required
          />
        </Grid>
      )}

      {isShowField('gender') && (
        <Grid item container justify="flex-start" className="mt_10 mb_5">
          <Grid item xs={12} sm={12} md={12}>
            <FormLabel component="legend" className={styles.formLabel}>
              {translate('gender')}
            </FormLabel>
            <Field name="gender" className={styles.gender} component={radioButton} />
          </Grid>
        </Grid>
      )}

      {isShowField('dateOfBirth') && (
        <Grid item container direction="row" md={12} sm={12}>
          <Field
            name="dateOfBirth"
            label={translate('Profile:dateOfBirth')}
            component={KeyboardDatePickerHandler}
            minDate={null}
            onChange={(value) => updateDateOfBirth(value)}
            fullWidth
            className={styles.datePicker}
          />
        </Grid>
      )}

      {isShowField('email') && (
        <Grid className="mt_10" container item>
          <Field
            component={TextInput}
            variant="filled"
            name="email"
            label={translate('Auth:email')}
            type="email"
            margin="normal"
            required
          />
        </Grid>
      )}

      {isShowField('phoneNumber') && (
        <Grid className="mt_10" container item>
          <Field
            component={TextInput}
            variant="filled"
            name="phoneNumber"
            label={translate('Auth:phoneNumber')}
            type="tel"
            margin="normal"
            required
          />
        </Grid>
      )}

      <Grid container item alignItems="center" className={styles.btnContainer}>
        <Button
          type="submit"
          disabled={submitting || invalid || pristine}
          className={`primary_yellow_btn ${styles.savePwdBtn}`}
          variant="contained"
        >
          {translate('Common:save')}
        </Button>
        {handleClose && <Button className={styles.addLaterBtn} onClick={handleClose}>{translate('Common:addLater')}</Button>}
      </Grid>
    </form>
  );
};

const validate = (values, props) => {
  const { translate } = props;
  const errors = {};

  if (!values.email) {
    errors.email = translate('Common:required');
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = translate('invalidEmail');
  }

  if (!values.dateOfBirth) {
    errors.dateOfBirth = translate('Common:required');
  } else if (moment(values.dateOfBirth).isSameOrAfter(moment())) {
    errors.dateOfBirth = translate('Common:invalidDate');
  }

  if (!values.name) {
    errors.name = translate('Common:required');
  }

  if (!values.gender) {
    errors.gender = translate('Common:required');
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = translate('Common:required');
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => {
  const currentUser = state.user.currentUser;
  const optionalFields = ownProps.excludeFields;
  let excludeFields = [];
  if (optionalFields) {
    const fields = typeof optionalFields === 'string' ? optionalFields.split(',') : optionalFields;
    excludeFields = [...excludeFields, ...fields];
  }
  return {
    initialValues: {
      id: (currentUser && currentUser.id) || null,
      name: (currentUser && currentUser.name) || null,
      email: (currentUser && currentUser.email) || null,
      dateOfBirth: (currentUser && currentUser.dob) || null,
      gender: (currentUser && currentUser.gender) || null,
      phoneNumber: (currentUser && currentUser.phone_number) || null
    },
    excludeFields
  };
};

ProfileForm = reduxForm({ form: 'profileForm', validate })(ProfileForm);

export default compose(connect(mapStateToProps, null))(ProfileForm);
