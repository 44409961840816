import React, { useContext } from 'react';
import styles from './thankyou.module.scss';
import { useSelector } from 'react-redux';
import { Grid, Container, Button, Typography, Box } from '@material-ui/core';
import backgroundSrc from 'assets/image/thankyou_bg.svg';
import { rootPath } from 'lib/helper';
import { AppContext } from 'routes';
import { useTranslation } from 'react-i18next';
import { SHApplicationConnection } from 'components';

const Thankyou = (props) => {
  const { t: translate } = useTranslation('Common');

  const {
    location: { state }
  } = props;
  const contextProps = useContext(AppContext);
  const router = useSelector((state) => state.router);
  const jobId = router.location.query ? router.location.query.jobId : state ? state.jobId : '';
  const jobName = router.location.query ? router.location.query.jobName : state ? state.jobName : '';
  const subscriptionId = (router.location.query && router.location.query.subscriptionId) || '';

  const handleBackToHome = () => {
    const { partnerId, referenceId } = contextProps.searchParams;
    if (!partnerId && !referenceId) return location.replace(rootPath());
    // keep partnerId and referenceId
    let search = [];
    if (partnerId) search.push(`partnerId=${partnerId}`);
    if (referenceId) search.push(`referenceId=${referenceId}`);
    location.replace(`${rootPath()}?${search.join('&')}`)
  };
  return (
    <Grid className={styles.rootContainer}>
      <Grid className={styles.imageContainer} container justify="center">
        <img className={styles.image} src={backgroundSrc} alt="bg_img" />
      </Grid>
      <Container maxWidth="md" className={styles.container}>
        <Grid item container alignItems="center" direction="column">
          <Grid item>
            <Typography variant="h1" className={styles.title}>
              {translate('thankyou')}
            </Typography>
          </Grid>
          <Box mb={3}>
            <Grid item>
              <Typography variant="h3" className={styles.subtitle}>
                { subscriptionId ?
                  translate('thankYouContentForRecurringService', {jobName: decodeURIComponent(jobName), subscriptionId: subscriptionId} )
                  :
                  translate('thankYouContentForOnceOffJob', {jobName: decodeURIComponent(jobName) ,jobId: jobId} )
                }
              </Typography>
            </Grid>
          </Box>
          <Grid item>
            <Button disabled className='shade_light_btn mr_10 pl_10 pr_10'>
              {translate('manageJobs')}
            </Button>
            <Button onClick={handleBackToHome} className='pl_10 primary_yellow_btn pr_10'>
              {translate('backToHome')}
            </Button>
          </Grid>
          <SHApplicationConnection translate={translate} />
        </Grid>
      </Container>
    </Grid>
  );
};

export default Thankyou;
