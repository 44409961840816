import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/th';
// Material components
import { KeyboardDatePicker } from '@material-ui/pickers';

// Material helpers
import { withStyles } from '@material-ui/core';

// Component styles
const styles = {
  root: {},
  inputAdornment: {
    position: 'end',
    '& .MuiIconButton-root': {
      padding: '10px 10px 10px 5px'
    }
  }
};

export default withStyles(styles)((props) => {
  const { classes, input, label, meta: { touched, error } = {}, helperText, ...rest } = props;
  const { t: translate } = useTranslation('Error');
  function handleDateChange(value) {
    input.onChange(value);
  }

  if (rest.minDate === null) {
    rest.minDate = undefined;
  }

  return (
    <KeyboardDatePicker
      className={classes.root}
      InputAdornmentProps={{
        className: classes.inputAdornment
      }}
      autoOk
      variant="inline"
      inputVariant={props.variant || 'outlined'}
      margin="dense"
      label={label}
      format={'DD/MM/YYYY'}
      value={(input && input.value) || null}
      onChange={(value) => handleDateChange(value)}
      placeholder={translate('Common:dateFormat1')}
      KeyboardButtonProps={{
        'aria-label': 'change date'
      }}
      maxDate={moment()}
      minDateMessage="Invalid Date"
      maxDateMessage="Invalid Date"
      error={touched && error !== undefined}
      helperText={touched ? translate(error) || helperText : helperText}
      {...rest}
    />
  );
});
