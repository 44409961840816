import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Breadcrumbs,
  RadioGroup,
  IconButton,
  Box,
  CircularProgress
} from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/th';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import styles from './styles.module.scss';
import { change, formValueSelector } from 'redux-form';
import clsx from 'clsx';
import { formatPrice } from 'lib/currencyFormatter';
import { connect } from 'react-redux';
import { TimeSelector, DateSelector } from 'components';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PlanSelect from '../select/planSelect';
import { Trans } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import lightBubSrc from 'assets/icons/lightBub.svg';
import piggySrc from 'assets/icons/Piggybank.svg';
import { toggleDateTimeModal } from 'redux/modal';

const GET_WEEKLY_PLAN_REVIEW = gql`
  query(
    $directServiceId: ID!
    $frequency: WeeklyFrequency!
    $selectedSalesItem: SelectedSalesItem!
    $selectedOptionalSalesItems: [ID!]!
  ) {
    weeklyRecurringServicePlanPreview(
      directServiceId: $directServiceId
      frequency: $frequency
      selectedSalesItem: $selectedSalesItem
      selectedOptionalSalesItems: $selectedOptionalSalesItems
    ) {
      id
      name
      duration
      durationUnit
      sessions
      totalSession
      startDate
      endDate
      serviceName
      subtotalAmount {
        amountCents
        currencyCode
      }
      totalAmount {
        amountCents
        currencyCode
      }
      pricePerSession {
        amountCents
        currencyCode
      }
      saveAmount {
        amountCents
        currencyCode
      }
      normalPrice {
        amountCents
        currencyCode
      }
      isBestPrice
    }
  }
`;

const WeeklyModal = ({
  directServiceId,
  selectedOptionalItems,
  selectedSalesItem,
  changeField,
  translate,
  selectedFrequency,
  toggleDateTimeModalDispatch,
  isOpenDateTimeModal
}) => {
  const dayOfWeek = {
    sunday: {
      selected: false,
      time: '',
      name: translate('Common:sunday')
    },
    monday: {
      selected: false,
      time: '',
      name: translate('Common:monday')
    },
    tuesday: {
      selected: false,
      time: '',
      name: translate('Common:tuesday')
    },
    wednesday: {
      selected: false,
      time: '',
      name: translate('Common:wednesday')
    },
    thursday: {
      selected: false,
      time: '',
      name: translate('Common:thursday')
    },
    friday: {
      selected: false,
      time: '',
      name: translate('Common:friday')
    },
    saturday: {
      selected: false,
      time: '',
      name: translate('Common:saturday')
    }
  };
  const [isSelectSchedule, setIsSelectSchedule] = useState(true);
  const [stateDayOfWeek, setStateDayOfWeek] = useState(dayOfWeek);
  const [isUpdatedTime, setIsUpdatedTime] = useState(false);
  const [selectedDate, handleDateChange] = useState(moment().add(1, 'day'));
  const [frequency, setFrequency] = useState('');
  const isOnMobile = useMediaQuery('(max-width:992px)', { noSsr: true });



  const handleConfirm = () => {
    setIsSelectSchedule(true);
    toggleDateTimeModalDispatch(false);
  };
  const handleNext = () => {
    setIsSelectSchedule(false);
    const days = Object.keys(stateDayOfWeek).reduce((accumulator, currentValue, index) => {
      if (stateDayOfWeek[currentValue].time) {
        accumulator.push({
          weekDay: index,
          startTime: moment(stateDayOfWeek[currentValue].time, translate('Common:timeFormat')).diff(
            moment().startOf('day'),
            'seconds'
          )
        });
      }
      return accumulator;
    }, []);
    const frequency = {
      startDate: moment(selectedDate).startOf('date').toISOString(),
      days
    };
    changeField('frequency', frequency);
    setFrequency(frequency);
  };

  const handleChange = (event) => {
    const dayState = stateDayOfWeek[event.target.name];
    const checkedState = {
      ...dayState,
      selected: !dayState.selected,
      time: ''
    };
    setStateDayOfWeek({ ...stateDayOfWeek, [event.target.name]: checkedState });
  };

  //disable when user uncheck all
  useEffect(() => {
    const selectedDateList = Object.keys(dayOfWeek).filter((day) => {
      return stateDayOfWeek[day].selected;
    });
    // handle date not have time
    const notSelectedTimeList = selectedDateList.filter(day => !stateDayOfWeek[day].time);
    if (notSelectedTimeList.length > 0) return setIsUpdatedTime(false);

    setIsUpdatedTime(selectedDateList.length === 0 ? false : true);
  }, [stateDayOfWeek]);

  const renderDateTime = () => {
    return Object.keys(dayOfWeek).map((day, index) => {
      const handleTimeChange = (value) => {
        const dayState = stateDayOfWeek[day];
        const checkedState = { ...dayState, selected: true, time: value };
        setStateDayOfWeek({ ...stateDayOfWeek, [day]: checkedState });
        setIsUpdatedTime(true);
      };
      return (
        <Grid key={index} container alignItems="center">
          <Grid item className={styles.daySelector}>
            <FormControlLabel
              control={
                <Checkbox checked={stateDayOfWeek[day].selected} onChange={handleChange} name={day} />
              }
              label={stateDayOfWeek[day].name}
            />
          </Grid>
          <Grid item className={styles.timeSelector}>
            <TimeSelector onChange={handleTimeChange} translate={translate} selectedTime={stateDayOfWeek[day].time} />
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Dialog maxWidth="md" classes={{ paper: styles.yearlyDialog }} open={isOpenDateTimeModal}>
      <DialogTitle className={styles.modalTitle}>
        {isOnMobile && (
          <Grid container justify="flex-end">
            <IconButton
              className={styles.closeBtn}
              onClick={() => {
                if (!selectedFrequency) changeField('dateType', '');
                toggleDateTimeModalDispatch(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
        <Grid container justify="space-between" alignItems="center">
          <Breadcrumbs
            className={styles.breadcrumbs}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Typography
              className={isSelectSchedule ? styles.currentBread : ''}
              color={isSelectSchedule ? 'textPrimary' : 'inherit'}
            >
              {translate('selectSchedule')}
            </Typography>
            <Typography
              className={isSelectSchedule ? '' : styles.currentBread}
              color={isSelectSchedule ? 'inherit' : 'textPrimary'}
            >
              {translate('selectDuration')}
            </Typography>
          </Breadcrumbs>
          {!isOnMobile && (
            <IconButton
              className={styles.closeBtn}
              onClick={() => {
                if (!selectedFrequency) changeField('dateType', '');
                toggleDateTimeModalDispatch(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent
        style={isSelectSchedule ? {} : { overflowY: 'hidden' }}
        className={styles.dialogContent}
      >
        {isSelectSchedule ? (
          <Grid className={styles.selectSchedule} container direction="row">
            <Grid item md={6} className={isOnMobile ? 'w_100' : ''}>
              <Box mb={2}>
                <FormLabel component="legend">{translate('willOccur')}</FormLabel>
              </Box>
              <FormGroup>{renderDateTime()}</FormGroup>
            </Grid>
            <Grid item md={6} className={isOnMobile ? 'mt_15' : ''}>
              <Box mb={2}>
                <FormLabel component="legend">{translate('firstService')}</FormLabel>
              </Box>
              <DateSelector
                autoOk
                disablePast
                disableToolbar
                styleProps={styles.weeklyCalendar}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </Grid>
          </Grid>
        ) : (
          <PlanDuration
            isOnMobile={isOnMobile}
            changeField={changeField}
            frequency={frequency}
            directServiceId={directServiceId}
            selectedOptionalItems={selectedOptionalItems}
            selectedSalesItem={selectedSalesItem}
            translate={translate}
          />
        )}
      </DialogContent>
      <DialogActions className={styles.modalFooter}>
        <Grid container justify={isOnMobile ? 'flex-end' : 'flex-start'} wrap="nowrap">
          <Grid
            className={isOnMobile && !isSelectSchedule ? styles.displayNone : ''}
            container
            item
            wrap="nowrap"
          >
            <Grid item>
              <Box mr={isOnMobile ? 1 : 2}>
                <img src={lightBubSrc} alt="lightBubSrc" />
              </Box>
            </Grid>
            <Grid item container direction="column">
              <Typography className={styles.textDark} variant="body2">
                {translate('didYouKnow')}
              </Typography>
              <Typography className={styles.textDark} variant="body2">
                {translate('recommend')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={isOnMobile ? styles.btnContainer : ''}>
            {isSelectSchedule && (
              <Button
                disabled={!selectedDate || !isUpdatedTime}
                onClick={handleNext}
                className={clsx(styles.actionButton, 'primary_yellow_btn')}
                variant="contained"
              >
                {translate('next')}
              </Button>
            )}
            {!isSelectSchedule && (
              <Grid container wrap="nowrap">
                <Box mr={isOnMobile ? 1 : 2}>
                  <Button
                    onClick={() => setIsSelectSchedule(true)}
                    className={clsx(styles.backBtn, 'shade_light_btn')}
                    variant="contained"
                  >
                    {translate('back')}
                  </Button>
                </Box>
                <Button
                  onClick={handleConfirm}
                  className={clsx(styles.actionButton, 'primary_yellow_btn')}
                  variant="contained"
                >
                  {translate('confirm')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const PlanDuration = ({
  directServiceId,
  selectedOptionalItems,
  selectedSalesItem,
  frequency,
  changeField,
  translate,
  isOnMobile
}) => {
  const { loading, error, data } = useQuery(GET_WEEKLY_PLAN_REVIEW, {
    variables: {
      directServiceId,
      frequency,
      selectedSalesItem,
      selectedOptionalSalesItems: selectedOptionalItems
    }
  });
  const [selectedPlan, setSelectedPlan] = useState(0);

  useEffect(() => {
    if (
      data &&
      data.weeklyRecurringServicePlanPreview &&
      weeklyRecurringServicePlanPreview[selectedPlan].sessions
    ) {
      changeField('sessions', weeklyRecurringServicePlanPreview[selectedPlan].sessions);
      changeField('recurringServicePlanId', weeklyRecurringServicePlanPreview[selectedPlan].id);
    }
  }, [data]);

  if (loading) return <div className='loading-content'><CircularProgress /></div>;

  const { weeklyRecurringServicePlanPreview } = data;

  const renderPlans = () => {
    return weeklyRecurringServicePlanPreview.map((plan, index) => (
      <PlanSelect
        key={index}
        value={index}
        title={plan.name}
        subtitle={translate('totalSessions', { numberSession: plan.totalSession })}
        translate={translate}
        price={formatPrice(plan.pricePerSession.amountCents, plan.pricePerSession.currencyCode)}
      />
    ));
  };

  const saveAmount =
    (weeklyRecurringServicePlanPreview &&
      weeklyRecurringServicePlanPreview[selectedPlan].saveAmount) ||
    {};
  const normalPrice =
    (weeklyRecurringServicePlanPreview &&
      weeklyRecurringServicePlanPreview[selectedPlan].normalPrice) ||
    {};
  const sessions =
    weeklyRecurringServicePlanPreview && weeklyRecurringServicePlanPreview[selectedPlan].sessions;

    const renderFrequency = () => {
    return sessions.map((session, index) => {
      return (
        <li key={index} className={index % 2 !== 0 ? styles.highLight : ''}>
          <Typography>{index + 1}</Typography>
          <Typography variant="body2">
            {moment(session).format(translate('Common:datetimeFormatWithDayName'))}
          </Typography>
        </li>
      );
    });
  };

  const handleChangePlan = (e) => {
    const index = parseInt(e.target.value);
    setSelectedPlan(index);
    changeField('sessions', weeklyRecurringServicePlanPreview[index].sessions);
    changeField('weeklyPreviewPlan', index);
    changeField('recurringServicePlanId', weeklyRecurringServicePlanPreview[selectedPlan].id);
  };

  return (
    <Grid className={styles.duration} container>
      <Grid className={styles.planContainer} item container md={7} direction="column" wrap="nowrap">
        <Box my={isOnMobile ? 1 : 2}>
          <FormLabel component="legend">{translate('willOccur')}</FormLabel>
        </Box>
        <Grid container className={styles.plan}>
          {weeklyRecurringServicePlanPreview && weeklyRecurringServicePlanPreview.length > 0 && (
            <RadioGroup
              className={styles.radioGroup}
              value={selectedPlan}
              onChange={handleChangePlan}
            >
              {renderPlans()}
            </RadioGroup>
          )}
        </Grid>
        <Grid container className={styles.save} wrap="nowrap">
          <Box className={styles.imageContainer} pl={1} mr={2}>
            <img src={piggySrc} alt="piggy_bank" />
          </Box>
          <Grid container justify="center" item wrap="nowrap" direction="column">
            <Typography variant="subtitle2">{translate('congratulations')}</Typography>
            <Box mb={1}>
              <Typography variant="body2" className={styles.textDark}>
                <Trans
                  i18nKey="Booking:saved"
                  values={{ price: formatPrice(saveAmount.amountCents, saveAmount.currencyCode) }}
                  components={{ bold: <strong style={{ color: 'black' }} /> }}
                />
              </Typography>
            </Box>
            <Typography variant="body2" className={styles.textDark}>
              {translate('normal', {
                price: formatPrice(normalPrice.amountCents, normalPrice.currencyCode)
              })}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={5} className={styles.scheduleContainer}>
        <Typography variant="h3">{translate('frequencySchedule')}</Typography>
        <Grid className={styles.frequency} container>
          <ul>{sessions && renderFrequency()}</ul>
        </Grid>
      </Grid>
    </Grid>
  );
};

const bookingFormSelector = formValueSelector('BookingForm');

const mapStateToProps = (state) => {
  const selectedService = state.service && state.service.selectedService;
  const directServiceId = (selectedService && selectedService.id) || null;
  const isOpenDateTimeModal = state.modal.openDateTimeModal;

  return {
    directServiceId,
    selectedOptionalItems: bookingFormSelector(state, 'selectedOptionalSalesItems') || [],
    selectedSalesItem: bookingFormSelector(state, 'selectedSalesItem') || null,
    selectedFrequency: bookingFormSelector(state, 'frequency') || '',
    isOpenDateTimeModal: isOpenDateTimeModal || false
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeField: (field, value) => dispatch(change('BookingForm', field, value)),
  toggleDateTimeModalDispatch: (value) => dispatch(toggleDateTimeModal(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyModal);
