import React, { useState } from 'react';
import styles from './styles.module.scss';
import { SvgIcon, InputAdornment } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/th';
import { useTranslation } from 'react-i18next';

const DateSelector = ({ value, onChange, styleProps, ...props }) => {
  const [selectedDate, setSelectedDate] = React.useState(value);
  const { t: translate } = useTranslation('Common');

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange && onChange(date);
  };
  return (
    <DatePicker
      {...props}
      variant="inline"
      inputVariant="outlined"
      className={clsx(styles.dateSelectWrapper, styleProps)}
      classes={{
        root: styles.dateSelectWrapper
      }}
      value={selectedDate || null}
      minDate={moment().add(1, 'day')}
      onChange={handleDateChange}
      labelFunc={(date) => (!date ? translate('selectDate') : date.format(translate('Common:dateFormat1')))}
      InputProps={{
        classes: {
          root: styles.dateInputWrapper,
          input: styles.dateSelect
        },
        endAdornment: (
          <InputAdornment position="end" style={{ marginLeft: 0 }}>
            <SvgIcon fontSize="inherit" style={{ fontSize: 24 }} component={ExpandMoreIcon} />
          </InputAdornment>
        )
      }}
    />
  );
};

export default DateSelector;
