import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from 'routes';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';
import { Grid, Card, CardHeader, SvgIcon, useMediaQuery } from '@material-ui/core';
import { ReactComponent as GridIcon } from 'assets/icons/grid.svg';
import { ReactComponent as PinIcon } from 'assets/icons/pin.svg';
import { LocationSelection, ServiceSelector,
  LocationConfirmation, ServiceSelectorMobile } from './components';
import { updateSettings } from 'redux/user';
import { setSelectedService } from 'redux/service';
import { updateProcess } from 'redux/modal';
import { useTranslation } from 'react-i18next';
import { showLocationErrorMessage } from 'lib/notifier';
import styles from './styles.module.scss';
import { buildFullPath } from 'lib/helper';
import { withRouter } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';

const GET_SAVED_ADDRESSES = gql`
  {
    savedAddresses {
      id
      address
      lat
      lng
      locality
      placeId
      unitNumber
      remarks
    }
  }
`;

const BookingTopBar = (props) => {

  const dispatch = useDispatch();
  const { t: translate } = useTranslation('Header');
  const bookingLocation = useSelector((state) => state.user.bookingLocation);
  const selectedService = useSelector((state) => state.service.selectedService);
  const isOnBookingForm = useSelector((state) => state.service.isOnBookingForm);
  const contextProps = useContext(AppContext);
  const [locationPopoverOpen, setLocationPopoverOpen] = useState(false);
  const [locationAnchorEl, setLocationAnchorEl] = useState(null);
  const [serviceAnchorEl, setServiceAnchorEl] = useState(null);
  const [servicePopoverOpen, setServicePopoverOpen] = useState(false);
  const [consumerSavedAddresses, setConsumerSavedAddresses] = useState([]);
  const [locationConfirmationOpen, setLocationConfirmationOpen] = useState(false);
  const locationWrapperRef = React.createRef();
  const serviceWrapperRef = React.createRef();
  const currentUser = useSelector((state) => state.user.currentUser);
  const isOnMobile = useMediaQuery('(max-width:767px)', { noSsr: true });


  const { refetch: fetchSaveAddress } = useQuery(GET_SAVED_ADDRESSES, {
    skip: true
  });

  useEffect(() => {

    async function getConsumerSaveAddress() {
      if (!currentUser) return;
      const res = await fetchSaveAddress();
      if (res && res.data) {
        setConsumerSavedAddresses(res.data.savedAddresses);
      }
    }

    getConsumerSaveAddress();
    openLocationCard();
  }, []);

  const openLocationCard = () => {
    if (selectedService && !bookingLocation) {
      setLocationAnchorEl(locationWrapperRef.current);
      showLocationErrorMessage(translate('location.chooseLocationFirst'));
      setLocationPopoverOpen(!locationPopoverOpen);
    }
  };
  // Handle when user click to open location selector
  const handleClickLocationCard = () => {
    setLocationAnchorEl(locationWrapperRef.current);
    setLocationPopoverOpen(!locationPopoverOpen);
  };

  // Handle close location selector
  const handleCloseLocationPopover = () => {
    setLocationAnchorEl(null);
    setLocationPopoverOpen(!locationPopoverOpen);
  };

  // Handle when user click to open service selector
  const handleClickServiceCard = () => {
    if (bookingLocation) {
      setServiceAnchorEl(serviceWrapperRef.current);
      setServicePopoverOpen(!servicePopoverOpen);
    } else {
      showLocationErrorMessage(translate('location.chooseLocationFirst'));
      setLocationAnchorEl(locationWrapperRef.current);
      setLocationPopoverOpen(!locationPopoverOpen);
    }
  };

  // Handle close service selector
  const handleCloseServicePopover = () => {
    setLocationAnchorEl(null);
    setServicePopoverOpen(!servicePopoverOpen);
  };

  // Handle location confirmation
  const confirmLocation = async (location) => {
    await dispatch(updateSettings({ bookingLocation: location }));
    setLocationConfirmationOpen(false);
  };

  const confirmLocationCancel = async () => {
    await dispatch(updateSettings({ bookingLocation: null }));
    setLocationConfirmationOpen(false);
  };

  const selectLocationCallback = async (location) => {
    if (!location) return null;
    await dispatch(updateSettings({ bookingLocation: location }));
    setLocationPopoverOpen(false);
    if (!location.unitNumber) {
      setLocationConfirmationOpen(true);
    }
  };
  const selectServiceCallback = async (service) => {
    setServicePopoverOpen(false);
    await dispatch(updateProcess(0));
    await dispatch(reset('BookingForm'));
    await dispatch(setSelectedService(service));
    if (!isOnBookingForm) {
      let url = buildFullPath('booking');
      if (contextProps.searchParams && contextProps.searchParams.urlSearchParams) {
        url += contextProps.searchParams.urlSearchParams
      }
      location.replace(url);
    }
  };

  // Update locality based on chosen location
  useEffect(() => {
    function updateConsumerLocation(location) {
      if (!location) return;
      dispatch(updateSettings({ geoLocation: location }));
    }

    updateConsumerLocation(bookingLocation);
  }, [bookingLocation]);

  return (
    <div className={styles.bookingTopBar}>
      <div className={styles.topbarNav}>
        <div className={styles.topbarWrapper}>
          <Grid container className={styles.gridContainer}>
            {/* location selector */}
            <Grid className={styles.locationControler} ref={locationWrapperRef}>
              <Card
                className={`${styles.card} ${locationPopoverOpen ? styles.selectedCard : ''}`}
                onClick={handleClickLocationCard}
              >
                <CardHeader
                  avatar={<SvgIcon component={PinIcon} />}
                  subheaderTypographyProps={{
                    style: {
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }
                  }}
                  classes={{root: styles.cardHeader, avatar: styles.cardIcon, content: styles.cardContent}}
                  title={translate('location.label')}
                  subheader={
                    !bookingLocation ? translate('location.placeholder') : bookingLocation.address
                  }
                />
              </Card>
            </Grid>

            {/* Service controler */}
            <Grid className={styles.serviceControler} ref={serviceWrapperRef}>
              <Card
                className={`${styles.card} ${servicePopoverOpen ? styles.selectedCard : ''}`}
                onClick={handleClickServiceCard}
              >
                <CardHeader
                  avatar={<SvgIcon component={GridIcon} />}
                  title={translate('service.label')}
                  subheaderTypographyProps={{
                    style: {
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: 205
                    }
                  }}
                  classes={{root: styles.cardHeader, avatar: styles.cardIcon, content: styles.cardContent}}
                  subheader={
                    !selectedService ? translate('service.placeholder') : selectedService.name
                  }
                />
              </Card>
            </Grid>
          </Grid>

          {/* Location selector */}
          <LocationSelection
            open={locationPopoverOpen}
            selectLocationCallback={selectLocationCallback}
            handleCloseLocationPopover={handleCloseLocationPopover}
            anchorEl={locationAnchorEl}
            consumerSavedAddresses={consumerSavedAddresses}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            translate={translate}
          />

          {/* Service selector */}
          { !isOnMobile &&
            <ServiceSelector
              open={servicePopoverOpen}
              selectServiceCallback={selectServiceCallback}
              onClose={handleCloseServicePopover}
              anchorEl={serviceAnchorEl}
              bookingLocation={bookingLocation}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              translate={translate}
            />
          }
          { isOnMobile &&
            <ServiceSelectorMobile
              open={servicePopoverOpen}
              selectServiceCallback={selectServiceCallback}
              handleCloseServicePopover={handleCloseServicePopover}
              anchorEl={serviceAnchorEl}
              bookingLocation={bookingLocation}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              translate={translate}
            />
          }

          {/* Location confirmation modal */}
          { bookingLocation && !bookingLocation.unitNumber && (
            <LocationConfirmation
              open={locationConfirmationOpen}
              location={bookingLocation}
              translate={translate}
              confirmLocationCancel={confirmLocationCancel}
              onSubmit={confirmLocation}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(BookingTopBar);
