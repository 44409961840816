import axios from 'axios';
import i18n from 'i18next';

const client = axios.create();
client.defaults.headers.common['Accept'] = 'application/json';

  // call every time make a request
client.interceptors.request.use((config) => {
  const { store } = require('services/store');
  const { partnerId, referenceId, accessToken } = store.getState().user;

  if (accessToken) {
    config.headers = {
      ...config.headers,
      'Authorization': `Bearer ${accessToken}`
    };
  }
  return config;

}, (error) => {
  return Promise.reject(error);
});

export function AuthorizedAPI(token, searchParams = {}) {
  if (token) client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  client.defaults.headers.common['x-locale'] = i18n.language;
  if (searchParams.partnerId) client.defaults.headers.common['partner-id'] = searchParams.partnerId;
  if (searchParams.referenceId) client.defaults.headers.common['reference-id'] = searchParams.referenceId;
  return client;
};

export function POST(url, token, data, searchParams) {
  const options = {
    method: 'POST',
    url,
    data
  };

  return AuthorizedAPI(token, searchParams)(options);
};


export function GET(url, token, data) {
  const options = {
    method: 'GET',
    url,
    data
  };

  return AuthorizedAPI(token)(options);
};

export function PUT(url, token, data, searchParams) {
  const options = {
    method: 'PUT',
    url,
    data
  };

  return AuthorizedAPI(token, searchParams)(options);
};