import React, { useEffect } from 'react';
import { SvgIcon, Container, Typography, Box } from '@material-ui/core';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { PopularServices, NewsAndUpdates } from './components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import { setIsOnBookingForm, setSelectedService } from 'redux/service';
import HomeBackground from 'assets/image/home_bg.svg';
import { reset } from 'redux-form';
import SHAppBanner from './components/servishero_app_banner';

const HomePage = (_props) => {
  const { t: translate } = useTranslation('HomePage');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsOnBookingForm(false));
    dispatch(setSelectedService(null));
    dispatch(reset('BookingForm'));
  }, []);

  return (
    <div className={styles.homePageRoot}>
      <div
        style={{ backgroundImage: `url(${HomeBackground})` }}
        className={styles.homeServiceContent}
      >
        {/* Popular on Servishero */}
        {
          <Container className={styles.popularServiceContainer}>
            <div className={styles.popularServicesWrapper}>
              <div className={styles.popularServices}>
                <div className={styles.popularServiceTitle}>
                  <SvgIcon className={styles.starIcon} viewBox="0 0 34 34" component={StarIcon} />

                  <Typography variant="h1" className={styles.loginTitle}>
                    {translate('pupularService')}
                  </Typography>
                </div>
                <PopularServices />
              </div>
            </div>
          </Container>
        }
        {/* End of popular */}

        {/* News and Updates */}
        <Container className={styles.newsAndUpdatesContainer}>
          <div className={styles.newsAndUpdatesWrapper}>
            <div className={styles.newsAndUpdates}>
              <div className={styles.newsAndUpdatesTitle}>
                <Typography variant="h1" className={styles.loginTitle}>
                  {translate('newsAndUpdates')}
                </Typography>
              </div>
              <NewsAndUpdates />
            </div>
          </div>
        </Container>
        {/* End of News and Updates  */}
        <Box mt={3}>
          <Container className={styles.popularServiceContainer}>
            <SHAppBanner translate={translate} />
          </Container>
        </Box>
      </div>
    </div>
  );
};

export default HomePage;
