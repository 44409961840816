import React from 'react';
import { Button, Grid, Typography, Box } from '@material-ui/core';
import styles from './../../styles.module.scss';
import { Field, reduxForm } from 'redux-form';
import { TextInput } from 'components';

let ResetPasswordForm = ({ handleSubmit, translate }) => {

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Typography className={styles.greyText}>
          { translate('resetPwdDescription')}
        </Typography>
      </Grid>
      <Box mt={2} mb={2}>
        <Grid container>
          <Field
            component={TextInput}
            variant="filled"
            name="email"
            label={translate('email')}
            required
          />
        </Grid>
      </Box>
      <Grid container>
        <Button className='primary_yellow_btn' variant="contained" type="submit">
          {translate('getNewPwd')}
        </Button>
      </Grid>
    </form>
  );
};
const validate = (values, props) => {
  const { translate } = props;

  const errors = {};
  if (!values.email) {
    errors.email = translate('Auth:required');
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = translate('invalidEmail');
  }
  return errors;
};

ResetPasswordForm = reduxForm({ form: 'resetPasswordForm', validate })(ResetPasswordForm);


export default ResetPasswordForm;
