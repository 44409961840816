import React, { useEffect, Fragment } from 'react';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import {
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@material-ui/core';
import cashIcon from 'assets/icons/Cash.svg';
import creditIcon from 'assets/icons/Credit.svg';
import grabIcon from 'assets/icons/grab.svg';
import eWalletIcon from 'assets/icons/e_wallet.svg';
import fpxIcon from 'assets/icons/fpx_banking.png';
import styles from '../payment.module.scss';
import BankTransfer from './bank_transfers';

const GET_PAYMENT_METHOD = gql`
  query($serviceId: ID!) {
    service(serviceId: $serviceId) {
      id
      paymentMethods {
        direct {
          id
          name
          value
          option
          imageUrl
        }
      }
    }
  }
`;

const GET_RECURRING_PAYMENT_METHOD = gql`
  query($serviceId: ID!) {
    service(serviceId: $serviceId) {
      paymentMethods {
        recurring {
          id
          name
          value
          option
          imageUrl
        }
      }
    }
  }
`;

const notDisabledMethod = ['cash'];
const CREDIT_CARD = 'credit_card';
const BANK_TRANSFER = 'bank_transfer';
const E_WALLET = 'e_wallet';
const CASH = 'cash';
const MONTHLY_RECURRING = 'monthly_recurring';
const FPX_BANKING = 'ebanking_fpx';

const PaymentMethod = (props) => {

  const {
    selectedPaymentMethod, setSelectedPaymentMethod, order, translate, dateType, selectedPaymentType,
    cardValidation, setFetchApiError
  } = props;
  const isRecurringBooking = dateType !== 'once_off';
  const countryCode = useSelector(state => state.user.geoLocation.countryCode);
  const promoCode = useSelector(state => state.modal && state.modal.voucherCode);
  const directServiceId = useSelector(state => ((state.service || {}).selectedService || {}).id)
  const jobPrice = order && order.totalDiscountedPrice && order.totalDiscountedPrice.amountCents;

  if (!directServiceId) return null;

  const { loading, error, data } = useQuery(
    isRecurringBooking ? GET_RECURRING_PAYMENT_METHOD : GET_PAYMENT_METHOD,
    { variables: { serviceId: directServiceId }}
  );

  if (error) {
    setFetchApiError(true);
    return null;
  }

  // if job price = 0 use cash method
  useEffect(() => {
    if (jobPrice === 0) setSelectedPaymentMethod(CASH);
  }, [jobPrice]);

  useEffect(() => {
    if (cardValidation) setSelectedPaymentMethod(CREDIT_CARD);
  }, [cardValidation]);

  const paymentMethodDescriptions = {
    [CASH]: {
      imageSrc: cashIcon,
      content: translate('cashContent'),
      subContent: (
        <Trans
          i18nKey="Payment:cashSubContent"
          components={{ bold: <strong style={{ color: 'black' }} /> }}
        />
      )
    },
    [CREDIT_CARD]: {
      imageSrc: creditIcon,
      content: translate('cardPaymentMethodContent')
    },
    [BANK_TRANSFER]: {
      imageSrc: creditIcon,
      content: translate('bankTransferMethodContent')
    },
    [E_WALLET]: {
      imageSrc: eWalletIcon,
      content: translate('cardPaymentMethodContent'),
      supportedPayments: [grabIcon, cashIcon]
    },
    [FPX_BANKING]: {
      imageSrc: fpxIcon,
      content: translate('cardPaymentMethodContent'),
    }
  };

  const handleChangePaymentMethod = (value) => {
    setSelectedPaymentMethod(value);
  };

  if (loading) {
    return (
      <div className="loading-content">
        <CircularProgress />
      </div>
    );
  }

  const {
    service: { paymentMethods }
  } = data;

  const supportedPaymentMethods = paymentMethods[isRecurringBooking ? 'recurring' : 'direct'] || [];
  const bankTransferMethods = supportedPaymentMethods.filter(item => item.value === BANK_TRANSFER);
  const filteredPaymentMethods = supportedPaymentMethods.filter(item => !item.option && item.value !== BANK_TRANSFER);  

  /* payment type monthly_recurring,only support credit card
     if have card validation, just enable credit card
  */
  const isSelectedPaymentMethod = (value) => { return selectedPaymentMethod === value; };

  const paymentMethodClassName = (value) => {
    return isSelectedPaymentMethod(value) ? styles.selected : styles.unselect;
  };

  const isMonthlyRecurring = selectedPaymentType === MONTHLY_RECURRING;

  // (recurring job and method was monthly recurring) or (card validation) disable all, except credit_card
  const isDisablePaymentMethod = (value) => {
    return (jobPrice === 0 && !notDisabledMethod.includes(value)) ||
      (isRecurringBooking && isMonthlyRecurring && value !== CREDIT_CARD) ||
      (!!promoCode && !!cardValidation && value !== CREDIT_CARD);
  }

  return (
    <>
      {filteredPaymentMethods.map((method, indexPaymentMethod) => {
        if (!paymentMethodDescriptions[method.value]) return <Fragment key={indexPaymentMethod} />;

        return (
          <Accordion
            disabled={isDisablePaymentMethod(method.value)}
            key={indexPaymentMethod}
            className={paymentMethodClassName(method.value)}
            expanded={isSelectedPaymentMethod(method.value)}
            onChange={() => handleChangePaymentMethod(method.value)}
          >
            <AccordionSummary classes={{ content: styles.paymentMethodContent}} aria-controls="panel1a-content" id="panel1a-header">
              <Box className={styles.paymentMethodIconAndText}>
                <img className={styles.paymentMethodIcon}
                  src={ paymentMethodDescriptions[method.value].imageSrc}
                  alt="payment method icon"
                />
                <Typography>{method.name}</Typography>
              </Box>

              { paymentMethodDescriptions[method.value].supportedPayments &&
                <Box className={styles.supportedPayments}>
                  { paymentMethodDescriptions[method.value].supportedPayments.map((icon, indexIcon) => {
                    return <img src={icon} className={styles.supportedPaymentIcon} key={indexIcon} alt="supported payment icons" />
                  })}
                </Box>
              }
            </AccordionSummary>

            <AccordionDetails>
              <Grid container>
                { paymentMethodDescriptions[method.value].content && (
                    <Typography>{paymentMethodDescriptions[method.value].content}</Typography>
                  )}
                { paymentMethodDescriptions[method.value].subContent && (
                    <Box mt={2}>
                      <Typography>{paymentMethodDescriptions[method.value].subContent}</Typography>
                    </Box>
                  )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        );

      })}
      
      <BankTransfer {...props} 
        bankTransferMethods={bankTransferMethods}
        countryCode={countryCode}
        paymentMethodClassName={paymentMethodClassName}
        isSelectedPaymentMethod={isSelectedPaymentMethod} 
        handleChangePaymentMethod={handleChangePaymentMethod}
        isDisablePaymentMethod={isDisablePaymentMethod}
      />
    </>
  );
};
export default PaymentMethod;
