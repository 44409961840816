import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { connect, useDispatch } from 'react-redux';
import { Grid, Card, useMediaQuery } from '@material-ui/core';
import { BookingForm } from './components/form';
import { formValueSelector } from 'redux-form';
import { setIsOnBookingForm } from 'redux/service';
import { toggleErrorPage } from 'redux/modal';
import { useTranslation } from 'react-i18next';
import StepperComponent from './components/stepper';
import { OnceOffComponent, WeeklyComponent, YearlyComponent } from './components/preview';

const mapDateTypeWithComponent = {
  once_off: OnceOffComponent,
  yearly: YearlyComponent,
  weekly: WeeklyComponent
};

const Booking = ({
  directServiceId,
  selectedOptionalItems,
  selectedSalesItem,
  dateType,
  timeslots,
  frequency,
  processNumber,
  weeklyPreviewPlan
}) => {
  const { t: translate } = useTranslation('Booking');
  const isOnMobile = useMediaQuery('(max-width:767px)', { noSsr: true });

  const [isShowSummary, setShowSummary] = useState(false);
  const [fetchApiError, setFetchApiError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // open ui error when fetch fail
    fetchApiError && dispatch(toggleErrorPage(true));
  }, [fetchApiError]);

  useEffect(() => {
    if (!directServiceId || !selectedSalesItem) {
      setShowSummary(false);
    } else if (
      (dateType === 'once_off' && timeslots) ||
      ((dateType === 'yearly' || dateType === 'weekly') && frequency)
    ) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }, [directServiceId, selectedSalesItem, timeslots, frequency]);

  useEffect(() => {
    dispatch(setIsOnBookingForm(true));
  }, []);
  const renderSummary = () => {
    const Components = mapDateTypeWithComponent[dateType];
    if (!Components) return null;
    return (
      <Components
        translate={translate}
        directServiceId={directServiceId}
        selectedOptionalItems={selectedOptionalItems}
        selectedSalesItem={selectedSalesItem}
        dateType={dateType}
        timeslots={timeslots}
        frequency={frequency}
        weeklyPreviewPlan={weeklyPreviewPlan}
        setFetchApiError={setFetchApiError}
      />
    );
  };

  if (!directServiceId) return null;
  return (
    <Grid container={isOnMobile ? false : true} className={styles.container}>
      <Card className={`${isShowSummary ? styles.spacing : ''} ${styles.stepper}`}>
        <StepperComponent processNumber={processNumber} translate={translate} />
      </Card>
      <Grid item className={styles.content}>
        <BookingForm translate={translate} setFetchApiError={setFetchApiError} />
      </Grid>
      {isShowSummary && renderSummary()}
    </Grid>
  );
};

const bookingFormSelector = formValueSelector('BookingForm');

const mapStateToProps = (state) => {
  const selectedService = state.service && state.service.selectedService;
  const {
    selectedOptionalSalesItems,
    selectedSalesItem,
    dateType,
    timeslots,
    frequency,
    weeklyPreviewPlan
  } = bookingFormSelector(
    state,
    'selectedOptionalSalesItems',
    'selectedSalesItem',
    'dateType',
    'timeslots',
    'frequency',
    'weeklyPreviewPlan'
  );
  return {
    directServiceId: (selectedService && selectedService.id) || null,
    selectedOptionalItems: selectedOptionalSalesItems || [],
    selectedSalesItem: selectedSalesItem || null,
    dateType: dateType || '',
    timeslots: timeslots || '',
    frequency: frequency || '',
    processNumber: state.modal && state.modal.processBooking,
    weeklyPreviewPlan: weeklyPreviewPlan || 0
  };
};

export default connect(mapStateToProps, null)(Booking);
