import React from 'react';
import { Box, Grid, Typography, Card, Link, useMediaQuery } from '@material-ui/core';
import styles from './styles.module.scss';

import appStoreSrc from 'assets/image/appStore.svg';
import googleStoreSrc from 'assets/image/googlePlay.svg';
import qrSrc from 'assets/image/qrCode.svg';
import downloadSrc from 'assets/icons/scan_icon.svg';
import shImageSrc from 'assets/image/sh_image.png';

const SHAppBanner = (props) => {
  const { translate } = props;
  const isOnMobile = useMediaQuery('(max-width:767px)', { noSsr: true });

  return (
    <Card className={styles.cardWrapper}>
      <Grid container>
        <Grid item md={6} className={styles.contextWrapper}>
          <Grid style={{ width: '80%' }}>
            <Typography variant="h1">{translate('servisheroApp')}</Typography>
            <Box mt={2} style={{ width: isOnMobile ? '100%' : '70%' }}>
              <Typography>{translate('downloadSHApp')}</Typography>
            </Box>
            <Box mt={3}>
              <Grid container wrap="nowrap">
                <Grid style={{ width: 140 }}>
                  <Link
                    href="https://itunes.apple.com/my/app/servishero-on-demand-services/id992334828?mt=8"
                    target="_blank"
                  >
                    <img src={appStoreSrc} alt="app_store" className={styles.w_100} />
                  </Link>

                  <Link
                    href="https://play.google.com/store/apps/details?id=com.servishero.consumer&amp;hl=en"
                    target="_blank"
                  >
                    <img src={googleStoreSrc} alt="google_store" className={styles.w_100} />
                  </Link>
                </Grid>
                <Grid item>
                  <Card className={styles.cardBorder}>
                    <Box display="flex" justifyContent="space-between" p={1}>
                      {!isOnMobile && (
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <img src={downloadSrc} alt="downloadSrc" className={styles.scanIcon} />
                          <Typography
                            className={styles.scanText}
                            align="center"
                            variant="subtitle2"
                          >
                            SCAN TO DOWNLOAD
                          </Typography>
                        </Box>
                      )}
                      <img src={qrSrc} alt="qr_code" className={styles.qr} />
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {!isOnMobile && (
          <Grid item md={6}>
            <Box display="flex" justifyContent="center">
              <img src={shImageSrc} alt="shImageSrc" />
            </Box>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default SHAppBanner;
