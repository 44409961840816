export default {
  confirm: 'ยืนยัน',
  selectDate: 'เลือกวันที่',
  dateFormat1: 'DD MM YYYY',
  dateFormat2: 'DD MMM YYYY',
  timeFormat: 'HH:mmน.',
  datetimeFormat: 'DD MMM YYYY HH:mmน.',
  datetimeFormatWithDayName: 'ddd DD MMM YYYY - HH:mmน.',
  or: 'หรือ',
  help: 'ช่วยเหลือ',
  getApp: 'ดาวน์โหลดแอปพลิเคชัน',
  back: 'กลับ',
  save: 'บันทึก',
  welcomeToSH: 'ยินดีต้อนรับเข้าสู่ ServisHero',
  thanksForSigningUp: 'ขอบคุณที่ลงทะเบียนกับ ServisHero บัญชีของคุณได้ถูกสร้างขึ้นแล้วและ',
  bookFirstServiceWithPromotion: 'คุณได้รับส่วนลด 20% สำหรับการจองบริการครั้งแรกทันที!',
  findServiceAndMakeFirstBooking: 'ค้นหาและจองบริการได้เลย',
  bookNow: 'จองเลย',
  buySubscription: 'สมัครบริการรายเดือน',
  buySubscriptionDesc: 'ใช้บริการบ่อย ไม่อยากจองใหม่ทุกครั้ง สมัครบริการรายเดือนเลย',
  buyPlan: 'สมััครรับบริการระยะยาว',
  shMobileApp: 'แอปพลิเคชันเซอร์วิสฮีโร่',
  downloadAppNow: 'ดาวน์โหลดแอปพลิเคชันเพื่อการใช้งานที่สะดวกกว่า',
  scanToDownload: 'หรือสแกนคิวอาร์โค้ดด้วยมือถือของคุณ',
  thankyou: 'ขอบคุณ',
  manageJobs: 'จัดการงาน',
  backToHome: 'กลับสู่หน้าหลัก',
  thankYouContentForRecurringService: 'คุณได้จองบริการ {{jobName}} สำเร็จแล้ว รหัสงาน: {{subscriptionId}}',
  thankYouContentForOnceOffJob: 'คุณได้จองบริการ {{jobName}} สำเร็จแล้ว รหัสงาน: {{jobId}}',
  oopsContent: 'ขออภัย บริการนี้ยังไม่เปิดให้บริการในเขตพื้นที่ของคุณ ติดต่อเราเพื่อสอบถามเพิ่มเติมได้ทางไลน์ @servishero (มี @)',
  close: 'ปิด',
  contactForSupport: 'ติดต่อสอบถาม',
  whatsappAvailable: 'จันทร์-ศุกร์ 09.00-18.00น.',
  hotline: 'โทร',
  email: 'อีเมล',
  getUpdateAndPromos: 'ไม่อยากพลาดการอัพเดทและโปรโมชั่นดีๆ',
  followingUsOnFB: 'ติดตามเราบนเฟสบุ๊ค',
  whatsApp: '',
  line: 'ไลน์',
  lineUsAt: 'ไลน์ @servishero',
  termsOfService: 'เงื่อนไขและข้อตกลงการให้บริการ',
  letUsKnow: 'เกี่ยวกับคุณ',
  letUsContent: 'กรุณาแจ้งข้อมูลที่ถูกต้องเพื่อให้การให้บริการของเรานั้นสะดวกและเหมาะสมกับคุณมากที่สุด',
  name: 'ชื่อ-นามสกุล',
  email: 'อีเมล',
  phoneNumber: 'เบอร์มือถือ',
  changedYourProfileSuccessfully: 'อัปเดตโปรไฟล์สำเร็จ',
  invalidDate: 'วันที่ไม่ถูกต้อง',
  oops: '',
  addLater: 'จะมาเพิ่มข้อมูลในภายหลัง',
  required: 'จำเป็น',
  sunday: 'วันอาทิตย์',
  monday: 'วันจันทร์',
  tuesday: 'วันอังคาร',
  wednesday: 'วันพุธ',
  thursday: 'วันพฤหัสบดี',
  friday: 'วันศุกร์',
  saturday: 'วันเสาร์',
  copied: 'คัดลอกแล้ว',
  copy: 'คัดลอก',
  oopsError: 'ขออภัย ระบบขัดข้องกำลังแก้ไข',
  pleaseContact: 'กรุณาลองใหม่อีกครั้งหรือติดต่อทีมงาน',
  forUrgent: 'ไลน์ @servishero (มี @)',
  tryingHard: 'ฟีเจอร์นี้กำลังอยู่ในการพัฒนา พร้อมเปิดให้บริการเร็วๆนี้',
  downloadToTrack: 'คุณสามารถดาวน์โหลดแอปพลิเคชัน ServisHero เพื่อติดตามและจัดการงาน'
}
