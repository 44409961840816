import React, { useEffect } from 'react';
import styles from './style.module.scss';
import { RadioGroup, Typography, CardContent, Card, Box, Button } from '@material-ui/core';
import { SelectInput } from 'components';
import LikeIconSrc from 'assets/icons/Like.svg';
import moment from 'moment';
import 'moment/locale/th';
import { toggleDateTimeModal } from 'redux/modal';
import { useDispatch } from 'react-redux';

const DateTimeComponent = ({
  changeField,
  serviceType,
  isRecurringService,
  setRef,
  translate,
  timeslots,
  dateType
}) => {
  const dispatch = useDispatch();
  const handleSelectType = (e) => {
    const value = e.target.value;
    changeField('dateType', value);
    changeField('frequency', null);
    changeField('timeslots', null);
    dispatch(toggleDateTimeModal(true));
  };

  const handleClick = () => {
    dispatch(toggleDateTimeModal(true));
  };
  return (
    <Card ref={(newRef) => setRef(newRef)} className={styles.card}>
      <CardContent className={styles.cardContent}>
        <Typography variant="h1" className={styles.title}>
          {translate('dateTime')}
        </Typography>
        <Box mt={1} mb={2}>
          <Typography variant="subtitle1" className={styles.textDark}>
            {translate('regular')}
          </Typography>
        </Box>
        <RadioGroup onChange={handleSelectType} value={dateType} className={styles.radioGroup}>
          <SelectInput
            selectStyle={styles.w_80}
            value="once_off"
            title={translate('justOnce')}
            subtitle={translate('selectDateTime')}
            scheduleType={dateType}
            translate={translate}
            handleClick={handleClick}
            name="once_off"
            dateTime={timeslots && moment(timeslots[0]).format(translate('Common:datetimeFormat'))}
          />
          {isRecurringService && (
            <SelectInput
              selectStyle={styles.w_80}
              value={serviceType}
              title={translate('repeat')}
              subtitle={translate('repeatDescription')}
              scheduleType={dateType}
              translate={translate}
              handleClick={handleClick}
              name={serviceType}
              titleIcon={LikeIconSrc}
            />
          )}
        </RadioGroup>
      </CardContent>
    </Card>
  );
};

export default DateTimeComponent;
