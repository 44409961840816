import React from 'react';
import { Typography, Grid, Select, MenuItem } from '@material-ui/core';
import styles from './styles.module.scss';
import moment from 'moment';
import 'moment/locale/th';

const timeArray = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

const TimeSelector = ({ onChange, selectedTime, translate }) => {

  const workingHours = timeArray.map(time => moment(time, "h").format(translate('Common:timeFormat')));

  const setTimeSelect = (event) => {
    onChange && onChange(event.target.value);
  };

  const renderTimeSlot = () => {
    return workingHours.map((hour, index) => (
      <MenuItem value={hour} key={index}>
        <Typography>{hour}</Typography>
      </MenuItem>
    ));
  };
  return (
    <Grid item container>
      <Select
        value={selectedTime || ''}
        variant="outlined"
        classes={{ root: styles.inputField, select: styles.timeSelect }}
        onChange={setTimeSelect}
        displayEmpty
        MenuProps={{ classes: { paper: styles.spacing } }}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value="" disabled>
          {translate('selectTime')}
        </MenuItem>
        {renderTimeSlot()}
      </Select>
    </Grid>
  );
};

export default TimeSelector;
