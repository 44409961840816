import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from '../services/history';
import { reducer as formReducer } from 'redux-form';
import AuthReducer from './auth';
import UserReducer from './user';
import ServiceReducer from './service';
import PaymentReducer from './payment';
import ModalReducer from './modal';

const appReducer = combineReducers({
  router: connectRouter(history),
  auth: AuthReducer,
  service: ServiceReducer,
  form: formReducer,
  payment: PaymentReducer,
  modal: ModalReducer,
  user: UserReducer
});
// eslint-disable-next-line
export default (state, action) => {
  if (action.type === 'RESET') {
    state = undefined;
  }

  return appReducer(state, action);
};
