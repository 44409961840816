
import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  SvgIcon,
  CircularProgress
} from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import bankTransfer from 'assets/icons/bank_transfer.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload_icon.svg';
import styles from './styles.module.scss';
import ProofPaymentDetails from './proof_payment_details';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DuitNowIcon from 'assets/icons/duit_now_logo.png';
import QrcodeIcon from 'assets/icons/qr_code_icon.svg';
import DuitnowQRCode from 'assets/image/duit_now_qr_code.svg';
import SHPaymentQRCodeTH from 'assets/image/payment_qr_code_for_thailand.svg';

const BankInformation = (props) => {
  const { className, expanded, handleChangePaymentMethod, translate, label,
    proofPaymentFile, setProofPaymentFile, countryCode, isDisablePaymentMethod,
    loadingBankDetails, errorBankDetails, bankDetailsData } = props;
  const [openProofPaymentDetails, setOpenProofPaymentDetails] = useState(false);

  const BANK_INFORMATION = {
    my: {
      qrCodeIcon: DuitNowIcon,
      qrCode: DuitnowQRCode,
      qrCodeTitle: 'Pay via QR Code from DuitNow',
      subtitle: 'Instant transfer by scanning the QR code with your banking app or E-wallets and send proof of payment'
    },
    th: {
      qrCodeIcon: QrcodeIcon,
      qrCode: SHPaymentQRCodeTH,
      qrCodeTitle: translate('payViaQrCode'),
    },
  }

  const handleChangeProofPaymentFile = async (files) => {
    const fileUpload = files[0];
    setProofPaymentFile(fileUpload);
  };

  const handleOpeningProofPaymentDetails = () => {
    setOpenProofPaymentDetails(true);
  }

  const handleClosingProofPaymentDetails = () => {
    setOpenProofPaymentDetails(false);
  }

  const imageIcon = countryCode.toLowerCase() === 'my' ? DuitNowIcon : bankTransfer;
  const subtitle = BANK_INFORMATION[countryCode].subtitle;

  return (
    <Accordion
      disabled={isDisablePaymentMethod}
      className={className}
      expanded={expanded}
      classes={{root: styles.bankTransferInformation}}
      onChange={() => handleChangePaymentMethod('bank_transfer')}
    >
      <AccordionSummary classes={{ content: styles.paymentMethodContent}} aria-controls="panel1a-content" id="panel1a-header">
        <Box className={styles.paymentMethodIconAndText}>
          <img src={imageIcon} className={styles.paymentMethodIcon} alt="bank transfer" />
          <Grid container>
            <Typography>{label || translate('bankTransfer')}</Typography>
            {
              expanded && subtitle &&
              <Grid container>
                <Typography className={styles.subtitle2} variant="subtitle2">{subtitle}</Typography>
              </Grid>
            }
          </Grid>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        { loadingBankDetails && <div className="loading-content">
          <CircularProgress />
        </div>}

        { errorBankDetails && <div className="loading-content">
          <Typography className={styles.bankTransferError}>{translate('bankTransferError')}</Typography>
        </div>}

        { !loadingBankDetails && !errorBankDetails &&
          <Grid container spacing={2}>
            {
              !subtitle && <Grid item>
                <Typography>{translate('bankTransferDesc')}</Typography>
              </Grid>
            }
            <Grid item container direction="column">
              <Typography className={`pt_5 ${styles.subtitle2}`} variant="subtitle2">
                {translate('bankName')}: <b>{bankDetailsData.bankDetails.bankName}</b>
              </Typography>
              <Typography className={`pt_5 ${styles.subtitle2}`} variant="subtitle2">
                {translate('swiftCode')}: <b>{bankDetailsData.bankDetails.swiftCode}</b>
              </Typography>
              <Typography className={`pt_5 ${styles.subtitle2}`} variant="subtitle2">{translate('accountName')}: <b>{bankDetailsData.bankDetails.accountName}</b></Typography>
              <Typography className={`pt_5 ${styles.subtitle2}`} variant="subtitle2">{translate('accountNumber')}: <b>{bankDetailsData.bankDetails.accountNumber}</b></Typography>
            </Grid>
            <Grid item container>
              <Accordion classes={{root: styles.qrCodeCollapse}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <img src={BANK_INFORMATION[countryCode]['qrCodeIcon']} className={`mr_10 ${styles.qrCodeIcon}`} alt="icon" />
                  <Typography>{BANK_INFORMATION[countryCode]['qrCodeTitle']}</Typography>
                </AccordionSummary>
                <AccordionDetails classes={{root: styles.qrCodeDetails}}>
                  <img src={BANK_INFORMATION[countryCode]['qrCode']} alt="QR code" />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item container>
              <TextField
                id='avata_proof_payment'
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => handleChangeProofPaymentFile(e.target.files)}
                InputProps={{
                  inputProps:{
                    'accept': 'image/*'
                  }
                }}
              />

              { !proofPaymentFile &&
                <label htmlFor='avata_proof_payment' className={styles.proofUploaderZone}>
                  <Grid container className={styles.proofUploaderZoneContent}>
                    <Grid item container direction="column">
                      <Typography variant="h3">{translate('uploadProofPayment')}</Typography>
                      <Typography variant="h4" className="pt_5 pb_10">{translate('uploadProofPaymentFormatType')}</Typography>
                    </Grid>
                    <Grid item>
                      <SvgIcon component={UploadIcon} />
                    </Grid>
                  </Grid>
                </label>
              }

              { proofPaymentFile &&
                <>
                  <Grid container className={styles.successProofUploadedContent} >
                    <Grid item container wrap="nowrap" spacing={1} alignItems="center">
                      <Grid item>
                        <CheckIcon className={styles.leafColor}/>
                      </Grid>
                      <Grid item container direction="column">
                        <Typography variant="h4" className={styles.leafColor}>{translate('uploadProofPaymentSuccessfully')}</Typography>
                        <Typography variant="subtitle2" className="pt_5" style={{cursor: 'pointer'}} onClick={handleOpeningProofPaymentDetails}>
                          {translate('clickToReview')}</Typography>
                      </Grid>
                    </Grid>
                    <label htmlFor='avata_proof_payment' style={{width:150, cursor: 'pointer'}}>
                      <Grid container justify='flex-end'>
                        <AutorenewIcon />
                        <Typography variant="h3">{translate('replace')}</Typography>
                      </Grid>
                    </label>
                  </Grid>

                  {/* Show proof preview after chosen file */}
                  { openProofPaymentDetails && <ProofPaymentDetails
                    openProofPaymentDetails={openProofPaymentDetails}
                    handleClosingProofPaymentDetails={handleClosingProofPaymentDetails}
                    proofPaymentFile={proofPaymentFile}
                  />}
                </>
              }
            </Grid>
          </Grid>
        }
      </AccordionDetails>
    </Accordion>
  );
};
export default BankInformation;
