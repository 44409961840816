export default {
  back: "ย้อนกลับ",
  confirmPay: "ยืนยันและชำระเงิน",
  payWith: "ชำระโดย",
  confirmBook: "ยืนยันและจองบริการ",
  toPayment: "เข้าสู่หน้าชำระเงิน",
  remove: "ลบ",
  applyNow: "ใส่รหัส",
  promo: "ใส่รหัสส่วนลด",
  total: "รวม",
  description: "ราคานี้รวมภาษีมูลค่าเพิ่มแล้ว",
  cashContent:
    "ชำระเงินสดโดยตรงกับฮีโร่ของเราหลังจากที่คุณได้รับบริการ กรุณาเตรียมเงินให้พอดีกับค่าบริการเนื่องจากฮีโร่ของเราไม่สามารถทอนเงินได้",
  cashSubContent:
    "<bold> แจ้งเตือน </bold>: เมื่อกดยืนยัน การจองจะถือว่าสำเร็จและงานจะถูกส่งไปหาฮีโร่ช่างและแม่บ้านทันที",
  cardContent: "กำลังไปที่หน้าชำระเงิน",
  cardNumber: "เลขบัตรเครดิต",
  month: "เดือนที่หมดอายุ",
  year: "ปีที่หมดอายุ",
  cvc: "CVC/CVV",
  nameOnCard: "ชื่อบนบัตร",
  pay: "ยอดชำระ {{price}}",
  validateDescription:
    "กรุณาชำระเงินด้วยบัตรเครดิตธนาคาร {{bankname}} เพื่อใช้ส่วนลดนี้",
  cancel: "ยกเลิก",
  viewDetails: "ดูรายละเอียด",
  total: "รวม",
  payByCard: "ชำระด้วยบัตรเครดิต",
  invalidCardNumber: "เลขบัตรไม่ถูกต้อง",
  cardAlreadyExpired: "บัตรหมดอายุแล้ว",
  notSupportCardNumber: "บัตรของคุณไม่สามารถใช้กับรหัสส่วนลดนี้ได้",
  required: "จำเป็น",
  paymentType: "ช่องทางชำระเงิน",
  paymentTypeDescription: "กรุณาเลือกช่องทางชำระเงิน",
  supportCreditOnly:
    "บริการระยะยาวสามารถเลือกเป็นช่องทางบัตรเครดิต/เดบิตเท่านั้น",
  notice: "เกี่ยวกับบริการระยะยาว:",
  noticeContent:
    "บริการระยะยาวมีระยะเวลาบริการขั้นต่ำตามจำนวนที่ได้เลือกไว้ สามารถเปลี่ยนแปลง/ยกเลิกงานได้เดือนละ 1 ครั้ง ค่าบริการจะถูกหักทุกต้นเดือน หากไม่สามารถตัดบัตรได้บริการทั้งหมดจะถูกยกเลิกทันที",
  agreed:
    "ข้าพเจ้าได้อ่าน เข้าใจ และยอมรับใน <bold> รายละเอียดและเงื่อนไข </bold> ของบริษัทฯ",
  paymentPlan: "การชำระเงิน",
  session: "ครั้ง",
  youWillPay: "คุณจะจ่าย <blue>{{price}}/ครั้ง</blue>",
  willBeCharged: "ยอดชำระ",
  bankTransfer: "โอนเงิน",
  bankTransferDesc: "ชำระเงินและแนบหลักฐานการโอนเงินที่นี่",
  bankName: "ธนาคาร",
  swiftCode: "รหัสธนาคาร",
  accountName: "ชื่อบัญชี",
  accountNumber: "เลขบัญชี",
  uploadProofPayment: "แนบหลักฐานการชำระเงิน",
  uploadProofPaymentFormatType: "รองรับไฟล์ PNG หรือ JPG เท่านั้น",
  uploadProofPaymentSuccessfully: "แนบไฟล์แล้ว",
  clickToReview: "แตะเพื่อดู",
  replace: "เปลี่ยน",
  payViaQrCode: "ชำระด้วย QR Code",
  appliedPromoSuccess: "ใช้รหัสส่วนลดสำเร็จ!",
  bankTransferError:
    "ขออภัย ขณะนี้ไม่สามารถทำรายการโอนผ่านบัญชีได้ กรุณาเลือกชำระเงินด้วยช่องทางอื่น",
  myPromo: "ส่วนลด",
  inputHere: "ใส่รหัสส่วนลด",
  invalidPromoCode: "รหัสส่วนลดไม่ถูกต้อง",
  apply: "ยืนยัน",
  timeOutError: "ขออภัยระบบขัดข้อง กรุณาลองใหม่อีกครั้ง",
  duitNowQrPayment: "DuitNow QR Payment",
  bankTransferMethodContent:
    "Transfer funds via ATM or Online Banking and Send Proof of Payment",
};
