import React, { useEffect, useState } from "react";
import styles from "./card.module.scss";
import {
  Typography,
  CircularProgress,
  Grid,
  Container,
  Button,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CardForm from "./cardForm";
import shSrc from "assets/image/SH_fullLogo.svg";
import { useQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import "moment/locale/th";
import { gql } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatPrice } from "lib/currencyFormatter";
import { setVoucher } from "redux/modal";
import { Trans } from "react-i18next";
import crypto from "crypto-js";
import { withRouter } from "react-router-dom";
import { showErrorMessage } from "lib/notifier";
import { buildFullPath } from "lib/helper";
import { reset } from "redux-form";
import { integrate2c2pPayment, setCardRequirement } from "redux/payment";
import { useScript } from "./components/useScript";

const GET_ORDER_DETAILS = gql`
  query ($orderId: ID!, $discountCode: String) {
    order(orderId: $orderId, discountCode: $discountCode) {
      id
      discountData
      publicId
      additionalNotes
      job {
        id
        publicId
        timeslot1
        isRecurringJob
      }
      address {
        id
        address
      }
      totalPrice {
        amountCents
        currencyCode
      }
      totalDiscountedPrice {
        amountCents
        currencyCode
      }
      totalDuration
      discountData
      salesItems {
        name
        price {
          amountCents
          currencyCode
        }
        duration
      }
      directService {
        id
        name
      }
      discountAmount {
        amountCents
        currencyCode
      }
      payableAmount {
        amountCents
        currencyCode
      }
      creditCardValidation
    }
  }
`;

// const SUBMIT_CARD_PAYMENT = gql`
//   mutation (
//     $orderId: ID!
//     $discountCode: String
//     $paymentMethod: String!
//     $paymentDetails: PaymentDetails
//     $recurringPaymentType: String
//     $agreedOn: DateTime
//   ) {
//     submitCreditCardPayment(
//       orderId: $orderId
//       discountCode: $discountCode
//       paymentMethod: $paymentMethod
//       paymentDetails: $paymentDetails
//       recurringPaymentType: $recurringPaymentType
//       agreedOn: $agreedOn
//     ) {
//       id
//       paymentMethod
//       paid
//       errorReason
//       order {
//         id
//         publicId
//         job {
//           isRecurringJob
//           publicId
//         }
//       }
//     }
//   }
// `;

const CardValidation = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const status = useScript(process.env.REACT_APP_2C2P_SDK);

  const { t: translate } = useTranslation("Payment");
  const [cardNumber, setCardNumber] = useState("");
  const dispatch = useDispatch();
  const orderId = useSelector(
    (state) => state.payment && state.payment.currentOrderId
  );
  const discountCode =
    useSelector((state) => state.modal && state.modal.voucherCode) || null;
  const requirements =
    useSelector((state) => state.payment && state.payment.cardNumberValidate) ||
    {};

  // const [submitCreditCard] = useMutation(SUBMIT_CARD_PAYMENT);
  const { loading, data } = useQuery(GET_ORDER_DETAILS, {
    variables: {
      orderId,
      discountCode,
    },
  });

  // const postMessage = ({ cardNumber, cvc, expDate, nameOnCard }) => {
  //   const key = "to the rescue"; // Please dont change this key
  //   const cardInfo = JSON.stringify({
  //     cardName: nameOnCard,
  //     cardNumber,
  //     cardExpireMonth: expDate.split("/")[0],
  //     cardExpireYear: expDate.split("/")[1],
  //     cardCvc: cvc,
  //     generationTime: moment().toISOString(),
  //   });
  //   const encrypted = crypto.AES.encrypt(cardInfo, key).toString();
  //   return encrypted;
  // };

  if (loading)
    return (
      <div className="loading-content">
        <CircularProgress />
      </div>
    );
  const { order } = data;
  const jobId = order.job && order.job.publicId;
  const jobName = (order.directService && order.directService.name) || "";
  const price = formatPrice(
    order.totalDiscountedPrice.amountCents,
    order.totalDiscountedPrice.currencyCode
  );

  const handleCancel = () => {
    history.goBack();
    dispatch(reset("CardForm"));
    dispatch(setVoucher(""));
    dispatch(setCardRequirement(null));
  };

  const handleNumericInput = (e) => {
    const isNumeric = /^[0-9]*$/.test(e.key);

    if (!isNumeric) {
      e.preventDefault();
    }
  };

  const handleMonthTypeWithNumericInput = (e) => {
    const isNumeric = /^[0-9]*$/.test(e.key);
    const currentValue = e.target.value;

    if (
      !(
        isNumeric &&
        (currentValue.length === 0 ||
          (parseInt(currentValue + e.key) >= 1 &&
            parseInt(currentValue + e.key) <= 12))
      )
    ) {
      e.preventDefault();
    }
  };

  const checkValidCardNumber = (value) => {
    if (!discountCode) {
      return true;
    }
    const validPrefixCardNumbers =
      requirements && requirements.validPrefixCardNumbers;
    const cardNumberPrefix = value.slice(0, 6);
    if (validPrefixCardNumbers?.includes(cardNumberPrefix)) {
      return true;
    } else {
      return translate("notSupportCardNumber");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    My2c2p.getEncrypted(
      "2c2p-payment-form",
      function (encryptedData, errCode, errDesc) {
        const { encryptedCardInfo } = encryptedData;

        if (errCode === 2) {
          setIsLoading(false);
          return showErrorMessage(translate("invalidCardNumber"));
        }

        if (errCode === 7) {
          setIsLoading(false);
          return showErrorMessage(translate("cardAlreadyExpired"));
        }

        const checkValid = checkValidCardNumber(cardNumber);

        if (checkValid !== true) {
          setIsLoading(false);
          return showErrorMessage(checkValid);
        }

        dispatch(integrate2c2pPayment(orderId, discountCode, encryptedCardInfo))
          .then((response) => {
            setIsLoading(false);

            location.replace(
              buildFullPath(`thankyou?jobId=${jobId}&jobName=${jobName}`)
            );
          })
          .catch((error) => {
            setIsLoading(false);
            showErrorMessage(error.data);
          });
      }
    );
  };

  return (
    <Container maxWidth="sm" className={styles.containerPage}>
      <Grid container justify="flex-end">
        <Button
          className={`peach_btn ${styles.cancelBtn}`}
          onClick={handleCancel}
        >
          {translate("cancel")}
        </Button>
      </Grid>
      <Typography variant="h3">{translate("total")}</Typography>
      <Typography variant="h1">{price}</Typography>
      {discountCode && (
        <Grid container className={styles.description}>
          <Typography>
            <Trans
              i18nKey="Payment:validateDescription"
              values={{ bankName: requirements.bankApplied }}
            ></Trans>
          </Typography>
        </Grid>
      )}
      <Typography className={!discountCode ? "mt_20" : ""} variant="h3">
        {translate("payByCard")}
      </Typography>
      {status === "ready" && (
        <Box className="mt_30 mb_10">
          <form
            className={styles["payment-form"]}
            id="2c2p-payment-form"
            onSubmit={handleSubmit}
          >
            <Box>
              <label htmlFor="cardNumber">{translate("cardNumber")}</label>
              <input
                id="cardNumber"
                type="text"
                data-encrypt="cardnumber"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
                maxLength={16}
                placeholder="Credit Card Number"
                required
                onKeyPress={(e) => handleNumericInput(e)}
              />
            </Box>
            <Box display={"flex"} gridGap={"10px"}>
              <Box className="mt_10">
                <label htmlFor="expMonth">{translate("month")}</label>
                <input
                  id="expMonth"
                  type="text"
                  data-encrypt="month"
                  maxLength="2"
                  placeholder="MM"
                  required
                  onKeyPress={(e) => handleMonthTypeWithNumericInput(e)}
                />
              </Box>
              <Box className="mt_10">
                <label htmlFor="expYear">{translate("year")}</label>
                <input
                  id="expYear"
                  type="text"
                  data-encrypt="year"
                  maxLength="4"
                  placeholder="YYYY"
                  required
                  onKeyPress={(e) => handleNumericInput(e)}
                />
              </Box>
              <Box className="mt_10">
                <label htmlFor="code">{translate("cvc")}</label>
                <input
                  id="code"
                  type="text"
                  data-encrypt="cvv"
                  maxLength="4"
                  placeholder="CVV2/CVC2"
                  required
                  onKeyPress={(e) => handleNumericInput(e)}
                />
              </Box>
            </Box>

            <Button
              disabled={isLoading}
              fullWidth
              type="submit"
              className="primary_yellow_btn mt_10"
              variant="contained"
            >
              {isLoading ? (
                <Box display="flex" alignItems={"center"} gridGap={"20px"}>
                  <CircularProgress size={24} color="inherit" />
                  <Trans
                    i18nKey="Payment:pay"
                    values={{ price: price }}
                  ></Trans>
                </Box>
              ) : (
                <Trans i18nKey="Payment:pay" values={{ price: price }}></Trans> // Render payment text
              )}
            </Button>
          </form>
        </Box>
      )}

      <Grid container justify="center">
        <img className={styles.logo} src={shSrc} alt="sh_logo" />
      </Grid>
    </Container>
  );
};
export default withRouter(CardValidation);
