export default {
  language: {
    countryNLanguage: 'Country & Language',
    language : 'Language'
  },
  jobs: 'Jobs',
  bookingService: 'Book a Service',
  category: 'Category',
  jobsHistory: 'Jobs History',
  location: {
    placeholder: 'Enter your location to get started',
    label: 'Location',
    searchPlaceholder: 'Input street address here',
    unitNumber: 'Unit or Street Number',
    additionalNoteTitle: 'Leave additional notes to help our Heroes find you more easily',
    additionalNote: 'Additional notes...',
    confirmYourAddress: 'Confirm your address',
    chooseLocationFirst: 'Enter the address where you need services to get started'
  },
  service: {
    label: 'Service',
    placeholder: 'Which service do you need?',
    category: 'Category',
    selectService: 'Select your desired service',
  },
}