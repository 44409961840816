export default {
  confirm: 'Confirm',
  selectDate: 'Select Date',
  dateFormat1: 'DD/MM/YYYY',
  dateFormat2: 'MMM DD, YYYY',
  timeFormat: 'hh:mm A',
  datetimeFormat: 'MMM DD, YYYY - hh:mm A',
  datetimeFormatWithDayName: 'ddd, MMM DD, YYYY - hh:mm A',
  or: 'Or',
  help: 'Help',
  getApp: 'Get the app',
  back: 'Back',
  save: 'Save',
  welcomeToSH: 'Welcome to ServisHero!',
  thanksForSigningUp:
    'Thank you for signing up with Servishero! Your account has been created, you can now',
  bookFirstServiceWithPromotion: 'Book your first service with 10% off',
  findServiceAndMakeFirstBooking: 'Find a service you need and make your very first booking.',
  bookNow: 'Book now',
  buySubscription: 'Buy a Subscription',
  buySubscriptionDesc: 'Skip the hassle of monthly booking and subscribe now',
  buyPlan: 'Buy a Plan',
  shMobileApp: 'ServisHero on Mobile Application',
  downloadAppNow: 'Download now to create and manage your bookings on the go!',
  scanToDownload: 'or scan the QR Code to get our App',
  thankyou: 'Thank you!',
  manageJobs: 'Manage Jobs',
  backToHome: 'Back to Home',
  thankYouContentForRecurringService:
    'You have successfully made a booking for {{jobName}} with ServisHero. Subscription ID: {{subscriptionId}}.',
  thankYouContentForOnceOffJob:
    'You have successfully made a booking for {{jobName}} with ServisHero. Job ID: {{jobId}}.',
  oopsContent:
    'This service might not be available at your location. Do drop us a message to enquire for assistance.',
  close: 'Close',
  contactForSupport: 'Contact for Support',
  chatAvailable: 'Mon-Fri: 9 am - 7 pm',
  hotline: 'Hotline',
  email: 'Email',
  getUpdateAndPromos: "Don't miss our great Updates and Promos",
  followingUsOnFB: 'Following us on facebook',
  whatsApp: 'WhatsApp',
  line: 'Line',
  lineUsAt: 'Line us at @servishero',
  termsOfService: 'Terms of service',
  letUsKnow: 'Save your details',
  letUsContent: "We'll need this information for you to book services, and to serve you better.",
  name: 'Name',
  email: 'Email',
  phoneNumber: 'Phone Number',
  changedYourProfileSuccessfully: 'Changed Your Profile Successfully',
  invalidDate: 'Invalid Date',
  oops: 'Oops! Service Not Available.',
  addLater: 'I’ll add later',
  required: 'Required',
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  copied: 'Copied',
  copy: 'Copy',
  oopsError: 'Oops! An error has occured and we’re trying to fix the problem.',
  pleaseContact: 'Please try again later or contact',
  forUrgent: 'For urgent situation please call us',
  tryingHard: 'Job management and tracking coming to web soon',
  downloadToTrack: 'You can still download the ServisHero App, on mobile, to manage your job requests.'
};
