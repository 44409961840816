import React, { useState } from 'react';
import {
  Grid,
  Button,
  Dialog,
  Paper,
  TextField,
  DialogContent,
  Divider,
  IconButton,
  Typography,
  Box
} from '@material-ui/core';
import PromoGift from 'assets/image/promo-gift.svg';
import styles from './promo.module.scss';
import { connect } from 'react-redux';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { setVoucher, openModalVoucher } from 'redux/modal';
import { setCardRequirement } from 'redux/payment';
import CloseIcon from '@material-ui/icons/Close';
import { showSuccessMessage } from 'lib/notifier';
import { useTranslation } from 'react-i18next';

const DISCOUNT_CODE = gql`
  query($orderId: ID!, $discountCode: String!) {
    checkDiscountCodeValid(orderId: $orderId, discountCode: $discountCode) {
      valid
      creditCardValidation
      validPrefixCardNumbers
      bankApplied
    }
  }
`;

const PromoModal = ({
  openVoucherModal,
  setVoucherDispatch,
  openModalVoucherDispatch,
  setCardRequirementDispatch,
  orderId,
}) => {
  if (!orderId) return null;
  const { t: translate } = useTranslation('Payment');

  const { refetch: checkValidCode } = useQuery(DISCOUNT_CODE, {
    fetchPolicy: 'no-cache',
    skip: true
  });

  const [code, setCode] = useState('');
  const [errorStatus, setVoucherErrorStatus] = useState(false);

  const handleClickApplyPromoCode = async () => {
    const status = await checkValidCode({
      orderId,
      discountCode: code
    }).catch((e) => ({ e }));

    if (status.e && status.e.graphQLErrors) return setVoucherErrorStatus(status.e.graphQLErrors[0].message);
    setVoucherDispatch(code);
    openModalVoucherDispatch(false);
    showSuccessMessage(translate('appliedPromoSuccess'));
    if (
      status.data &&
      status.data.checkDiscountCodeValid &&
      status.data.checkDiscountCodeValid.bankApplied
    ) {
      setCardRequirementDispatch(status.data.checkDiscountCodeValid);
    }

    //set code to normal when finish applied
    setCode('');
    setVoucherErrorStatus(false);
  };

  const handleCodeInputChange = (e) => setCode(e.target.value);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClickApplyPromoCode();
    }
  };

  return (
    <Dialog
      open={openVoucherModal}
      fullWidth
      maxWidth={'sm'}
      classes={{
        paper: styles.paper,
        scrollPaper: styles.scrollPaper
      }}
    >
      <img src={PromoGift} className={styles.imageContainer} />
      <DialogContent className={styles.content}>
        <Grid container item justify="flex-end">
          <IconButton className={styles.closeBtn} onClick={() => openModalVoucherDispatch(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid
          className={styles.inputContainer}
          container
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Box mb={2}>
            <Typography style={{ zIndex: 1 }} variant="h1">
              {translate('myPromo')}
            </Typography>
          </Box>
          <Paper className={styles.input}>
            <TextField
              style={{
                flex: 1
              }}
              onKeyDown={handleKeyDown}
              error={errorStatus}
              autoFocus
              value={code}
              onChange={handleCodeInputChange}
              placeholder={translate('inputHere')}
              helperText={errorStatus ? errorStatus : ''}
              inputProps={{
                style: { padding: 0 },
                spellCheck: false
              }}
            />
            <Divider
              style={{
                height: 28,
                marginRight: 12
              }}
              orientation="vertical"
            />

            <Button className="sea_btn" onClick={handleClickApplyPromoCode}>
              {translate('apply')}
            </Button>
          </Paper>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
const mapStateToProps = (state) => ({
  openVoucherModal: (state.modal && state.modal.openModalVoucher) || false,
  orderId: (state.payment && state.payment.currentOrderId) || '',
});

const mapDispatchToProps = (dispatch) => ({
  setVoucherDispatch: (index) => dispatch(setVoucher(index)),
  openModalVoucherDispatch: (status) => dispatch(openModalVoucher(status)),
  setCardRequirementDispatch: (card) => dispatch(setCardRequirement(card))
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoModal);
