import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import ResetPasswordForm from '../forms/resetPasswordForm';
import { useDispatch } from 'react-redux';
import { forgotPassword } from 'redux/auth';

const ResetPasswordHandler = ( props ) => {
  const { handleShowingInformResetingPwd, translate } = props;
  const dispatch = useDispatch();

  const handleResetPwd = async (values) => {
    await dispatch(forgotPassword(values.email));
    handleShowingInformResetingPwd(values.email);
  };

  return (
    <Grid container>
      <ResetPasswordForm onSubmit={handleResetPwd} translate={translate} />
    </Grid>
  );
};

export default ResetPasswordHandler;