export default {
  loginInWithEmail: 'ล็อกอินด้วยอีเมล',
  password: 'รหัสผ่าน',
  confirmPassword: 'ยืนยันรหัสผ่าน',
  email: 'อีเมล',
  login: 'ล็อกอิน',
  signUp: 'ลงทะเบียน',
  forgotPwd: 'ลืมรหัสผ่าน?',
  required: 'จำเป็น',
  invalidEmail: 'อีเมลไม่ถูกต้อง',
  didNotHaveAccountYet: 'ยังไม่มีบัญชีผู้ใช้งาน?',
  signUpWithEmail: 'ลงทะเบียนด้วยอีเมล',
  continueWithFB: 'เข้าสู่ระบบด้วยเฟซบุ๊ก',
  continueWithApple: 'เข้าสู่ระบบด้วยบัญชี Apple',
  loginSuccess: 'เข้าสู่ระบบสำเร็จ',
  authFailed: 'การอนุญาตล้มเหลว',
  plzCheckYourEmailForResetingPwd: 'กรุณาเช็คอีเมลของคุณเพื่อรีเซ็ตรหัสผ่าน',
  createdAccountSuccessfully: 'สร้างบัญชีสำเร็จ',
  alrdHaveAccount: 'มีบัญชีผู้ใช้งานอยู่แล้ว?',
  fullName: 'ชื่อ-นามสกุล',
  phoneNumber: 'เบอร์โทรศัพท์',
  signUpAgreeing: 'การยืนยันการลงทะเบียนหมายความว่าคุณเข้าใจและยอมรับข้อ <0>กำหนดและเงื่อนไขในการใช้งาน</0> แล้ว',
  resetPwdDescription: 'กรุณาใส่อีเมลที่คุณลงทะเบียนไว้ เพื่อรับรหัสผ่านชั่วคราวใหม่',
  getNewPwd: 'ขอรหัสผ่านใหม่',
  viewProfile: 'ดูโปรไฟล์',
  logOut: 'ออกจากระบบ',
  confirmPasswordNotMatch: 'รหัสผ่านไม่ตรงกัน',
  resetYourPassword: 'รีเซ็ตรหัสผ่านใหม่',
  informEmailSending: 'ส่งรหัสผ่านใหม่ทางอีเมลแล้ว',
  informEmailSendingContent: 'เราได้ส่งรหัสผ่านใหม่ให้คุณทางอีเมล <0>{{email}}</0> กรุณาเช็คในช่องสแปมหากคุณไม่ได้รับอีเมล',
  backToLogIn: 'กลับไปหน้าล็อกอิน',
  changePassword: 'เปลี่ยนรหัสผ่าน',
  dontHaveCurrentPwd: 'หากคุณลืมรหัสผ่าน หรือ เคยเข้าสู่ระบบด้วยบัญชีเฟซบุ๊กหรือบัญชี Apple   คลิก <0>ที่นี่</0> เพื่อรับรหัสผ่านปัจจุบันได้เลย',
  currentPassword: 'รหัสผ่านปัจจุบัน',
  newPassword: 'รหัสผ่านใหม่',
  confirmNewPassword: 'กรุณาใส่รหัสผ่านใหม่อีกครั้ง',
  changedYourPasswordSuccessfully: 'เปลี่ยนรหัสผ่านเรียบร้อย',
  sendNewPasswordToEmail: 'เราได้ส่งรหัสผ่านใหม่ให้คุณทางอีเมล {{email}} แล้ว',
  myProfile: 'โปรไฟล์ของฉัน',
  currentPasswordNotCorrect: 'รหัสผ่านปัจจุบันไม่ถูกต้อง',
}
