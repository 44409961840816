import React from 'react';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { ApolloProvider } from 'react-apollo';
import { setContext } from 'apollo-link-context';
import ExternalAPI from './constants/ExternalAPI';
import { useSelector } from 'react-redux';
import {
  isSupportedCountry,
  isSupportedLanguage,
} from 'lib/locationAndLanguage';

const cache = new InMemoryCache({
  addTypename: false
});


const authLink = (language, countryCode, accessToken, referenceId, partnerId) => {

  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...(referenceId ? { 'reference-id': referenceId } : {}),
        ...(partnerId ? { 'partner-id': partnerId } : {}),
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
      ...(countryCode ? createHttpLink({
        uri: `${ExternalAPI(countryCode.toLowerCase(), "GRAPHQL_ENDPOINT")}?locale=${language}`
      }) : {}),
    }
  })
}

export let client;

export default (props) => {
  const { children } = props;
  let { countryCode, language, searchParams } = props;

  if (!isSupportedCountry(countryCode)) {
    countryCode = useSelector(state => state.user.geoLocation.countryCode || 'my');
  }
  if (!isSupportedLanguage(countryCode, language)) {
    language = useSelector(state => state.user.preferredLanguage || 'en');
  }

  const accessToken = useSelector(state => state.user.accessToken);
  const referenceId = searchParams.referenceId;
  const partnerId = searchParams.partnerId;

  client = new ApolloClient({
    cache,
    link: authLink(language, countryCode, accessToken, referenceId, partnerId).concat(
      createHttpLink({ uri: `${ExternalAPI(countryCode, 'GRAPHQL_ENDPOINT')}?locale=${language}` })
    )
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
