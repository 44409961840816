export default {
  aboutService: 'รายละเอียดบริการ',
  frequencySchedule: 'วันและเวลาในการรับบริการ',
  describeYourPlace: 'รายละเอียดงาน',
  askingPlaceExplain: 'เราจะแนะนำบริการที่เหมาะสมให้จากรายละเอียดงานของคุณ',
  otherSessionType: 'ชุดบริการแนะนำอื่นๆ',
  bookingYourSession: 'จองบริการ',
  pickYourSessionType: 'เลือกบริการ',
  pickYourSessionTypeExplain: 'บริการที่แนะนำเรียงลำดับจากความเหมาะสม',
  dateAndTime: 'วันที่และเวลา',
  additionalItems: 'บริการเพิ่มเติม',
  additionalItemsExtraItems: 'อาจมีค่าใช้จ่ายหรือใช้เวลาในการให้บริการมากขึ้น',
  total: 'ราคา',
  includedTaxes: 'ราคานี้รวมภาษีมูลค่าเพิ่มแล้ว',
  onceOffNote: 'กรุณาเลือกวันและเวลาที่คุณลูกค้าสะดวกรับบริการ 2 ตัวเลือก (ตารางนี้ไม่ใช่ตารางว่างของบริษัท) โดยทีมงานจะติดต่อกลับเพื่อยืนยันวันนัดหมายอีกครั้ง',
  confirm: 'ยืนยัน',
  selectTime: 'เลือกวันและเวลารับบริการ',
  morningSlot: 'ช่วงเช้า',
  afternoonSlot: 'ช่วงบ่าย',
  dateTime: 'วันและเวลา',
  regular: 'คุณต้องการใช้บริการนี้บ่อยแค่ไหน',
  justOnce: 'ใช้เพียงครั้งเดียว',
  selectDateTime: 'เลือกการจองรายครั้ง อิสระกว่า',
  repeat: 'ใช้เป็นประจำ',
  repeatDescription: 'เลือกการจองรายเดือน/รายปี ประหยัดกว่า',
  selectSchedule: 'เลือกวันที่เริ่มรับบริการ',
  selectDuration: 'เลือกระยะเวลา',
  willOccur: 'ต้องการรับบริการในวัน ...',
  firstService: 'วันเริ่มต้นบริการคือ',
  didYouKnow: 'รู้หรือไม่?',
  recommend: 'ยิ่งใช้บริการถี่ ราคาต่อครั้งจะยิ่งถูกลง!',
  next: 'ถัดไป',
  back: 'ย้อนกลับ',
  confirm: 'ยืนยัน',
  congratulations: 'ยินดีด้วย!',
  saved: 'คุณประหยัดไป <bold>{{price}}</bold> บาท สำหรับบริการนี้',
  normal: '(ราคาปกติ: {{price}} บาท/ครั้ง)',
  custom: 'เลือกวันและเวลาด้วยตนเอง',
  need: 'ต้องการรับบริการในวัน ...',
  selectOcc: 'เลือกความถี่',
  times: '{{time}} ครั้ง',
  perYear: 'ต่อปี',
  at: 'เวลา',
  yearly: 'รายปี',
  weekly: 'รายเดือน',
  checkout: 'ชำระเงิน',
  attachment: 'แนบรูปถ่ายเพื่อช่วยให้ฮีโร่ของเราเข้าใจงานมากยิ่งขึ้น',
  alternativeNote: 'วันและเวลาทางเลือกจะช่วยเพิ่มโอกาสในการกดรับงานจากฮีโร่ช่างและแม่บ้าน',
  pleaseSelect: 'กรุณาเลือกวันและเวลาทางเลือก',
  totalAmount: 'รวม',
  checkoutText: 'ชำระเงิน',
  timeslot: 'วันและเวลา',
  alternativeTimeslot: 'วันและเวลาทางเลือก',
  inputBrandName: 'ยี่ห้อแอร์',
  alternative: 'ทางเลือก',
  summary: 'สรุปงาน',
  saveAmount: 'ลด {{price}}',
  youWillPay: 'คุณจะจ่าย',
  session: 'ครั้ง',
  collapse: 'ย่อ',
  viewSteps: 'ดูรายละเอียด',
  totalSessions: 'รวม {{numberSession}} เซสชัน',
};
