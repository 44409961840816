export const formatPrice = (price = 0, currencyCode) => {
  // NOTE: always divide 100, because db save is cent.
  const defaultPrice = (price || 0) / 100;

  const toFixedPrice = defaultPrice.toFixed(2);
  const [wholeNumber, fractional] = toFixedPrice.split('.');
  return `${countryToLanguage(currencyCode)} ${addCommaToString(wholeNumber)}.${fractional}`;
};

const addCommaToString = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const fallbackCurrency = 'NA';

const currencyCode = {
  MYR: 'RM',
  Malaysia: 'RM',
  THB: 'THB',
  Thailand: 'THB',
  SGD: '$',
  Singapore: '$'
};

export const countryToLanguage = (currencyIdentifier) => {
  return currencyCode[currencyIdentifier] || fallbackCurrency;
};
