import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Box,
  Container
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles.module.scss';
import helpIcon from 'assets/icons/Help.svg';
import mobileIcon from 'assets/icons/mobile.svg';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// component
import { LoginHandler, SignUpNavigatorHandler, SignUpHandler,
  AfterSendingResetPwdEmailInstruction, ResetPasswordHandler } from './components';
import { useSelector, useDispatch } from 'react-redux';
import { handleOpeningAuthDialog } from 'redux/auth';
import { toggleContactSupportDialog, toggleGetAppDialog } from 'redux/modal';
import { useTranslation } from 'react-i18next';

const AuthModal = (props) => {
  const openAuthDialogState = useSelector(state => state.auth.openAuthDialog);
  const currentUser = useSelector(state => state.user.currentUser);

  const [openAuthenticationDialog, setAuthenticationDialogState] = useState(!currentUser && openAuthDialogState);

  const [isLoginForm, setIsLoginForm ] = useState(true);
  const [isSignUpNaviPage, setIsSignUpNaviPage ] = useState(false);
  const [isSignUpForm, setIsSignUpForm ] = useState(false);
  const [isResetPwdForm, setIsResetPwdForm ] = useState(false);
  const [isAfterSendingResetPwdEmail, setIsAfterSendingResetPwdEmail ] = useState(false);
  const [previousStep, setPreviousStep] = useState(null);
  const [emailResetPwd, setEmailResetPwd] = useState(null);
  const { t: translate } = useTranslation('Auth');

  const dispatch = useDispatch();

  const handleCloseAuthDialog = () => {
    // Reset auth dialog back to login form and close dialog
    setIsSignUpForm(false);
    setIsResetPwdForm(false);
    setPreviousStep(null);
    setIsSignUpNaviPage(false);
    setIsAfterSendingResetPwdEmail(false);
    dispatch(handleOpeningAuthDialog(false));
    setIsLoginForm(true);
  };

  const handleShowingSignUpNavigator = () => {
    setIsLoginForm(false);
    setIsSignUpForm(false);
    setIsResetPwdForm(false);
    setPreviousStep(null);
    setIsSignUpNaviPage(true);
    setIsAfterSendingResetPwdEmail(false);
  }

  const handleShowingSignUpForm = () => {
    setIsLoginForm(false);
    setIsResetPwdForm(false);
    setIsSignUpNaviPage(false);
    setPreviousStep('signUpNavigator');
    setIsSignUpForm(true);
    setIsAfterSendingResetPwdEmail(false);
  }

  const handleShowingResetPwdForm = () => {
    setIsLoginForm(false);
    setIsSignUpNaviPage(false);
    setIsSignUpForm(false);
    setPreviousStep('loginForm');
    setIsResetPwdForm(true);
    setIsAfterSendingResetPwdEmail(false);
  }

  const handleShowingLoginForm = () => {
    setIsResetPwdForm(false);
    setIsSignUpNaviPage(false);
    setIsSignUpForm(false);
    setPreviousStep(null);
    setIsLoginForm(true);
    setIsAfterSendingResetPwdEmail(false);
  }

  const handleShowingInformResetingPwd = (email) => {
    setIsResetPwdForm(false);
    setIsSignUpNaviPage(false);
    setIsSignUpForm(false);
    setPreviousStep(null);
    setIsLoginForm(false);
    setEmailResetPwd(email);
    setIsAfterSendingResetPwdEmail(true);
  }

  const handleGoBackPreviousStep = () => {
    switch(previousStep) {
      case 'loginForm':
        handleShowingLoginForm();
        return;
      case 'signUpNavigator':
        handleShowingSignUpNavigator();
        return;
      default:
        return;
    }
  }

  const handleShowingContactSupport = (e) => {
    e.preventDefault();
    dispatch(toggleContactSupportDialog(true));
  }

  const handleShowingGetApp = (e) => {
    e.preventDefault();
    dispatch(toggleGetAppDialog(true));
  }

  useEffect(() => {
    setAuthenticationDialogState(openAuthDialogState);
  },[openAuthDialogState])

  const dialogTitle = () => {
    if (isLoginForm) return translate('login');
    if (isSignUpNaviPage) return translate('signUp');
    if (isSignUpForm) return translate('signUpWithEmail');
    if (isResetPwdForm) return translate('resetYourPassword');
    return null;
  }

  return (
    <>
      { isAfterSendingResetPwdEmail &&
        <Dialog
          open={openAuthenticationDialog}
          classes={{ paper: styles.dialog }}
          aria-labelledby="max-width-dialog-title"
          disableBackdropClick={true}
        >
          <AfterSendingResetPwdEmailInstruction
            handleShowingLoginForm={handleShowingLoginForm}
            translate={translate}
            email={emailResetPwd}
          />
        </Dialog>
      }

      { !isAfterSendingResetPwdEmail &&
        <Dialog
          open={openAuthenticationDialog}
          classes={{ paper: styles.dialog }}
          aria-labelledby="max-width-dialog-title"
          disableBackdropClick={true}
        >
          <Grid item container justify={previousStep ? "space-between" : "flex-end" } alignItems="center" className={styles.dialogTopAction}>
            { previousStep &&
              <Button onClick={() => handleGoBackPreviousStep()} className={styles.backBtn}>
                <ArrowBackIosIcon />
                <Typography className={styles.btnText}>{translate('Common:back')}</Typography>
              </Button>
            }
            <IconButton aria-label="close" onClick={handleCloseAuthDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <div className={styles.dialogTitle}>
            <Grid container justify="space-between">
              <Typography variant="h1" className={styles.loginTitle}>
                { dialogTitle() }
              </Typography>
            </Grid>
          </div>

          <DialogContent className={styles.dialogContent}>
            {/* Login content */}
            { isLoginForm && <LoginHandler
              handleShowingSignUpNavigator={handleShowingSignUpNavigator}
              handleShowingResetPwdForm={handleShowingResetPwdForm}
              translate={translate}
            /> }

            {/* Sign up navigator content */}
            { isSignUpNaviPage && <SignUpNavigatorHandler
              handleShowingSignUpForm={handleShowingSignUpForm}
              handleShowingLoginForm={handleShowingLoginForm}
              translate={translate}
            /> }

            {/* sign Up form */}
            { isSignUpForm && <SignUpHandler translate={translate} />}

            {/* Reset password form */}
            { isResetPwdForm && <ResetPasswordHandler
              handleShowingInformResetingPwd={handleShowingInformResetingPwd}
              translate={translate} /> }

          </DialogContent>

          <DialogActions className={styles.dialogAction}>
            <Grid container>
              <Grid md={12} item container alignItems="center" justify="flex-end">
                <Button onClick={e => handleShowingContactSupport(e)} color="primary">
                  <img src={helpIcon} alt="help" />
                  <Box className={styles.blackText} pl={1}>
                    {translate('Common:help')}
                  </Box>
                </Button>
                <Button onClick={e => handleShowingGetApp(e)} color="primary">
                  <img src={mobileIcon} alt="mobile" />
                  <Box pl={1} className={styles.blackText}>
                    { translate('Common:getApp')}
                  </Box>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      }
    </>
  );
};

export default AuthModal;
