import React from 'react';
import { Grid } from '@material-ui/core';
import styles from './styles.module.scss';
import CheckIcon from '@material-ui/icons/Check';

const TimeslotButton = (props) => {
  const { children, onClick, disabled, isSelected } = props;
  return <Grid
      onClick={onClick}
      className={(disabled && styles.disable) || styles.timeSlot}
      container
      alignItems="center"
      justify="space-between"
    >
      {children}
      {isSelected && <CheckIcon />}
    </Grid>;
};

export default TimeslotButton;
