import React, { useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/th';

const DatePickerHandler = ({ calendarType = 'static', onDateChange, selectedDate, minDate }) => {
  const today = moment();
  const [pickerValue, setPickerValue] = useState(selectedDate || today);
  const updateState = (value) => {
    setPickerValue(value);
    onDateChange(value);
  }
  return (
    <DatePicker
      disableToolbar
      variant={calendarType}
      value={pickerValue}
      onChange={updateState}
      minDate={minDate || today}
      maxDate={new Date().setMonth(new Date().getMonth() + 3)}
    />
  );
};

export default DatePickerHandler;
