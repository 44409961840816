import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';
// libraries
import i18n from 'i18next';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Grid, useMediaQuery } from '@material-ui/core';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import 'moment/locale/th';
import * as Sentry from '@sentry/react';

// Pages
import HomePage from 'page/home_page';
import WelcomePage from 'page/welcome_page';
import NewBookingPage from 'page/bookings/new_booking';
import PaymentPage from 'page/payment';
import ThankYouPage from 'page/thankyou_page';
import MyProfilePage from 'page/my_profile';
import ValidationPage from 'page/card_validation';
import JobsPage from 'page/jobs';
import ApolloSetup from '../apolloSetup';
// Components
import {
  AppHeader,
  AppHeaderMobile,
  AuthenticationModal,
  BookingTopBar,
  ContactSupport,
  ErrorPage,
  GetTheAppDialog,
  UpdateProfileModal,
  SHIconLoading,
} from 'components';
// Utilities
import {
  getCountryByIpAddress,
  getCurrentLocationByCoordinate,
  getDefaultLanguage,
  isSupportedCountry,
  isSupportedLanguage,
} from 'lib/locationAndLanguage';
import { updateSettings } from 'redux/user';
import { setPromoCode } from 'redux/payment';
import { setSelectedService } from 'redux/service';
import { setCurrentPage } from 'redux/modal';
import { initGTag } from 'lib/analytics';
import styles from './styles.module.scss';
import Seo from './seo';

export const publicRouteList = [
  {
    path: '/',
    component: HomePage,
    layout: 'BookingLayout',
    page: 'home',
  },
  {
    path: '/:countryCode',
    component: HomePage,
    layout: 'BookingLayout',
    page: 'home',
  },
  {
    path: '/:countryCode/:language',
    component: HomePage,
    layout: 'BookingLayout',
    page: 'home',
  },
  {
    path: '/:countryCode/:language/maintenance',
    component: HomePage,
    layout: 'MaintenanceLayout',
    page: 'home',
  },
  {
    path: '/:countryCode/:language/welcome',
    component: WelcomePage,
    layout: 'NoHeaderLayout',
    page: 'welcome',
  },
  {
    path: '/:countryCode/:language/booking',
    component: NewBookingPage,
    layout: 'BookingLayout',
    page: 'booking',
  },
  {
    path: '/:countryCode/:language/thankyou',
    component: ThankYouPage,
    layout: 'NoHeaderLayout',
    page: 'thankyou',
  },
  {
    path: '/:countryCode/:language/profile',
    component: MyProfilePage,
    page: 'profile',
  },
  {
    path: '/:countryCode/:language/summary',
    component: PaymentPage,
    page: 'payment',
  },
  {
    path: '/:countryCode/:language/validation',
    component: ValidationPage,
    layout: 'NoHeaderLayout',
    page: 'validation',
  },
  {
    path: '/:countryCode/:language/jobs',
    component: JobsPage,
    page: 'jobs',
  },
];

export const AppContext = React.createContext();

const NoHeaderLayout = ({ children }) => {
  return <>{children}</>;
};

const StandardLayout = ({ children, isError }) => {
  const isOnMobile = useMediaQuery('(max-width:767px)', {
    noSsr: true,
  });
  return (
    <>
      <Grid className={styles.appHeader}>
        {!isOnMobile && <AppHeader />}
        {isOnMobile && <AppHeaderMobile />}
      </Grid>
      {isError ? <ErrorPage /> : children}

      <UpdateProfileModal />
      <AuthenticationModal />
      <GetTheAppDialog />
      <ContactSupport />
    </>
  );
};

const BookingLayout = ({ children, isError }) => {
  const isOnMobile = useMediaQuery('(max-width:767px)', {
    noSsr: true,
  });
  return (
    <>
      <Grid className={styles.appHeaderAndBookingBar}>
        {!isOnMobile && <AppHeader />}
        {isOnMobile && <AppHeaderMobile />}
        <BookingTopBar />
      </Grid>
      {isError ? (
        <ErrorPage />
      ) : (
        <Grid className={styles.contentPage}>{children}</Grid>
      )}

      <UpdateProfileModal />
      <AuthenticationModal />
      <GetTheAppDialog />
      <ContactSupport />
    </>
  );
};

const MaintenanceLayout = ({ children, isError }) => {
  const isOnMobile = useMediaQuery('(max-width:767px)', {
    noSsr: true,
  });
  return (
    <>
      <Grid className={styles.appHeaderAndBookingBar}>
        {!isOnMobile && <AppHeader />}
        {isOnMobile && <AppHeaderMobile />}
        <BookingTopBar />
      </Grid>
      {isError ? (
        <ErrorPage />
      ) : (
        <Grid className={styles.contentPage}>{children}</Grid>
      )}

      <UpdateProfileModal />
      <AuthenticationModal />
      <GetTheAppDialog />
      <ContactSupport />
    </>
  );
};

const GET_DIRECT_SERVICE_API = gql`
  query ($serviceId: ID!) {
    service(serviceId: $serviceId) {
      id
      name
      description
      image
      disabled
      disabledImage
      disabledMessage
      recurring
      recurringType
    }
  }
`;

const Dashboard = ({ children, layout = 'StandardLayout', page }) => {
  let {
    props: {
      history,
      location: { search },
      match: {
        params: {
          countryCode: countryCodeFromUrl,
          language: languageFromUrl,
        },
      },
    },
  } = children;

  if (countryCodeFromUrl) {
    countryCodeFromUrl = countryCodeFromUrl.toLowerCase();
  }

  let queryParams = new URLSearchParams(search);
  let detectedCountryCode;
  let detectedCountryName;
  let detectLanguage;
  let currentLocation;
  let bookingLocation;
  let resetBookingLocation = false;

  const dispatch = useDispatch();

  const currentCountryCode = useSelector(
    (state) => state.user.geoLocation.countryCode
  );

  const selectedService = useSelector(
    (state) => state.service.selectedService
  );
  const preferredLanguage = useSelector(
    (state) => state.user.preferredLanguage
  );

  const currentBookingLocation = useSelector(
    (state) => state.user.bookingLocation
  );
  const isError = useSelector((state) => state.modal.errorPage);
  const selectedPage = useSelector(
    (state) => state.modal.currentPage
  );

  const { refetch: fetchServices } = useQuery(
    GET_DIRECT_SERVICE_API,
    {
      fetchPolicy: 'no-cache',
      skip: true,
    }
  );

  useEffect(() => {
    async function getPosition(options) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
          resolve,
          reject,
          options
        )
      );
    }

    // Ask user to get current location from browser
    async function detectCurrentUserLocation() {
      // Handle get current position by ip address if user didn't allow
      const positionDenied = async function () {
        if (isSupportedCountry(countryCodeFromUrl)) return;
        const response = await getCountryByIpAddress();
        let revealCountryCode = response.data.countrycode;
        let revealCountryName = response.data.countryname;
        if (revealCountryCode) {
          revealCountryCode = revealCountryCode.toLowerCase();
        }

        detectedCountryCode = revealCountryCode;
        detectedCountryName = revealCountryName;
      };

      // Handle get current position if user allowed
      const revealPosition = async function (position) {
        try {
          const lat = position.coords.latitude.toString();
          const lng = position.coords.longitude.toString();
          currentLocation = await getCurrentLocationByCoordinate(
            lat,
            lng,
            languageFromUrl || 'en'
          );

          let revealCountryCode = currentLocation.countryCode;
          let revealCountryName = currentLocation.countryName;
          if (revealCountryCode) {
            revealCountryCode = revealCountryCode.toLowerCase();
          }

          if (isSupportedCountry(countryCodeFromUrl)) {
            if (revealCountryCode === countryCodeFromUrl) {
              detectedCountryName = revealCountryName;
            }
          } else {
            detectedCountryCode = revealCountryCode;
            detectedCountryName = revealCountryName;
          }
        } catch (error) {
          console.log(error);
          Sentry.captureException(error);
        }
      };

      if (isSupportedCountry(countryCodeFromUrl)) {
        detectedCountryCode = countryCodeFromUrl;
      }

      if (navigator.permissions && navigator.permissions.query) {
        const result = await navigator.permissions.query({
          name: 'geolocation',
        });

        if (result.state === 'denied') {
          await positionDenied();
        } else {
          try {
            const position = await getPosition({
              enableHighAccuracy: true,
              timeout: 10000,
            });
            console.log('position', position);
            await revealPosition(position);
          } catch (err) {
            console.error(err.message);
            await positionDenied();
          }
        }
      } else {
        await positionDenied();
      }

      // If detected country is not supported country, we should make it is MY as default.
      if (!isSupportedCountry(detectedCountryCode)) {
        detectedCountryCode = 'th';
        detectedCountryName = 'Thailand';
      }
    }

    async function correctPreferredLanguage() {
      if (!detectedCountryCode) return;

      if (
        !isSupportedLanguage(detectedCountryCode, languageFromUrl)
      ) {
        detectLanguage = getDefaultLanguage(detectedCountryCode);
      } else {
        detectLanguage = languageFromUrl;
      }

      // Apply language to i18n (localization)
      if (detectLanguage && detectLanguage != i18n.language) {
        i18n.changeLanguage(detectLanguage);
      }
      moment.locale(detectLanguage);
    }

    const verifyAndCorrectUrl = async function () {
      // Get and store reference id and partner
      const promoCode =
        queryParams.get('promo_code') || queryParams.get('promoCode');
      const userEmail = queryParams.get('email');
      const userJwt = queryParams.get('jwt');
      const phoneNumber = queryParams.get('phoneNumber');
      const userName = queryParams.get('userName');

      // just set promo code, in checkout screen will verify it
      if (promoCode) dispatch(setPromoCode(promoCode));

      await detectCurrentUserLocation();
      await correctPreferredLanguage();

      // Check if we need reset booking location
      if (
        currentBookingLocation &&
        currentBookingLocation.countryCode !== detectedCountryCode
      ) {
        resetBookingLocation = true;
        bookingLocation = null;
      }

      // Check if we need set booking location
      if (
        !currentBookingLocation &&
        currentLocation &&
        currentLocation.countryCode === detectedCountryCode
      ) {
        resetBookingLocation = true;
        bookingLocation = currentLocation;
      }

      const detectedGeoLocation = {
        countryCode: detectedCountryCode,
        countryName: detectedCountryName,
      };

      const userObject = {
        ...(userEmail && { email: userEmail }),
        ...(userName && { name: userName }),
        ...(phoneNumber && { phone_number: phoneNumber }),
      };

      await dispatch(
        updateSettings({
          userCurrentLocation: currentLocation,
          ...(detectedCountryCode !== currentCountryCode && {
            geoLocation: detectedGeoLocation,
          }),
          ...(resetBookingLocation && { bookingLocation }),
          ...(detectLanguage != preferredLanguage && {
            preferredLanguage: detectLanguage,
          }),
          ...(userJwt && { accessToken: userJwt, isSignIn: true }),
          ...(Object.keys(userObject).length > 0 && {
            currentUser: userObject,
          }),
        })
      );

      // Init gtag
      if (detectedCountryCode !== currentCountryCode) {
        initGTag(detectedCountryCode);
      } else {
        initGTag(countryCodeFromUrl);
      }

      // Correct url for web app
      // Query service and redirect to booking page if valid
      const serviceId = queryParams.get('service');
      if (
        serviceId &&
        (!selectedService || serviceId !== selectedService.id)
      ) {
        await dispatch(reset('BookingForm'));
        try {
          const { data } = await fetchServices({
            serviceId: serviceId,
          });
          if (data && data.service) {
            await dispatch(setSelectedService(data.service));
            if (selectedPage !== 'booking') {
              history.push({
                pathname: `/${detectedCountryCode}/${detectLanguage}/booking`,
              });
            }
          } else {
            history.push({
              pathname: `/${detectedCountryCode}/${detectLanguage}`,
            });
          }
          return;
        } catch (error) {
          console.log('error', error);
          Sentry.captureException(error);
        }
      }

      // If user dont specify country or language in url, correct it.
      if (
        !isSupportedCountry(countryCodeFromUrl) ||
        !isSupportedLanguage(detectedCountryCode, languageFromUrl)
      ) {
        history.push({
          pathname: `/${detectedCountryCode}/${detectLanguage}`,
        });
      }
    };

    verifyAndCorrectUrl();

    if (page) dispatch(setCurrentPage(page));
  }, []);

  useEffect(() => {
    const listPathname = window.location.pathname.split("/")
    if(listPathname[1] !== "th"){
      listPathname[1] = "th"
      history.push({
        pathname: listPathname.join("/"),
      });
    }
  }, [])
  

  // Show loading icon while correcting url
  if (!isSupportedCountry(countryCodeFromUrl)) {
    return (
      <div className={styles.loadingData}>
        <SHIconLoading />
      </div>
    );
  }

  return (
    <Seo>
      {layout === 'StandardLayout' && (
        <StandardLayout isError={isError}>{children}</StandardLayout>
      )}

      {layout === 'BookingLayout' && (
        <BookingLayout isError={isError}>{children}</BookingLayout>
      )}

      {layout === 'NoHeaderLayout' && (
        <NoHeaderLayout isError={isError}>{children}</NoHeaderLayout>
      )}
    </Seo>
  );
};

const Routes = (props) => {
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(
      window.location.search
    );
    const params = Object.fromEntries(urlSearchParams.entries());
    const referenceId = params.reference_id || params.referenceId;
    const partnerId = params.partner_id || params.partnerId;
    setSearchParams({
      referenceId,
      partnerId,
      urlSearchParams: window.location.search,
    });
  }, []);

  const routeComponents = publicRouteList.map((route, key) => (
    <Route
      exact
      path={route.path}
      key={key}
      render={(routeProps) => {
        const {
          match: {
            params: {
              countryCode: countryCodeFromUrl,
              language: languageFromUrl,
            },
          },
        } = routeProps;

        return (
          <ApolloSetup
            searchParams={searchParams}
            countryCode={countryCodeFromUrl}
            language={languageFromUrl}
          >
            <Dashboard layout={route.layout} page={route.page}>
              <route.component {...routeProps} />
            </Dashboard>
          </ApolloSetup>
        );
      }}
    />
  ));

  return (
    <AppContext.Provider value={{ searchParams }}>
      <Router>
        <Switch>
          {routeComponents}
          <Redirect to="/" />
        </Switch>
      </Router>
    </AppContext.Provider>
  );
};

export default Routes;

// Common Use case for location routing
// 1: user go to book.servishero.com/
// -> get current user location
//   - if user is in my/sg/th -> go to that country and get current location
//   - if outside -> my/en

// 2. User book.servishero.com/sg/en
// -> get current user location
//   - if in sg -> set current user location
//   - outside -> just update geoLocation for SG.

// 3. book.servishero.com/vn/en
// -> get current user location
//   - if user in my/sg/th -> direct to user's country
//   - outsite of my/sg/th -> direct to my/en

// 4. book.servishero.com/my or book.servishero.com/my/id
// If user dont specify preferred language or specify unsupported language
// - Check if that language is not supported -> go to my/en

// 5. User is in book.servishero.com/my/en, they choose a location then switch country to sg.
// Expect: Go to sg, remove booking location, show correct flag for sg.

// 6. User go to book.servishero.com?service_id=xyz
// Expect: detect correct country accordingly with user's location, check service if valid then go to booking page with correct country.
// For example: If user is in sg, correct url is: book.servishero.com/sg/en/booking and selected service is with id is xyz.
