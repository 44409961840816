import React, { useContext } from 'react';
import { Grid, Dialog, Typography, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toggleUpdateContactDialog } from 'redux/service';
import moment from 'moment';
import 'moment/locale/th';
import styles from './update_profile.module.scss';
import ProfileSrc from 'assets/icons/profile.svg';
import ProfileForm from '../profile_form';
import { updateUserProfile } from 'redux/auth';
import { showSuccessMessage, showErrorMessage } from 'lib/notifier';
import { AppContext } from 'routes';

const excludeFields = 'gender,dateOfBirth';
// const excludeFields = ['gender','dateOfBirth'];

const UpdateProfileModal = ({}) => {
  const openOutService = useSelector((state) => state.service.openUpdateContactDialog) || false;
  const countryCode = useSelector(state => state.user.geoLocation.countryCode);
  const isOnMobile = useMediaQuery('(max-width:767px)', { noSsr: true });
  const { t: translate } = useTranslation('Common');
  const contextProps = useContext(AppContext);

  const dispatch = useDispatch();
  const submitProfile = async (values) => {
    const data = {
      user: {
        id: values.id,
        name: values.name,
        email: values.email,
        dob: (values.dateOfBirth && moment(values.dateOfBirth).format('YYYY-MM-DD')) || null,
        gender: values.gender || null,
        phone_number: values.phoneNumber,
        country_code: countryCode
      }
    };
    try {
      await dispatch(updateUserProfile(data, contextProps.searchParams));
      dispatch(toggleUpdateContactDialog(false));
    } catch (error) {
      showErrorMessage(error.data.errors[0], 10000);
    }
  };

  const handleCloseBtn = () => {
    dispatch(toggleUpdateContactDialog(false));
  };

  return (
    <Dialog
      open={openOutService}
      fullWidth
      maxWidth="md"
      classes={{
        paper: styles.paper
      }}
    >
      <Grid container className={styles.formContainer}>
        <Grid
          container
          item
          md={7}
          wrap="nowrap"
          direction="column"
          className={styles.dialogLeftSide}
        >
          <Typography variant="h1">{translate('letUsKnow')}</Typography>
          <Typography variant="body2">{translate('letUsContent')}</Typography>
          <ProfileForm
            onSubmit={submitProfile}
            translate={translate}
            excludeFields={excludeFields}
            handleClose={handleCloseBtn}
          />
        </Grid>
        {!isOnMobile && (
          <Grid
            item
            container
            md={5}
            className={styles.dialogRightSide}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item className={styles.imageStyle}>
              <img src={ProfileSrc} alt="profile" />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default UpdateProfileModal;
