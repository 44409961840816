import React from 'react';
import {
  Grid,
  Link,
  Dialog,
  Container,
  Typography,
  IconButton,
  Box,
  useMediaQuery
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openOutServiceModal } from 'redux/modal';
import NotFoundSrc from 'assets/image/notFound.svg';
import styles from './out_service.module.scss';
import CloseIcon from '@material-ui/icons/Close';

import mobileIcon from 'assets/icons/Hotline.svg';
import whatsappIcon from 'assets/icons/Whatsapp.svg';
import facebookIcon from 'assets/icons/Facebook.svg';
import emailIcon from 'assets/icons/Email.svg';
import lineIcon from 'assets/icons/line.svg';

// TODO extract to constants
const contacts = {
  my: {
    whatsApp: 'https://wa.me/60174344376?text=[Consumer]%20Hi,%20I%20need%20help%20on%20the%20app',
    whatsAppNumber: '+60 17-434 4376',
    fb: 'http://m.me/servishero',
    hotline: '03-8408 1345',
    email: 'support@servishero.com'
  },
  th: {
    line: 'https://line.me/R/oaMessage/@servishero/?สวัสดีฉันต้องการความช่วยเหลือเกี่ยวกับแอพนี้',
    fb: ' http://m.me/servisherothailand',
    hotline: '063-236-6640',
    email: 'support.th@servishero.com'
  },
  sg: {
    whatsApp: 'https://wa.me/6588153055?text=[Consumer]%20Hi,%20I%20need%20help%20on%20the%20app',
    whatsAppNumber: '+65 88153055',
    fb: 'http://m.me/servisherosingapore',
    hotline: '+65-88153055',
    email: 'support@servishero.com'
  }
};

const OutOfServiceModal = ({}) => {
  const openOutService = useSelector((state) => state.modal.openOutServiceModal) || false;
  const countryCode = useSelector((state) => state.user.geoLocation.countryCode);
  const isOnMobile = useMediaQuery('(max-width:767px)', { noSsr: true });

  const { t: translate } = useTranslation('Common');
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(openOutServiceModal(false));
  };

  return (
    <Dialog
      open={openOutService}
      fullWidth
      maxWidth={'md'}
      classes={{
        paper: styles.paper
      }}
    >
      <Container className={styles.dialogContainer}>
        <Grid container>
          {isOnMobile && (
            <div className={styles.closeButtonWrap}>
              <IconButton aria-label="close" className={styles.closeButton} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
          <Grid container item md={7} className={styles.dialogLeftSide}>
            <Grid item md={12}>
              <Typography variant="h1">{translate('oops')}</Typography>
              <Box mt={2} mb={isOnMobile ? 2 : 4}>
                <Typography variant="body1">{translate('oopsContent')}</Typography>
              </Box>
            </Grid>
            <Grid
              item
              container
              alignItems={isOnMobile ? 'flex-start' : 'center'}
              wrap="nowrap"
              className={styles.rowStyle}
              spacing={2}
            >
              <Grid item>
                <img className={styles.fixedIconSize} src={contacts[countryCode].whatsApp ? whatsappIcon: lineIcon} alt="mobile" />
              </Grid>
              <Grid item>
                <Grid
                  container
                  item
                  direction="column"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Typography variant="body2">({translate('chatAvailable')})</Typography>
                  {contacts[countryCode].whatsApp ? (
                    <Link href={contacts[countryCode].whatsApp} className={styles.fbText}>
                      <b>{`WhatsApp ${contacts[countryCode].whatsAppNumber}`}</b>
                    </Link>
                  ) : (
                    <Link href={contacts[countryCode].line} className={styles.fbText}>
                      <b>{translate('lineUsAt')}</b>
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems={isOnMobile ? 'flex-start' : 'center'}
              wrap="nowrap"
              className={styles.rowStyle}
              spacing={2}
            >
              <Grid item>
                <img className={styles.fixedIconSize} src={mobileIcon} alt="mobile" />
              </Grid>
              <Grid item>
                <Grid
                  container
                  item
                  direction="column"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Typography variant="subtitle1" color="textPrimary">
                    {translate('hotline')}
                  </Typography>
                  <Typography variant="body1">
                    <Link
                      href={`tel:${contacts[countryCode].hotline.replace(/-/g, '')}`}
                      className={styles.fbText}
                    >
                      <b>{contacts[countryCode].hotline}</b>
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems={isOnMobile ? 'flex-start' : 'center'}
              wrap="nowrap"
              className={styles.rowStyle}
              spacing={2}
            >
              <Grid item>
                <img className={styles.fixedIconSize} src={emailIcon} alt="email" />
              </Grid>
              <Grid item>
                <Grid
                  container
                  item
                  direction="column"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Typography variant="subtitle1" color="textPrimary">
                    {translate('email')}
                  </Typography>
                  <Typography variant="body1">
                    <Link href={`mailto:${contacts[countryCode].email}`} className={styles.fbText}>
                      <b>{contacts[countryCode].email}</b>
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              className={styles.rowStyle}
              spacing={2}
              wrap="nowrap"
            >
              <Grid item>
                <img src={facebookIcon} alt="mobile" />
              </Grid>
              <Grid item md={12} sm container>
                <Grid
                  container
                  item
                  direction="column"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Typography variant="subtitle1">{translate('getUpdateAndPromos')}</Typography>
                  <Typography variant="body2" color="textPrimary">
                    <Link
                      href="https://www.facebook.com/servishero"
                      target="_blank"
                      className={styles.fbText}
                    >
                      <b>{`${translate('followingUsOnFB')} >`}</b>
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!isOnMobile && (
            <Grid
              item
              container
              md={5}
              className={styles.dialogRightSide}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <div className={styles.closeButtonWrap}>
                <IconButton aria-label="close" className={styles.closeButton} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <Grid item className={styles.imageStyle}>
                <img src={NotFoundSrc} alt="mobile" />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </Dialog>
  );
};

export default OutOfServiceModal;
