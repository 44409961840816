import React from 'react';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { SummaryComponent } from '../summary';
import { CircularProgress } from '@material-ui/core';

const GET_WEEKLY_PLAN_REVIEW = gql`
  query(
    $directServiceId: ID!
    $frequency: WeeklyFrequency!
    $selectedSalesItem: SelectedSalesItem!
    $selectedOptionalSalesItems: [ID!]!
  ) {
    weeklyRecurringServicePlanPreview(
      directServiceId: $directServiceId
      frequency: $frequency
      selectedSalesItem: $selectedSalesItem
      selectedOptionalSalesItems: $selectedOptionalSalesItems
    ) {
      id
      name
      duration
      durationUnit
      sessions
      totalSession
      startDate
      endDate
      serviceName
      subtotalAmount {
        amountCents
        currencyCode
      }
      totalAmount {
        amountCents
        currencyCode
      }
      pricePerSession {
        amountCents
        currencyCode
      }
      saveAmount {
        amountCents
        currencyCode
      }
      normalPrice {
        amountCents
        currencyCode
      }
      isBestPrice
    }
  }
`;

const WeeklyComponent = ({
  selectedSalesItem,
  selectedOptionalItems,
  directServiceId,
  frequency,
  weeklyPreviewPlan,
  setFetchApiError
}) => {
  const { loading, error, data } = useQuery(GET_WEEKLY_PLAN_REVIEW, {
    variables: {
      directServiceId,
      selectedOptionalSalesItems: selectedOptionalItems,
      selectedSalesItem,
      frequency
    }
  });
  if (loading)
    return (
      <div className="loading-content">
        <CircularProgress />
      </div>
    );

  if (error || !data) {
    setFetchApiError(true);
    return null;
  }
  
  const { weeklyRecurringServicePlanPreview } = data;
  const selectedPreviewPlan =
    (weeklyRecurringServicePlanPreview && weeklyRecurringServicePlanPreview[weeklyPreviewPlan]) ||
    '';
  return (
    <SummaryComponent
      selectedSalesItem={selectedSalesItem}
      totalAmount={selectedPreviewPlan.totalAmount}
      timeslots={selectedPreviewPlan.startDate}
      endDate={selectedPreviewPlan.endDate}
      pricePerSession={selectedPreviewPlan.pricePerSession}
    />
  );
};

export default WeeklyComponent;
