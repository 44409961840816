import React from 'react';
import styles from './style.module.scss';
import { Typography, CardContent, Card, RadioGroup, Box } from '@material-ui/core';
import { CheckboxInput } from 'components';
import { formatPrice } from 'lib/currencyFormatter';

const OptionalComponent = ({
  optionalSalesItems,
  changeField,
  selectedOptionalItems,
  translate
}) => {
  const handleClick = (id) => {
    let newOptions = selectedOptionalItems;
    if (selectedOptionalItems.includes(id)) {
      newOptions = selectedOptionalItems.filter((item) => item !== id);
    } else {
      newOptions = [...selectedOptionalItems, id];
    }
    changeField('selectedOptionalSalesItems', newOptions);
  };

  return (
    <Card className={styles.card}>
      <CardContent className={styles.cardContent}>
        <Typography variant="h1" className={styles.title}>
          {translate('additionalItems')}
        </Typography>
        <Box my={1}>
          <Typography variant="subtitle1" className={styles.textDark}>
            {translate('additionalItemsExtraItems')}
          </Typography>
        </Box>
        <RadioGroup className={styles.radioGroup}>
          {optionalSalesItems.map((item, index) => (
            <CheckboxInput
              onClick={() => handleClick(item.id)}
              price={formatPrice(item.price.amountCents, item.price.currencyCode)}
              key={index}
              value={item.id}
              title={item.name}
              name={item.name}
              subtitle={item.description}
              selectStyle={styles.w_80}
              selectedValues={selectedOptionalItems}
              isUseDefaultIcon
            />
          ))}
        </RadioGroup>
      </CardContent>
    </Card>
  );
};

export default OptionalComponent;
