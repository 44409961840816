import { POST } from 'lib/api';
import ExternalAPI from 'constants/ExternalAPI';
import { showErrorMessage } from 'lib/notifier';

const SET_SELECTED_SERVICE = 'SET_SELECTED_SERVICE';
const UPDATE_IS_BOOKING = 'UPDATE_IS_BOOKING';
const TOGGLE_UPDATE_CONTACT_DIALOG = 'TOGGLE_UPDATE_CONTACT_DIALOG';

// Actions

const initialState = {
  selectedService: null,
  isOnBookingForm: false,
  openUpdateContactDialog: false
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SELECTED_SERVICE:
      return {
        ...state,
        selectedService: action.service
      };

    case UPDATE_IS_BOOKING:
      return {
        ...state,
        isOnBookingForm: action.isOnBookingForm
      };

    case TOGGLE_UPDATE_CONTACT_DIALOG:
      return {
        ...state,
        openUpdateContactDialog: action.status
      };

    default:
      return state;
  }
}

// Action Creators

export function setSelectedService(service) {
  return { type: SET_SELECTED_SERVICE, service };
}

export function setIsOnBookingForm(status = false) {
  return { type: UPDATE_IS_BOOKING, isOnBookingForm: status };
}

export function toggleUpdateContactDialog(status) {
  return { type: TOGGLE_UPDATE_CONTACT_DIALOG, status: status };
}

export function uploadJobFile(formData, country) {
  return (dispatch) => {
    return POST(ExternalAPI(country, 'ATTACHMENT_UPLOAD_ENDPOINT'), null, formData)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        showErrorMessage('Upload fail');
        return Promise.reject(err);
      });
  };
}


export function uploadAvatar(formData) {
  return (dispatch) => {
    return POST(ExternalAPI('GLOBAL', 'UPLOAD_AVATAR_ENDPOINT'), null, formData)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        showErrorMessage('Upload fail');
        return Promise.reject(err);
      });
  };
}
