import common from './common';
import homePage from './home_page';
import header from './header';
import auth from './auth';
import booking from './booking';
import profile from './profile';
import payment from './payment';

export default {
  Common: { ...common },
  HomePage: {...homePage},
  Header: {...header},
  Auth: {...auth},
  Booking: {...booking},
  Profile: {...profile},
  Payment: {...payment}
};
