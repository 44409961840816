import React, { useState } from 'react';
import {
  Button,
  Grid,
  Link,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';

import styles from './../../styles.module.scss';
import { TextInput } from 'components';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

let LoginWithCredential = (props) => {
  const { handleSubmit, handleShowingResetPwdForm, translate,pristine, invalid, submitting } = props;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid item>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid container item justify="center" className={styles.loginInWithEmailLabel}> {translate('Common:or')} {translate('loginInWithEmail')}</Grid>
          <Grid container item>
            <Field
              component={TextInput}
              variant="filled"
              name="email"
              label={translate('email')}
              required
            />
          </Grid>
          <Grid className="mt_10" container item>
            <Field
              component={TextInput}
              variant="filled"
              name="password"
              label={translate('password')}
              type={showPassword ? 'text' : 'password'}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                      tabIndex={-1}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              required
            />
          </Grid>
        </Grid>
        <Grid container justify="space-between" alignItems="center" className="mt_10">
          <Button type="submit" variant="contained" className={`primary_yellow_btn ${styles.loginBtn}`}
            disabled={pristine || submitting || invalid}
          >
            {translate('login')}
          </Button>
          <Grid item>
            <Link onClick={() => handleShowingResetPwdForm()} className='link_style'>
              {translate('forgotPwd')}
            </Link>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

const validate = (values, props) => {
  const { translate } = props;

  const errors = {};
  if (!values.email) {
    errors.email = translate('Auth:required');
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = translate('invalidEmail');
  }
  if (!values.password) {
    errors.password = translate('Auth:required');
  }
  return errors;
};

LoginWithCredential = reduxForm({ form: 'authForm', validate })(LoginWithCredential);

export default LoginWithCredential;
