/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField } from '@material-ui/core';

const TextInput = (props) => {
  const {
    children,
    classes,
    input,
    inputClass,
    inputProps,
    label,
    name,
    helperText,
    meta: { touched, error, warning },
    ...rest
  } = props;

  function allowNumberOnly(event) {
    // allow keyCode for number from 0 to 9 (48 to 57)
    // delete (8), backspace(46), dot(190), up-down-left-right anchor (37 -> 40)
    // numpad (96 -> 105)
    // copy & paste ctrl/cmd + v/c (86, 67)
    let allow = true;
    allow =
      allow &&
      ![8, 9, 46, 65, 86, 67].includes(event.keyCode) &&
      (event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105) &&
      (event.keyCode < 37 || event.keyCode > 40);

    const iptProps = props.inputProps || (props.InputProps && props.InputProps.inputProps);
    // if it is decimal, then we need to check step attributes for this input and allow dot key(190, 110).
    if (iptProps && iptProps.step) {
      allow = allow && event.keyCode !== 190 && event.keyCode !== 110;
    }

    return allow && event.preventDefault();
  }

  return (
    <TextField
      id={name}
      fullWidth
      label={label}
      name={name}
      InputProps={{
        classes,
        inputProps
      }}
      className={inputClass}
      error={touched && error !== undefined}
      helperText={touched ? error || helperText : helperText}
      {...input}
      {...rest}
      onKeyDown={(event) => props.type === 'number' && allowNumberOnly(event)}
    >
      {children}
    </TextField>
  );
};

export default TextInput;
