import React, { useEffect } from 'react';
import CONSTANTS from 'constants/config';
const FacebookButton = ({ children, handleSubmitFacebook }) => {
  useEffect(() => {
    const loadSdkAsynchronously = () => {
      ((d, s, id) => {
        const element = d.getElementsByTagName(s)[0];
        const fjs = element;
        let js = element;
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = `https://connect.facebook.net/en_US/sdk.js`;
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    };

    const setFbAsyncInit = () => {
      window.fbAsyncInit = () => {
        FB.init({
          appId: CONSTANTS.FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: CONSTANTS.FACEBOOK_VERSION
        });

        FB.Event.subscribe('auth.statusChange', (response) => {
          if (response.authResponse) {
            checkLoginState();
          } else {
            console.log('[FacebookLoginButton] User cancelled login or did not fully authorize.');
          }
        });
      };
    };
    setFbAsyncInit();
    loadSdkAsynchronously();
  }, []);

  const checkLoginState = () => {
    FB.getLoginStatus(
      function (response) {
        statusChangeCallback(response);
      }.bind(this)
    );
  };

  const login = () => {
    FB.login(checkLoginState(), {
      scope: 'email'
    });
  };

  const statusChangeCallback = (response) => {
    if (response.status === 'connected') {
      handleSubmitFacebook &&
        handleSubmitFacebook(response.authResponse && response.authResponse.accessToken);
    } else if (response.status === 'not_authorized') {
      console.log('[FacebookLoginButton] Person is logged into Facebook but not your app');
    } else {
      console.log('[FacebookLoginButton] Person is not logged into Facebook');
    }
  };

  return (
    <div style={{ width: '100%' }} onClick={login}>
      {children}
    </div>
  );
};

export default FacebookButton;
