import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import { Provider } from "react-redux";
import { store, persistor } from "./services/store";
import reportWebVitals from "./reportWebVitals";
import { StylesProvider } from "@material-ui/core/styles";
import "./assets/scss/index.scss";
import Notifier from "./services/Notifier";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "./i18n";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn:
    "https://2798040981d14b3b96f6d70a260bcb7d@o204144.ingest.sentry.io/5601294",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  allowUrls: ["https://book.servishero.com", "https://book-stg.servishero.com"],
  environment: process.env.NODE_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Notifier />
            <Routes />
          </MuiPickersUtilsProvider>
        </PersistGate>
      </Provider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
