import React, { useContext } from 'react';
import { Grid, Link } from '@material-ui/core';
import { signInWithCredentials } from 'redux/auth';
import { AppContext } from 'routes';
import styles from './../../styles.module.scss';
import AuthBySocialNetwork from './authBySocialNetworkHandler';
import LoginWithCredential from '../forms/loginWithCredential';
import { useDispatch } from 'react-redux';
import { handleOpeningAuthDialog } from 'redux/auth';
import { showSuccessMessage, showErrorMessage } from 'lib/notifier';

const LoginHandler = (props) => {
  const {
    handleShowingSignUpNavigator,
    handleShowingResetPwdForm,
    translate
  } = props;
  const contextProps = useContext(AppContext);
  const dispatch = useDispatch();

  const submitLogin = async (values) => {
    const response = await dispatch(signInWithCredentials(values.email, values.password, contextProps.searchParams)).catch((error) => ({ error }));
    if (response.error) {
      showErrorMessage('Login failed');
    } else {
      showSuccessMessage(translate('loginSuccess'));
      dispatch(handleOpeningAuthDialog(false));
    }
  };

  return (
    <Grid container>
      <Grid item container className="mb_30">
        <AuthBySocialNetwork translate={translate} />
      </Grid>

      <LoginWithCredential onSubmit={submitLogin} handleShowingResetPwdForm={handleShowingResetPwdForm} translate={translate} />

      <Grid item container justify="center" alignItems="center" className={styles.notHaveAccYet}>
        <Grid item className={styles.greyText}>{translate('didNotHaveAccountYet')}</Grid>
        <Grid item className="ml_10">
          <Link onClick={() => handleShowingSignUpNavigator()} className='link_style'>
            { translate('signUp')}
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginHandler;
