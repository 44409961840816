import React from 'react';
import styles from './styles.module.scss';
import { Container, Typography, Grid, Button, Link } from '@material-ui/core';
import CupIcon from 'assets/image/cup.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { buildFullPath } from 'lib/helper';

const contacts = {
  my: {
    hotline: '+6017-3474376',
    email: 'support@servishero.com'
  },
  th: {
    hotline: '063-236-6640',
    email: 'support.th@servishero.com'
  },
  sg: {
    hotline: '+65-88153055',
    email: 'support@servishero.com'
  }
};

const ErrorPage = () => {
  const countryCode = useSelector((state) => state.user.geoLocation.countryCode) || 'my';
  const { t: translate } = useTranslation('Common');

  return (
    <Container maxWidth="sm" className="h_100">
      <Grid
        container
        className={styles.container}
        justify="center"
        alignItems="center"
        direction="column"
      >
        <img src={CupIcon} alt="cup_icon" />
        <Typography variant="h1" className="mt_30" align="center">
          {translate('oopsError')}
        </Typography>
        <Typography variant="subtitle1" className="mt_30">
          {translate('pleaseContact')}
        </Typography>
        <Link href={`mailto:${contacts[countryCode].email}`} className={styles.seaText}>
          <b>{contacts[countryCode].email}</b>
        </Link>
        <Typography variant="subtitle1" className="mt_20">
          {translate('forUrgent')}
        </Typography>
        <Link
          href={`tel:${contacts[countryCode].hotline.replace(/-/g, '')}`}
          className={styles.seaText}
        >
          <b>{contacts[countryCode].hotline}</b>
        </Link>
        <Button href={buildFullPath()} className="primary_yellow_btn mt_20">{translate('backToHome')}</Button>
      </Grid>
    </Container>
  );
};

export default ErrorPage;
