import React, { useContext } from 'react';
import { Typography, Popover, Grid, TextField, InputAdornment, Divider,
   List, ListItem, ListItemText, useMediaQuery, IconButton, CircularProgress} from '@material-ui/core';
  import SearchIcon from '@material-ui/icons/Search';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import RoomIcon from '@material-ui/icons/Room';
import CloseIcon from '@material-ui/icons/Close';
import styles from './styles.module.scss';
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-places-autocomplete';
import { detectLocality } from 'lib/locationAndLanguage';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { empty } from '@apollo/client';
import { AppContext } from 'routes';

const LocationAutocomplete = (props) => {
  const [address, setAddress] = React.useState('');
  const countryCode = useSelector(state => state.user.geoLocation.countryCode);
  const { selectLocationCallback } = props;
  const { t: translate } = useTranslation('Header');
  const contextProps = useContext(AppContext);
  const partnerId = contextProps.searchParams.partnerId;

  const handleChange = address => {
    setAddress(address);
  };

  // Receives a Google Place result object(s) as an argument
  // 1. Checks for empty object, return if empty
  // 2. Checks for country, use "administrative_area_level_1" for Thailand, "locality" for others
  // 3. For "locality", if locality is not found, check for partnerId.
  //   if partnerId is present and item is not present, get location by "adminstration_area_level_1"
  const getLocalityItem = place => {
    if (!place || place.length < 1) return;

    if (countryCode === "th") {
      return place[0].address_components.find(item => item.types.includes('country'));
    }

    let item = place[0].address_components.find(item => item.types.includes('locality'));
    if (!!partnerId && !item) {
      item = place[0].address_components.find(item => item.types.includes('administrative_area_level_1'));
    }

    return item;
  }

  const handleSelectLocation = async (address, placeId) => {
    if (!placeId) {
      selectLocationCallback(null);
      return;
    }

    try {
      const placeRes = await geocodeByPlaceId(placeId);
      const countryAddressComponents = placeRes[0].address_components.find(item => item.types.includes('country'));
      const resultCountryName = countryAddressComponents ? countryAddressComponents.long_name : null;
      const resultCountryCode = countryAddressComponents ? countryAddressComponents.short_name.toLowerCase() : null;
      const localityItem = getLocalityItem(placeRes);
      const locality = localityItem ? detectLocality(resultCountryCode, localityItem.long_name, localityItem.short_name) : null;
      const location = {
        address: address || placeRes[0].formatted_address,
        placeId: placeRes[0].place_id,
        countryCode: resultCountryCode,
        countryName: resultCountryName,
        locality: locality,
        lat: placeRes[0].geometry.location.lat(),
        lng: placeRes[0].geometry.location.lng(),
        unitNumber: null,
        remarks: null
      };
      selectLocationCallback(location)
    } catch (error) {
      console.error('error in autocomplete', error)
    }
  };

  return <PlacesAutocomplete
    value={address}
    onChange={handleChange}
    onSelect={handleSelectLocation}
    shouldFetchSuggestions={address.length > 3}
    highlightFirstSuggestion
    debounce={500}
    searchOptions={{
      componentRestrictions: {
        ...(countryCode ? { country: [countryCode.toLowerCase()] } : {}),
      }
    }}>

    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      <>
        <TextField id="outlined-search" variant="outlined"
          {...getInputProps({
            className: styles.locationSearchInput,
          })}
          placeholder={translate('location.searchPlaceholder')}
          fullWidth={true}
          autoFocus={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <div className={styles.autocompleteDropdownContainer}>
          {loading && <div className='loading-content'><CircularProgress /></div>}
          <div className={styles.locationSuggestion}>
            <List className={styles.searchPlaces}>
              { suggestions.map(suggestion => {
                return (
                  <ListItem key={suggestion.placeId} className={styles.itemAddress} {...getSuggestionItemProps(suggestion)}>
                    <RoomIcon className={styles.locationIcon} />
                    <ListItemText primary={suggestion.description} secondary={suggestion.formattedAddress} />
                  </ListItem>
                );
              })}
            </List>
          </div>
        </div>
      </>
    )}
  </PlacesAutocomplete>
}

const LocationSelection = (props) => {
  const {
    selectLocationCallback,
    handleCloseLocationPopover,
    anchorEl,
    anchorOrigin,
    transformOrigin,
    open,
    consumerSavedAddresses,
    translate
  } = props;

  const isOnMobile = useMediaQuery('(max-width:767px)', { noSsr: true });
  const countryCode = useSelector(state => state.user.geoLocation.countryCode);
  const countryName = useSelector(state => state.user.geoLocation.countryName);

  const handleSelectSaveAddress = async (event, location) => {
    event.preventDefault();
    if (!location) {
      selectLocationCallback(null);
      return;
    }

    location = {
      address: location.address,
      placeId: location.placeId,
      countryCode: countryCode,
      countryName: countryName,
      locality: location.locality,
      lat: location.lat,
      lng: location.lng,
      unitNumber: location.unitNumber,
      remarks: location.remarks
    };
    selectLocationCallback(location)
  };

  return (
    <Popover open={open}
      onClose={handleCloseLocationPopover}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      classes={{paper: styles.locationPopover}}
      marginThreshold={0}
    >
      <Grid container direction="column" className={styles.locationPopoverContent }>
        { isOnMobile && <Grid
          item container
          className={styles.dialogRightSide}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <div className={styles.closeButtonWrap}>
            <IconButton
              aria-label="close"
              className={styles.closeButton}
              onClick={handleCloseLocationPopover}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Grid>}

        <Grid item className={styles.autocompleteInputWraper}>
          <LocationAutocomplete
            selectLocationCallback={selectLocationCallback}
          />
        </Grid>

        {/* @TIN: comment select on map for now */}
        {/* <Button
          startIcon={<MapOutlinedIcon />}
          style={{ marginTop: 8, marginBottom: 8 }}
          color="primary"
        >
          Select on Map
        </Button> */}
        <Grid item>
          <Divider />
        </Grid>

        { consumerSavedAddresses && consumerSavedAddresses.length > 0 &&
          <Grid item container className={styles.saveAddressWraper}>
            <Grid item>
              <Typography >{translate('Profile:savePlaces')}</Typography>
            </Grid>
            <Grid item container className={styles.saveAddressList}>
              {consumerSavedAddresses.map((itemAddress, index) => {
                return (
                  <Grid item container
                    wrap="nowrap" spacing={0}
                    key={index} className={styles.addressRow}
                    onClick={e => handleSelectSaveAddress(e, itemAddress)}
                    >
                    <Grid item >
                      <div className={styles.addressIcon} >
                        <QueryBuilderOutlinedIcon style={{ fontSize: 12 }} />
                      </div>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Typography noWrap> {itemAddress.address} </Typography>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        }

      </Grid>
    </Popover >
  );
};

export default LocationSelection;
