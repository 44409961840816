export default {
  back: "Back",
  confirmPay: "Confirm and pay",
  payWith: "Pay with",
  confirmBook: "Confirm & Book",
  toPayment: "To Payment page",
  remove: "Remove",
  applyNow: "Apply Now",
  promo: "Got a promo code?",
  total: "Total",
  description: "Amount includes taxes if applicable",
  cashContent:
    "Pay cash directly to your Hero after completion of work. Heroes might not carry small change, so make sure you have exact amount prepared.",
  cashSubContent:
    "<bold>Important</bold>: This is not a quotation request - by completing the booking you confirm you will pay the total amount due to your Hero upon job completion",
  cardPaymentMethodContent: "You will be directed to the Payment Gateway page",
  cardNumber: "Card Number",
  month: "Exp. Month",
  year: "Exp. Year",
  cvc: "CVC / CVV",
  nameOnCard: "Name on Card",
  pay: "Pay {{price}}",
  validateDescription:
    "Please make the payment with a credit card from {{ bankName }} to use this promocode",
  cancel: "Cancel",
  viewDetails: "View details",
  total: "Total price",
  payByCard: "Pay by Card",
  invalidCardNumber: "Invalid Card Number",
  cardAlreadyExpired: "Card Already Expired",
  notSupportCardNumber:
    "Your credit card is not eligible for this promotion. Please contact ServisHero or your bank for further queries.",
  required: "Required",
  paymentType: "Payment Type",
  paymentTypeDescription: "How would you like to pay?",
  supportCreditOnly: "Monthly Recurring only support Pay by Card",
  notice: "Notice about Recurring Policy",
  noticeContent:
    "Minimum subscription term may apply. Your bank card will be charged upfront for initial payment and subsequent charges will be charged at the beginning of each month for monthly payments. Only 1 change is allowed for confirmed schedule every month. If we are unable to process the payment, we may suspend your subscription. Please refer to the Terms and Conditions for more details.",
  agreed: "I have read and agreed to the <bold>Terms and Conditions</bold>",
  paymentPlan: "Payment Plan",
  session: "Session(s)",
  youWillPay: "You’ll pay <blue>{{price}}/session</blue>",
  willBeCharged: "will be charged",
  bankTransfer: "Bank Transfer",
  bankTransferDesc: "Online Banking and send proof of payment here.",
  bankName: "Bank Name",
  swiftCode: "Swift Code",
  accountName: "Account Name",
  accountNumber: "Account Number",
  uploadProofPayment: "Upload Proof of Payment",
  uploadProofPaymentFormatType: "Screenshot in PNG or JPG format",
  uploadProofPaymentSuccessfully: "File selected",
  clickToReview: "Click to review",
  replace: "Replace",
  payViaQrCode: "Pay via QR Code",
  appliedPromoSuccess: "Yay! Promo applied successfully",
  bankTransferError:
    "Bank transfer isn't available at the moment. Please try other payment method. Thank you",
  myPromo: "My Promo",
  inputHere: "Input here",
  invalidPromoCode: "Invalid Voucher Code",
  apply: "Apply",
  timeOutError:
    "Looks like the server is taking to long to respond, please try again.",
  duitNowQrPayment: "DuitNow QR Payment",
  bankTransferMethodContent:
    "Transfer funds via ATM or Online Banking and Send Proof of Payment",
};
