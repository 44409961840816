import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Container,
  Link,
  IconButton,
  useMediaQuery
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import styles from './styles.module.scss';
import mobileIcon from 'assets/icons/Hotline.svg';
import whatsappIcon from 'assets/icons/Whatsapp.svg';
import contactIcon from 'assets/icons/contact.svg';
import facebookIcon from 'assets/icons/Facebook.svg';
import emailIcon from 'assets/icons/Email.svg';
import lineIcon from 'assets/icons/line.svg';

// component
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toggleContactSupportDialog } from 'redux/modal';


// TODO extract to constants
const contacts = {
  my: {
    whatsApp: 'https://wa.me/60174344376?text=[Consumer]%20Hi,%20I%20need%20help%20on%20the%20app',
    whatsAppNumber: '+60 17-434 4376',
    fb: 'http://m.me/servishero',
    hotline: '03-8408 1345',
    email: 'support@servishero.com'
  },
  th: {
    line: 'https://line.me/R/oaMessage/@servishero/?สวัสดีฉันต้องการความช่วยเหลือเกี่ยวกับแอพนี้',
    fb: ' http://m.me/servisherothailand',
    hotline: '063-236-6640',
    email: 'support.th@servishero.com'
  },
  sg: {
    whatsApp: 'https://wa.me/6588153055?text=[Consumer]%20Hi,%20I%20need%20help%20on%20the%20app',
    whatsAppNumber: '+65 88153055',
    fb: 'http://m.me/servisherosingapore',
    hotline: '+65-88153055',
    email: 'support@servishero.com'
  }
};

const ContactSupport = (props) => {
  const countryCode = useSelector((state) => state.user.geoLocation.countryCode) || 'my';
  const openContactSupportDialog = useSelector((state) => state.modal.openContactSupportDialog);
  const dispatch = useDispatch();

  const { t: translate } = useTranslation('Common');

  const isOnMobile = useMediaQuery('(max-width:767px)', { noSsr: true });
  const handleCloseDialog = () => {
    dispatch(toggleContactSupportDialog(false));
  };

  return (
    <>
      <Dialog
        open={openContactSupportDialog}
        classes={{ paper: styles.dialog }}
        aria-labelledby="max-width-dialog-title"
        disableBackdropClick={true}
      >
        <DialogContent className={styles.dialogContent}>
          <Grid container className={styles.dialogContainer}>
            <Grid
              item
              container
              xs={12}
              md={5}
              className={styles.dialogRightSide}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <div className={styles.closeButtonWrap}>
                <IconButton
                  aria-label="close"
                  className={styles.closeButton}
                  onClick={handleCloseDialog}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className={styles.mobileTitle}>
                <Typography variant="h1">{translate('contactForSupport')}</Typography>
              </div>
              <Grid item className={styles.imageStyle}>
                <img src={contactIcon} alt="mobile" />
              </Grid>
            </Grid>
            <Grid container item xs={12} md={7} className={styles.dialogLeftSide}>
              <Grid item xs={12} className={styles.leftPageTitle}>
                <Typography variant="h1">{translate('contactForSupport')}</Typography>
              </Grid>
              <Grid
                item
                container
                alignItems={isOnMobile ? 'flex-start' : 'center'}
                wrap="nowrap"
                className={styles.rowStyle}
                spacing={2}
              >
                <Grid item>
                  <img
                    className={styles.fixedIconSize}
                    src={contacts[countryCode].whatsApp ? whatsappIcon : lineIcon}
                    alt="mobile"
                  />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    item
                    direction="column"
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    <Typography variant="body2">({translate('chatAvailable')})</Typography>
                    {contacts[countryCode].whatsApp ? (
                      <Link href={contacts[countryCode].whatsApp} className={styles.fbText}>
                        <b>{`WhatsApp ${contacts[countryCode].whatsAppNumber}`}</b>
                      </Link>
                    ) : (
                      <Link href={contacts[countryCode].line} className={styles.fbText}>
                        <b>{translate('lineUsAt')}</b>
                      </Link>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems={isOnMobile ? 'flex-start' : 'center'}
                wrap="nowrap"
                className={styles.rowStyle}
                spacing={2}
              >
                <Grid item>
                  <img className={styles.fixedIconSize} src={mobileIcon} alt="mobile" />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    item
                    direction="column"
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    <Typography variant="subtitle1" color="textPrimary">
                      {translate('hotline')}
                    </Typography>
                    <Typography variant="body1">
                      <Link
                        href={`tel:${contacts[countryCode].hotline.replace(/-/g, '')}`}
                        className={styles.fbText}
                      >
                        <b>{contacts[countryCode].hotline}</b>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems={isOnMobile ? 'flex-start' : 'center'}
                wrap="nowrap"
                className={styles.rowStyle}
                spacing={2}
              >
                <Grid item>
                  <img className={styles.fixedIconSize} src={emailIcon} alt="email" />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    item
                    direction="column"
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    <Typography variant="subtitle1" color="textPrimary">
                      {translate('email')}
                    </Typography>
                    <Typography variant="body1">
                      <Link
                        href={`mailto:${contacts[countryCode].email}`}
                        className={styles.fbText}
                      >
                        <b>{contacts[countryCode].email}</b>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems={isOnMobile ? 'flex-start' : 'center'}
                wrap="nowrap"
                className={styles.rowStyle}
                spacing={2}
              >
                <Grid item>
                  <img className={styles.fixedIconSize} src={facebookIcon} alt="fb_icon" />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    item
                    direction="column"
                    alignItems="flex-start"
                    justify="flex-start"
                  >
                    <Typography variant="subtitle1">{translate('getUpdateAndPromos')}</Typography>
                    <Typography variant="body2" color="textPrimary">
                      <Link
                        href={contacts[countryCode].fb}
                        target="_blank"
                        className={styles.fbText}
                      >
                        <b>{`${translate('followingUsOnFB')} >`}</b>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContactSupport;
